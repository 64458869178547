export default interface ContractFileToShow {
  name: string;
  lastModifiedDate: string | number;
  documentId: string | null;
}

const contractFileToShow: ContractFileToShow = {
  name: '',
  lastModifiedDate: '',
  documentId: '',
};

export { contractFileToShow };
