<template>
  <div>
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.77839 0C3.0316 0.000331699 2.30165 0.207972 1.68063 0.596722C1.05962 0.985471 0.575373 1.53791 0.288996 2.18433C0.00261873 2.83076 -0.0730579 3.5422 0.071515 4.22889C0.216088 4.91558 0.574432 5.54676 1.10133 6.04277C1.30921 6.23846 1.64698 6.23915 1.85577 6.04432C2.06456 5.84949 2.0653 5.53291 1.85743 5.33721C1.47924 4.9812 1.22204 4.52818 1.11827 4.0353C1.01451 3.54242 1.06882 3.03178 1.27437 2.56781C1.47992 2.10384 1.82749 1.70732 2.27322 1.4283C2.71889 1.14931 3.24272 1.00028 3.77864 0.999999H9.0216C9.74119 0.999999 10.4313 1.26792 10.9401 1.74483C11.449 2.22173 11.7348 2.86855 11.7348 3.543C11.7348 4.92838 10.5406 5.99999 9.0216 5.99999H6.40012C6.10549 5.99999 5.86665 6.22385 5.86665 6.49999C5.86665 6.77613 6.10549 6.99999 6.40012 6.99999H9.0216C11.0897 6.99999 12.8018 5.51761 12.8018 3.543C12.8018 2.60333 12.4035 1.70216 11.6946 1.03772C10.9857 0.373279 10.0242 0 9.0216 0H3.77839Z"
        :fill="disabled ? '#AEAFB2' : '#34383F'"
      />
      <path
        d="M4.26517 6.45701C4.26517 5.07162 5.45939 4.00001 6.9784 4.00001H9.59988C9.89451 4.00001 10.1333 3.77615 10.1333 3.50001C10.1333 3.22387 9.89451 3.00001 9.59988 3.00001H6.9784C4.91035 3.00001 3.19822 4.48239 3.19822 6.45701C3.19822 7.39667 3.59649 8.29784 4.30541 8.96228C5.01433 9.62672 5.97583 10 6.9784 10H12.2214C12.9681 9.99967 13.6984 9.79203 14.3194 9.40328C14.9404 9.01453 15.4246 8.46209 15.711 7.81567C15.9974 7.16924 16.0731 6.4578 15.9285 5.77111C15.7839 5.08442 15.4256 4.45324 14.8987 3.95723C14.6908 3.76154 14.353 3.76085 14.1442 3.95568C13.9354 4.15051 13.9347 4.4671 14.1426 4.66279C14.5208 5.0188 14.778 5.47183 14.8817 5.9647C14.9855 6.45758 14.9312 6.96822 14.7256 7.43219C14.5201 7.89616 14.1725 8.29268 13.7268 8.5717C13.281 8.85073 12.7571 8.99976 12.2211 9H6.9784C6.25881 9 5.56868 8.73208 5.05985 8.25517C4.55102 7.77827 4.26517 7.13145 4.26517 6.45701Z"
        :fill="disabled ? '#AEAFB2' : '#34383F'"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ['disabled'],
};
</script>

<style lang="scss"></style>
