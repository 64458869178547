
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalApproveContract extends Vue {
  @Prop(Boolean) modalApproveContract!: boolean;
  @Prop(Boolean) approvingContract!: boolean;

  @Watch('approvingContract')
  handleCloseModal() {
    if (!this.approvingContract) this.closeModal();
  }

  async approveContract() {
    this.$emit('approveContract');
  }

  closeModal(): void {
    this.$emit('closeModal');
  }
}
