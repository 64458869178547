import Selects, { FintiApprover, FintiCompany, selectsInitialValue } from './selects';

export default class PaymentActivity {
  id?: string = '';
  activity: string = '';
  lesteRequester: Selects = selectsInitialValue;
  currency?: string = 'USD';
  grossValue: number = 0;
  runtime: string = '';
  deadline?: string = '';
  hoursUsed: number = 0;
  approversSelected: number[] = [];
  payingCompanySelected: FintiCompany = { id: 0, name: '' } as FintiCompany;
  showRuntimeDatePicker?: boolean = false;
  showDeadlineDatePicker?: boolean = false;
  approvalModeId: number = 3;
}
