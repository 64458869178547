import axios from 'axios';

export const setErrorText = (error: unknown): string => {
  if (!axios.isAxiosError(error))
    return 'Unexpected error encountered';
  const errors = error.response?.data.errors;
  if(typeof errors === 'object'){
    return parseErrorsFromObject(errors);
  }
  if (!errors || errors.length === 0)
    return parseBackendErrorString(error.response?.data) || 'Unexpected error encountered';
  
  let resultError = '';
  for (const error of errors as string[]) {            
    resultError += parseBackendErrorString(error) + '\n';
  }
  return resultError;
}

const parseBackendErrorString = (error: string): string => {
  try {
    if (error.includes('|')) {
      const errorData = error.split('|');
      if (errorData.length > 1)
        return errorData[1];            
      return errorData[0];
    }
    return error;
  }
  catch {
    return '';
  }
}

const parseErrorsFromObject = (errors: any): string => {
  let resultError = '';
  for (const [key, value] of Object.entries(errors)) {
    if (typeof value === 'string')
      resultError += parseBackendErrorString(value) + '\n';    
    else if (typeof value === 'object'){
      for (const error of value as string[]) {            
        resultError += parseBackendErrorString(error) + '\n';
      }
    }
  }  
  if (resultError === '')
    return "Unexpected error encountered"
  return resultError;
}