import { ActionsState } from './types';

import Area from '@/types/auth/areas';
import CurrentRoute from '@/types/auth/currentRoute';
import User from '@/types/auth/user';
import Feedback from '@/types/general/feedback';
import PermissionHandler from '@/utils/auth';

export default {
  setUser(state: ActionsState, user: User) {
    state.user = user;
  },
  setAreas(state: ActionsState, areas: Area[]) {
    state.areas = areas;
  },
  setCurrentRoute(state: ActionsState, currentRoute: CurrentRoute) {
    state.currentRoute = currentRoute;
  },

  canList(state: ActionsState, areas: Area[]) {
    const canList = PermissionHandler.verifyPermission(areas, 'Onboarding', 'List');
    state.canList = canList;
  },
  canApprove(state: ActionsState, areas: Area[]) {
    const canApprove = PermissionHandler.verifyPermission(areas, 'Onboarding', 'Approve');
    state.canApprove = canApprove;
  },
  canCreate(state: ActionsState, areas: Area[]) {
    const canCreate = PermissionHandler.verifyPermission(areas, 'Onboarding', 'Create');
    state.canCreate = canCreate;
  },
  canUpdate(state: ActionsState, areas: Area[]) {
    const canUpdate = PermissionHandler.verifyPermission(areas, 'Onboarding', 'Update');
    state.canUpdate = canUpdate;
  },
  canApproveDenied(state: ActionsState, areas: Area[]) {
    const canApproveDenied = PermissionHandler.verifyPermission(areas, 'Onboarding', 'ApproveDenied');
    state.canApproveDenied = canApproveDenied;
  },

  // TODO: Usar esse set profiles ou refatorar, passando as roles do usuário e fazer as verificações de usuários 1.0 e 2.0

  setProfiles(state: ActionsState, profiles: string[]): void {
    profiles.map((item) => {
      if (profiles.includes('Vendor.ApproverMaster')) {
        state.isApproverMaster = true;
      }
      if (profiles.includes('Vendor.Collaborator')) {
        state.isCollaborator = true;
      }
      if (profiles.includes('Vendor.Approver')) {
        state.isApprover;
      }
      if (profiles.includes('Vendor.Vendor')) {
        state.isVendor = true;
      }
    });
  },

  setModalApproveState(state: ActionsState, show: boolean): void {
    state.modalApprove = show;
  },
  setModalDenyState(state: ActionsState, show: boolean): void {
    state.modalDeny = show;
  },
  setModalApproveDeniedState(state: ActionsState, show: boolean): void {
    state.modalApproveDenied = show;
  },
  setModalPendencyState(state: ActionsState, show: boolean): void {
    state.modalPendency = show;
  },

  setModalBackDocuments(state: ActionsState, show: boolean): void {
    state.modalBackDocuments = show;
  },
  setDisclaimerForm(state: ActionsState, show: boolean): void {
    state.disclaimerForm = show;
  },

  setModalPendencyCommentState(state: ActionsState, show: boolean): void {
    state.modalPendencyComment = show;
  },

  setOnboardingId(state: ActionsState, id: string): void {
    state.onboardingId = id;
  },

  setFeedback(state: ActionsState, feedback: Feedback) {
    state.feedback = feedback;
  },
  setPendencyComment(state: ActionsState, comment: string) {
    state.pendencyComment = comment;
  },
};
