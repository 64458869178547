import Header from '@/types/general/headers';

export default class usersHeaders {
  public static header: Header[] = [
    {
      text: 'User Email',
      align: 'start',
      sortable: true,
      value: 'userEmail',
      class: 'item-table-header item-table-header-users',
      width: '',
    },
    {
      text: 'Email Line Manager',
      align: 'start',
      sortable: true,
      value: 'lineManager',
      class: 'item-table-header item-table-header-users',
      width: '',
    },
    {
      text: 'Max Contract Value',
      align: 'start',
      sortable: true,
      value: 'maxValueForContract',
      class: 'item-table-header item-table-header-users',
      width: '15%',
    },
    {
      text: 'Max Payment Value',
      align: 'start',
      sortable: false,
      value: 'maxValueForPayment',
      class: 'item-table-header item-table-header-users',
      width: '',
    },{
      text: 'Master Approval',
      align: 'start',
      sortable: false,
      value: 'isMasterApproval',
      class: 'item-table-header item-table-header-users',
      width: '',
    },{
      text: 'Actions',
      align: 'start',
      sortable: false,
      value: 'isMasterApproval',
      class: 'item-table-header item-table-header-users',
      width: '',
    },
  ];
}
