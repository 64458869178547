import DocumentTypes from '@/types/documentType';
import formatDate from './formatDate';
import moment from 'moment';
export default function setDocumentExpirationDate(documentType: DocumentTypes) {
  if (documentType.name === '') return;
  const today = moment(new Date());
  const expirationDate = today.add(documentType.monthsToExpire, 'months');

  return documentType.monthsToExpire < 1
    ? 'This document not expires'
    : formatDate(expirationDate.toString());
}
