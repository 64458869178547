export default interface SystemInformations {
  appId: string;
  displayName: string;
  appRoles: AppRoles[];
  id: string;
}

interface AppRoles {
  appRoleId: string;
  isEnabled: boolean;
  permissions: Permissions[];
  roleName: string;
  value: string;
}

interface Permissions {
  actions: string[];
  resource: string;
}

const systemInformationsInitialValue: SystemInformations = {
  appId: '',
  displayName: '',
  appRoles: [],
  id: '',
};

export { systemInformationsInitialValue, Permissions };
