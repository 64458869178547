
interface UserData {
  userRoles: string[];
  userPermissions: Permissions[];
}
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import User from '@/types/auth/user';
import { getUserPhotoFromShield } from './shieldService';

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import HandleConvertToLower from '@/utils/handleConvertToLower';
import parseJwt from '@/utils/parseJwt';
import { AxiosInstance } from 'axios';

@Component({})
export default class App extends Vue {
  @Prop(Boolean) readonly mini!: boolean;

  @Getter('getUser', { namespace: 'modals' }) user!: User;
  @Action('setUser', { namespace: 'modals' }) setUser: any;

  @Action('canList', { namespace: 'modals' }) canList: any;
  @Getter('getCanList', { namespace: 'modals' }) getCanList!: any;
  @Action('canApprove', { namespace: 'modals' }) canApprove: any;
  @Action('canCreate', { namespace: 'modals' }) canCreate: any;
  @Action('canUpdate', { namespace: 'modals' }) canUpdate: any;
  @Action('canApproveDenied', { namespace: 'modals' }) canApproveDenied: any;

  @Action('setProfiles', { namespace: 'modals' }) setProfiles: any;
  @Action('setIsCollaborator', { namespace: 'modals' }) setIsCollaborator: any;
  @Action('setIsApprover', { namespace: 'modals' }) setIsApprover: any;
  @Action('setIsApproverMaster', { namespace: 'modals' }) setIsApproverMaster: any;
  @Action('setIsVendor', { namespace: 'modals' }) setIsVendor: any;

  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Action('setAreas', { namespace: 'modals' }) setAreas: any;
  @Getter('getAreas', { namespace: 'modals' }) getAreas!: any;

  @Getter('getSystemInformations', { namespace: 'permissions' })
  systemInformations!: any;
  @Action('setSystemInformations', { namespace: 'permissions' })
  saveSystemInformations!: any;

  @Getter('getUserPermissions', { namespace: 'permissions' })
  userPermissions!: any;
  @Action('setUserPermissions', { namespace: 'permissions' })
  saveUserPermissions!: any;

  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];
  @Action('setUserRoles', { namespace: 'permissions' })
  setUserRoles!: any;

  @Watch('$route')
  resetRouteStorage() {
    this.emailLogged = localStorage.getItem('userEmail');
  }

  public setUserEmail(item: string) {
    this.emailLogged = this.user.email;
    return item;
  }

  getUserName() {
    const name = this.user.name;
    const userName = name.replace('.', ' ');
    return userName;
  }

  public getInitials(): string {
    const name = this.user.name.replace('.', ' ');
    if (name.length < 1)
      return "N/A";
    let splitted = name.split(' ');
    if (splitted.length === 1)
      return splitted[0][0].toUpperCase() + splitted[0][1].toUpperCase()
    return splitted[0][0].toUpperCase() + splitted[1][0].toUpperCase()
  }
  public async getUserPhoto(): Promise<string> {
    const userId = this.user.id;
    const result = await getUserPhotoFromShield(userId);
    return result;
  }

  // this.mini = false;
  areas = [];
  drawer = null;
  gettingMenu = false;
  systemLanguage = 'pt';
  interval = {} as number;
  token: string | null = '';
  emailLogged: string | null = '';
  photo: string | null = '';

  private endpoint = new Endpoint();
  authService = new AuthService();

  handleConvertToLower = HandleConvertToLower;
  parseJwt = parseJwt;

  systemInformationStorage = {} as any;
  userPermissionsStorage = {} as any;

  async initialize(): Promise<void> {
    const userData = await this.authService.getTokenData();
    this.setUser(userData);
    if (userData) {
      const token = await this.authService.getIdToken();

      await this.getSystemInformations();
      await this.setUserPermissions(token);

      await this.setUser(userData);
      await this.setUserEmail(this.user.email);
      localStorage.setItem('userEmail', this.user.email);

      if (this.$route.name == null) {
        window.location.href = '/';
      }
    } else {
      if (this.$route.name != 'Login') {
        this.$router.replace({ name: 'Login' });
      }
    }
    //}
  }

  public async logout(): Promise<void> {
    await this.authService.logoutAsync();
    window.location.href = '/login';
  }

  public replaceRouter(router: string): void {
    this.$router.replace({
      path: `/${router}`,
    });
  }

  async getSystemInformations() {
    try {
      // criar uma constante para o appName e fazer a verificação do NODE_ENV e setar
      const instance = this.$http as AxiosInstance;
      const { data } = await instance.get(
        this.endpoint.auth.lestShield + `/${this.endpoint.appName}`,
        {
          headers: {
            "lesteKey": this.endpoint.auth.lesteKey as string
          }
        }
      );

      // return data;

      this.saveSystemInformations(data);
    } catch (error) {
      console.log('Erro ao obter informações do sistema');
    }
  }

  async setUserPermissions(token: string | null) {
    if (token !== null) {
      let userRoles: string[] = this.parseJwt(token).roles;

      // if (userRoles === undefined) userRoles = [];

      this.setUserRoles(userRoles);
      this.setProfiles(userRoles);

      const userPermissions = userRoles.flatMap(
        (role) =>
          this.systemInformations.appRoles.find((item: any) => item.value === role)?.permissions
      ) as Permissions[];

      // return userPermissions;

      this.saveUserPermissions(userPermissions);
    }

    // por enquanto vamos usar so no Corporative
    // if (!this.userHasAccessInThisSystem(userRoles, 'Finti')) return;
  }

  async mounted() {
    await this.initialize();
    this.photo = await this.getUserPhoto();
    this.token = await this.authService.getIdToken();
  }
}
