
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalPaymentFinanceActions extends Vue {
  @Prop(Boolean) readonly modalPaymentFinanceActions!: boolean;
  @Prop(Boolean) readonly approvingPayment!: boolean;

  @Watch('approvingPayment')
  hnadleCloseModal() {
    if (this.approvingPayment === false) this.closeModal;
  }

  sendPayment() {
    this.$emit('sendPayment');
  }

  bundlePayments() {
    this.$emit('showBundlePaymentsModal');
  }

  approvePayment() {
    this.$emit('approvePayment', '');
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
