
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Onboarding from '@/types/onboarding';
import DocumentType from '@/types/documentType';
import BackDraft from '@/types/backDrafts';

import Rules from '@/utils/rules';
import FormatDate from '@/utils/formatDate';
import SetDocumentExpirationDate from '@/utils/setDocumentExpirationDate';
import TruncateFile from '@/utils/truncateFile';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

const VIEW_DOCUMENT = 0;

@Component({})
export default class ModalBackDocuments extends Vue {
  @Getter('getOnboardingId', { namespace: 'modals' }) public onboardingId!: boolean;

  @Getter('getModalBackDocuments', { namespace: 'modals' }) public modalBackDocuments!: boolean;
  @Action('setModalBackDocuments', { namespace: 'modals' }) public setModalBackDocuments!: any;

  @Prop({ default: false }) public loading!: boolean;
  @Prop(Object) public onboardingData!: Onboarding;
  @Prop(Number) public onboardingStatus!: number;
  @Prop(Array) public documentTypes!: DocumentType[];
  @Prop(String) public handleArchiveIsDownloaded!: string;
  @Prop(String) public handleArchiveIsDeleting!: string;

  @Watch('loading')
  public handleCloseModal() {
    if (!this.loading) {
      this.tab = VIEW_DOCUMENT;
      this.selectedBackFiles = [];
      this.setItems();
    }
  }

  @Watch('handleArchiveIsDeleting')
  public reloadBackDraftList() {
    if (this.handleArchiveIsDeleting === '') this.setItems();
  }

  @Watch('modalBackDocuments')
  public setItems() {
    this.backDraftList = this.onboardingData.backDocuments.map((item) => {
      return {
        id: item.id,
        documentTypeSelected: this.documentTypes.find(
          (documentType) => documentType.id === item.documentType
        ) as DocumentType,
        expirationDate: item.expirationDate,
        observation: item.note,
        attachment: new File([`'${item.fileName}'`], item.fileName),
        fileName: item.fileName,
        url: item.url,
        hasChange: false,
        hasDeleted: false,
      };
    });
  }

  rules = new Rules();
  formatDate = FormatDate;
  setDocumentExpirationDate = SetDocumentExpirationDate;
  truncateFile = TruncateFile;

  tab: null | number = null;
  backDraft: BackDraft[] = [];
  backDraftList: BackDraft[] = [];
  backDraftAttachment: File = new File([], '');
  selectedBackFiles: File[] = [];
  backData = 0;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  setBackFiles(file: File) {
    this.selectedBackFiles.push(file);
  }

  public setBackArchiveName(item: BackDraft): string {
    let index = this.backDraft.indexOf(item);
    let file = this.backDraft[index].attachment;
    return file?.name;
  }

  public handleSetDocumentType(documentId: number): string | undefined {
    return this.documentTypes.find((item) => item.id === documentId)?.name;
  }

  public backDocumentHasChange(index: number): void {
    this.backDraft[index].hasChange = true;
  }

  public backDocumentHasDeleted(index: number): void {
    this.backDraft[index].hasDeleted = true;
  }

  handleDonwloadFile(item: BackDraft) {
    const params = {
      url: item.url,
      fileName: item.fileName,
    };
    this.$emit('downloadFile', params);
  }

  public removeBackDocument(item: BackDraft): void {
    this.backDraft.splice(this.backDraft.indexOf(item), 1);
  }

  public removeWaitingBackDocuments(item: File) {
    this.selectedBackFiles.splice(this.selectedBackFiles.indexOf(item), 1);
  }

  public removeBackAttachment(file: BackDraft): void {
    this.backData++;
    let index = this.backDraft.indexOf(file);
    this.backDraft[index].attachment = new File([], '');
  }

  public removeSelectedBackDocuments(item: BackDraft): void {
    if (item.id === '') {
      this.removeBackDocument(item);
      return;
    }

    this.$emit('deleteBackDocument', item);
  }

  public submitBackDocuments(): void {
    this.$emit('submitBackDocuments', this.selectedBackFiles);
  }

  public closeModal(): void {
    this.setModalBackDocuments(false);
    this.tab = null;
    this.selectedBackFiles = [];
  }
}
