
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalReprove extends Vue {
  @Prop(Boolean) readonly showReproveModal!: boolean;
  @Prop(Boolean) readonly showSendField!: boolean;
  @Prop(Boolean) readonly reprovingContract!: boolean;
  @Prop(String) readonly feedbackText!: string;
  @Prop(String) readonly btnText!: string;
  @Prop(Boolean) isAdditive!: boolean;
  @Prop(String) deniedMember!: string;

  @Watch('showReproveModal')
  handleClearReproveField() {
    if (this.showReproveModal === false) {
      this.reproveField = '';
    }
  }

  reproveField = '';

  //   @Watch('loading')
  //   handleReloadSubtaskData() {
  //     if (this.loading === false) {
  //       this.$emit('reloadSubtaskData');
  //     }
  //   }
  //   removeEntity() {
  //     this.$emit('removeEntity');
  //   }

  handleReprove() {
    if (this.isAdditive) {
      this.reproveAditive();
    } else {
      this.reproveContract();
    }
  }

  reproveAditive() {
    this.$emit('reproveAditive', this.reproveField);
  }

  reproveContract() {
    this.$emit('reproveContract', this.reproveField);
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
