export interface VendorPermission {
  userCanEdit: boolean;
  userCanCancel: boolean;
  userCanApprove: boolean;
  approveMember: string;
  userCanDenied: boolean;
  deniedMember: string;
  userCanCreateAllocation: boolean;
  userCanBlock: boolean;
  userCanCreateAdditive: boolean;
  userCanInsertSignedContract: boolean;
  userCanInsertInvoice: boolean;
}

export const EmptyPermission = {
  userCanEdit: false,
  userCanCancel: false,
  userCanApprove: false,
  approveMember: '',
  userCanDenied: false,
  deniedMember: '',
  userCanCreateAllocation: false,
  userCanBlock: false,
  userCanCreateAdditive: false,
  userCanInsertSignedContract: false,
  userCanInsertInvoice: false,  
}