
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';

@Component({
  components: {},
})
export default class ModalSelectPaymentForm extends Vue {
  @Prop(Boolean) public openSelectPaymentModal!: boolean;

  paymentType = null;

  closeModal() {
    this.$emit('closeModal');
    this.paymentType = null;
  }

  openNewPaymentModal() {
    this.$emit('openNewPaymentModal', this.paymentType);
    this.closeModal();
  }
}
