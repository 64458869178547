const clientId = process.env.VUE_APP_CLIENT_ID;
const authority = process.env.VUE_APP_AUTHORITY;
const redirectUri = process.env.VUE_APP_URL_REDIRECT;
const redirectLogoutUri = process.env.VUE_APP_URL_REDIRECT_LOGOUT;
// const scope = process.env.VUE_APP_SCOPE;
const user_impersonation_scope = process.env.VUE_APP_SCOPE_IMPERSONATION;

import * as Msal from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import User from '../types/auth/user';

interface Error {
  errorCode: string;
}

const msalConfig = {
  auth: {
    clientId: clientId as string, // NEED THIS
    authority: authority, // NEED THIS
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
  scopes: ['openid', 'profile'],
};

const tokenRequest: any = {
  scopes: [user_impersonation_scope],
  forceRefresh: false,
};

const tokenSecurityRequest: any = {
  scopes: ['https://leste-security.azurewebsites.net/user_impersonation'],
  forceRefresh: true,
};

const graphRequest: any = {
  scopes: ['User.Read'],
};

let instance: AuthService;
export class AuthService {
  msalInstance: Msal.PublicClientApplication;

  constructor() {
    this.msalInstance = new Msal.PublicClientApplication(msalConfig!);
    if (!instance){
      instance = this;
    } else {
      return instance;
    }
  }

  public async getAllAccountsAsync(): Promise<any[]> {
    const retVal = this.msalInstance.getAllAccounts();
    return retVal;
  }

  public async logoutAsync() {
    // localStorage.clear();
    await this.msalInstance.logout({
      postLogoutRedirectUri: redirectLogoutUri,
    });
  }

  public async getAccountsAsync(): Promise<any> {
    try {
      const accounts = this.msalInstance.getAllAccounts();

      if (accounts && accounts.length > 1) {
        return accounts;
      } else {
        return [];
      }
    } catch (error) {
      console.log('authService: getAccountsAsync()', error);
      return [];
    }
  }

  public async LoginByAccountAsync(account: any): Promise<string | null> {
    try {
      tokenRequest.account = account;

      const response = await this.msalInstance.acquireTokenSilent(tokenRequest);

      return response.accessToken;
    } catch (error) {
      // console.log('authService: LoginByAccountAsync()', error);
      await this.msalInstance.logout();
      return null;
    }
  }

  public async loginAsync(): Promise<string | void> {
    try {
      const response = await this.msalInstance.loginPopup(loginRequest);
      // console.log('response', response);

      return response.accessToken;
    } catch (error) {
      return;
    }
  }

  public async loginRedirect(){
    const account = this.msalInstance.getAllAccounts();
    if (account.length === 0){
      try {
        this.msalInstance.loginRedirect(loginRequest);
      } catch (error) {
        // console.log("authService I: loginAsync()", error);
      }
    }
  }

  public async tryLoginSilentOrRedirect(setUser: (user: User) => void): Promise<void>{
    const account = this.msalInstance.getAllAccounts();
    if (account.length === 0){
      try {
        const emailLogged = localStorage.getItem('userEmail');        
        const loginResponse = await this.msalInstance.ssoSilent({...loginRequest, loginHint: emailLogged ?? ''});
        console.log("🚀 ~ file: authService.ts:128 ~ AuthService ~ loginSilent ~ loginResponse:", loginResponse)
        await this.setAuthResult(loginResponse, setUser);
      } catch (error) {        
        if (error instanceof InteractionRequiredAuthError) {          
          await this.msalInstance.loginRedirect(loginRequest);
      } else {          
          console.log("authService: loginSilent()", error);
        }
      }
    }
  }

  public async handleRedirect(setUser: (user: User) => void): Promise<void> {

    const authResult = await this.msalInstance.handleRedirectPromise();
    await this.setAuthResult(authResult, setUser);
    // else {
    //   this.msalInstance.loginRedirect();
    // }
  }

  private async setAuthResult(authResult: Msal.AuthenticationResult | null, setUser: (user: User) => void): Promise<void> {
    if (authResult?.account){
      const user = await this.getTokenData();
      if (user !== null){
        localStorage.setItem('userEmail', user.email);
        const emailLogged = localStorage.getItem('userEmail');          
        setUser(user);
        window.location.href = "/";
      }
    } 
  }
  public async getTokenForRequiredAsync(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }

      tokenRequest.account = accounts[0];

      const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
      return response.accessToken;
    } catch (error) {
      // console.log('authService: getTokenForRequiredAsync()', error);
      return null;
    }
  }

  public async getTokenForMenuAsync(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }
      tokenSecurityRequest.account = accounts[0];

      const response = await this.msalInstance.acquireTokenSilent(tokenSecurityRequest);
      return response.accessToken;
    } catch (error) {
      // console.log('authService: getTokenForMenuAsync()', error);

      const response = await this.msalInstance.acquireTokenPopup(tokenSecurityRequest);

      return response.accessToken;
    }
  }

  public async getIdToken(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }
      tokenSecurityRequest.account = accounts[0];

      const response = await this.msalInstance.acquireTokenSilent(tokenSecurityRequest);
      return response.idToken;
    } catch (error) {
      // console.log('authService: getTokenForMenuAsync()', error);

      const response = await this.msalInstance.acquireTokenPopup(tokenSecurityRequest);

      return response.idToken;
    }
  }

  public async getTokenData(): Promise<User | null> {
    try {
      const currentAccounts = this.msalInstance.getAllAccounts();
      if (currentAccounts.length === 0) {
        return null;
      }

      if (currentAccounts.length === 1) {
        const data = this.msalInstance.getAccountByUsername(currentAccounts[0].username);
        return {
          name: data?.name,
          email: data?.username,
          id: data?.idTokenClaims?.oid
        } as User;
      }

      return null;
    } catch (error) {
      // console.log('authService: getTokenData()', error);

      return null;
    }
  }

  public async getTokenGraph(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();

      if (!accounts || accounts.length === 0 || accounts.length > 1) {
        return null;
      }

      graphRequest.account = accounts[0];
      const response = await this.msalInstance.acquireTokenSilent(graphRequest);

      return response.accessToken;
    } catch (error) {
      // console.log('authService I: getTokenGraph()', error);
      const responseError = error as Error;
      if (responseError.errorCode === 'invalid_grant') {
        try {
          const accounts = this.msalInstance.getAllAccounts();

          graphRequest.account = accounts[0];
          const response = await this.msalInstance.acquireTokenPopup(graphRequest);

          return response.accessToken;
        } catch (error) {
          // console.log('authService II: getTokenGraph()', error);
          return null;
        }
      }
      // console.log('authService: getTokenGraph(): ', JSON.parse(JSON.stringify(error)));
      return null;
    }
  }
}
