import { PermissionsState } from './types';

import SystemInformations, {
  Permissions,
} from '@/types/auth/systemInformations';

export default {
  setSystemInformations(state: PermissionsState, systemInformations: SystemInformations) {
    state.systemInformations = systemInformations;
  },
  setUserPermissions(state: PermissionsState, permissions: Permissions[]) {
    state.userPermissions = permissions;
  },

  setUserRoles(state: PermissionsState, userRoles: string[]) {
    state.userRoles = userRoles;
  },
};
