
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ContractByid from '@/types/contractsById';
import Selects, { selectsInitialValue } from '@/types/selects';
import SetApproversPayload from '@/types/SetApproversPayload';

@Component({})
export default class ModalEditApproveContract extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Prop(Boolean) readonly requiresSecondApprover!: boolean;
  @Prop(Boolean) showAddApproverModal!: boolean;
  @Prop(Boolean) gettingApprovers!: boolean;
  @Prop(Boolean) readonly addingApprover!: boolean;
  @Prop(Boolean) readonly isEditApprove!: boolean;
  @Prop(Boolean) readonly hasError!: boolean;
  @Prop(Object) contractData!: ContractByid;
  @Prop(Array) approverData!: Selects[];
  @Prop(String) textError!: string;

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  approverSelected: number[] = [];
  // approverSelected = selectsInitialValue;
  secondApproverSelected = selectsInitialValue;
  setContractApprover() {
    const params: SetApproversPayload = {
      approversId: this.approverSelected
    };
    this.$emit('setContractApprover', params);
    this.$forceUpdate();
  }

  closeModal() {
    this.$emit('closeModal');
    this.approverSelected = []
    this.secondApproverSelected = selectsInitialValue;
  }

  created() {
    if (this.isEditApprove) {
      this.approverSelected = this.contractData.approvers.map(a => a.id as number);
      this.secondApproverSelected = this.contractData.secondApproverId ? this.approverData.find(
        (approver) => approver.id === this.contractData.secondApproverId
      ) as Selects : selectsInitialValue;
    }
  }
}
