
import OnboardingInterface from '@/types/onboarding';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};
import moment from 'moment';
import { Money } from 'v-money';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Rules from '@/utils/rules';

import ModalLoading from '@/components/actions/ModalLoading.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';

import NewContractPayload from '@/types/NewContractPayload';
import User from '@/types/auth/user';
import RemoveDocumentPayload from '@/types/removeDocumentPayload';
import Selects, { selectsInitialValue } from '@/types/selects';

import { AuthService } from '@/services/authService';
import VendorResponse from '@/types/response/response';
import Endpoint from '@/utils/endpoint';
import CurrencyData, { currencyFormater } from '@/utils/currencyData';
import Onboarding from '@/views/Onboarding.vue';

@Component({
  components: { Onboarding, FileDisplay, ModalLoading, Money },
})
export default class ModalNewContract extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Prop(Boolean) public openNewContractModal!: boolean;
  @Prop(Boolean) public creatingContract!: boolean;
  // @Prop(Array) public companies!: Selects[];
  @Prop(Array) public managers!: Selects[];
  @Prop(Array) public vendors!: Selects[];
  @Prop(Array) public paymentMethods!: Selects[];
  private endpoint = new Endpoint();
  authService = new AuthService();

  @Watch('newContract.managerSelected')
  async refreshOwnerCompanies() {
    if (this.newContract.managerSelected.id !== selectsInitialValue.id) {
      await this.getCompaniesForOwner(this.newContract.managerSelected.email);
      this.newContract.lesteCompanySelected = selectsInitialValue;
    }
  }

  @Watch('onboardingItems')
  OnlyOneVendor() {
    if (this.onboardingItems.length === 1)
      this.newContract.entityVendorId = this.onboardingItems[0].entityId!;
  }
  @Watch('newContract.entityVendorId')
  async getBankDataFromOnboarding() {
    this.newContract.vendorEmail = this.user.email;
    if (!this.newContract.entityVendorId) {
      return;
    }
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(
        this.endpoint.onboarding.base + `/entity/${this.newContract.entityVendorId}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (!data || data.length === 0) {
        return;
      }
      const onboarding = data[0];
      this.bank = onboarding.bankId;
      this.agencyNumber = onboarding.agency;
      this.account = onboarding.account;
      this.accountDigit = onboarding.accountDigit;
      this.isOnShore = onboarding.onShore;
      this.newContract.paymentTypeSelected = selectsInitialValue;
    } catch (error) {
      this.bank = '';
      this.agencyNumber = '';
      this.account = '';
      this.accountDigit = '';
    }
  }

  contractSteps = 1;
  isDraggingContract = false;
  uploadContractInput = 0;
  files: Blob = new Blob();
  isDraggingAdditional = false;
  uploadAdditionalInput = 0;
  additionalFiles: Blob[] = [];

  ownerCompanies: Selects[] = [];
  ownerCompaniesLoading = false;
  gettingPaymentMethods = false;
  gettingCompanies = false;
  gettingManagers = false;
  showExecutionDeadline = false;
  contractIsSigned = false;
  isOnShore = false;
  bank = '';
  agencyNumber = '';
  account = '';
  accountDigit = '';
  currencyData = CurrencyData;
  currencyFormater = currencyFormater;
  newContract = {
    name: '',
    executionDealine: '',
    lesteCompanySelected: selectsInitialValue,
    managerSelected: selectsInitialValue,
    value: 0,
    scope: '',
    contract: '',
    paymentTypeSelected: selectsInitialValue,
    vendorEmail: '',
    entityVendorId: '',
    currency: 'USD',
    financeCanManage: false,
  };

  dolar = {
    decimal: '.',
    thousands: ',',
    precision: 2,
  };

  rules = new Rules();

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  created() {
    this.newContract.vendorEmail = this.user.email;
  }

  validateAmount() {
    if (this.newContract.value <= 0) {
      return 'Amount must be more than zero';
    } else {
      this.contractSteps++;
    }
  }

  testeNextStep() {
    if (!this.form.validate()) return;
  }

  closeModal() {
    this.$emit('closeModal');
    this.clearFields();
  }

  clearFields() {
    this.newContract = {
      name: '',
      executionDealine: '',
      lesteCompanySelected: selectsInitialValue,
      managerSelected: selectsInitialValue,
      scope: '',
      value: 0,
      contract: '',
      paymentTypeSelected: selectsInitialValue,
      vendorEmail: '',
      entityVendorId: '',
      currency: 'USD',
      financeCanManage: false,
    };

    this.contractSteps = 1;
    this.files = new Blob();
    this.additionalFiles = [];
  }

  setContractFile() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractFile as any).files[0];
  }

  contractDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  contractDragleave() {
    this.isDraggingContract = false;
  }

  dropContractFile(e: any) {
    e.preventDefault();
    (this.$refs.contractFile as any).files = e.dataTransfer.files;
    this.setContractFile();
    this.isDraggingContract = false;
  }

  setAdditionalFile() {
    this.uploadAdditionalInput++;
    const additionalFiles = (this.$refs.additionalFile as any).files as FileList;
    for (let [key, value] of Object.entries(additionalFiles)) {
      this.additionalFiles.push(value);
    }
  }

  additionalDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  additionalDragleave() {
    this.isDraggingContract = false;
  }

  dropAdditionalFile(e: any) {
    e.preventDefault();
    (this.$refs.additionalFile as any).files = e.dataTransfer.files;
    this.setAdditionalFile();
    this.isDraggingContract = false;
  }

  removeFile(payload: RemoveDocumentPayload) {
    if (payload.key === null) {
      this.files = new Blob();
    } else {
      this.additionalFiles.splice(payload.key, 1);
    }
  }

  public handleFormatDate(date: string): string {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }

  public async getCompaniesForOwner(ownerEmail: string | undefined) {
    this.ownerCompaniesLoading = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get<VendorResponse<Selects[]>>(
        this.endpoint.selects.companies + `?ownerEmail=${ownerEmail}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (!data || data.length === 0) {
        return;
      }
      this.ownerCompanies = data;
    } catch (error) {
      this.ownerCompanies = [];
    } finally {
      this.ownerCompaniesLoading = false;
    }
  }

  public onboardingItems: OnboardingInterface[] = [];
  public async getOnboarding(): Promise<void> {
    const token = await this.authService.getIdToken();

    const params = {
      Status: 5,
    };
    try {
      const onboardings = await this.$http.get(this.endpoint.onboarding.base, {
        params,
        headers: { Authorization: `${token}` },
      });
      this.onboardingItems = onboardings.data.items;
    } catch (error) {
      console.log('Ocorreu um erro ao listar onboardings');
    }
  }

  public showBankData(id: string | number | undefined): boolean {
    return id !== undefined && id !== '' && id !== 28 && id !== 33;
  }

  public tomorrow(): string {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString();
  }

  public filterPaymentMethods(): Selects[] {
    if (this.isOnShore) {
      return this.paymentMethods.filter((paymentMethod) => paymentMethod.id !== 33);
    }
    return this.paymentMethods.filter((paymentMethod) => paymentMethod.id === 33);
  }
  async createContract() {
    const params: NewContractPayload = {
      title: this.newContract.name,
      companyId: this.newContract.lesteCompanySelected.id as string,
      contractNumber: '0',
      deadlineContract: this.newContract.executionDealine,
      contractValue: this.newContract.value,
      description: this.newContract.scope,
      ManagementContractEmail: this.newContract.managerSelected.email as string,
      ContractFile: this.files,
      PaymentMethodId: this.newContract.paymentTypeSelected.id as string,
      Status: 'Open',
      Documents: this.additionalFiles,
      EntityVendorId: this.newContract.entityVendorId,
      VendorEmail: this.isVendor ? this.newContract.vendorEmail : null,
      Currency: this.newContract.currency,
      FinanceCanManage: this.newContract.financeCanManage,
      contractIsSigned: this.contractIsSigned,
    };

    this.$emit('createContract', params);
  }

  public async mounted() {
    this.getOnboarding();
  }
}
