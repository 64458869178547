
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

const namespace = 'add';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalReproveContract extends Vue {
  @Prop(Boolean) modalReproveContract!: boolean;
  @Prop(Boolean) reprovingContract!: boolean;

  // @Watch('reprovingContract')
  // handleCloseModal() {
  //   if (!this.reprovingContract) this.closeModal();
  // }

  async reproveContract() {
    this.$emit('reproveContract');
  }

  closeModal(): void {
    this.$emit('closeModal');
  }
}
