export default interface FinancialBudget {
  id: number;
  name: string;
  siengeValue: string;
  disabled: boolean;
  isExternal: boolean;
}

const financialBudgetInitialVlaue: FinancialBudget = {
  id: 0,
  name: '',
  siengeValue: '',
  disabled: false,
  isExternal: false,
};

export { financialBudgetInitialVlaue };
