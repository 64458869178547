
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import FeedbackInterface from '@/types/general/feedback';

@Component({})
export default class Feedback extends Vue {
  @Getter('getFeedback', { namespace: 'modals' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  // closeFeedback() {
  //   this.setFeedback({
  //     feedback: false,
  //   });
  // }
}
