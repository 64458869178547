import { CostCenterAllocation, Documents } from "./contractsById";
import { EmptyPermission, VendorPermission } from "./VendorPermission";
import Selects from "./selects";
export default interface AdditiveById {
  id: string;
  contractId: string;
  title: string;
  additiveValue: number;
  description: string;
  deadlineAdditive: string;
  createdAt: string;
  status: string;
  documents: Documents[];
  contract: ContractAdditive;
  vendor: VendorAdditive;
  permission: VendorPermission;
  feedBacks: FeedBack[];
}

export interface FeedBack {
  email: string;
  occupation: string;
  content: string;
  name: string;
  createdDate: string;
}

export interface DocumentAdditive {
  id: string;
  contractId: string;
  fileName: string;
  sharepointPath: string;
  fileExtension: string;
  type: string;
  createdAt: string;
}

export interface ContractAdditive {
  id: string;
  title: string;
  workflowId: string;
  companyId: number;
  contractNumber: string;
  deadlineContract: string;
  contractValue: number;
  description: string;
  paymentMethodId: number;
  management: string;
  isAdditive: true;
  statusTitle: string;
  createdAt: string;
  additiveId: string;
  companyName: string;
  approverId: number;
  approverName: string;
  secondApproverId: number;
  secondApproverName: string;
  costCenterAllocations: CostCenterAllocation[];
  entityId: string;
  currency: string;
  financeCanManage: boolean;
  approvers: Selects[];
}

export interface VendorAdditive {
  companyName: string;
  shortName: string;
  identifier: string;
  jurisdictionId: string;
  jurisdictionOfOperations: JurisdictionOfOperationsAndActivity[];
  activityTypes: JurisdictionOfOperationsAndActivity[];
  formOrganizationId: string;
}

export interface JurisdictionOfOperationsAndActivity {
  id: string;
  name: string;
}

const additiveByIdInitialValue: AdditiveById = {
  id: '',
  contractId: '',
  title: '',
  additiveValue: 0,
  description: '',
  deadlineAdditive: '2022-11-29T13:54:55.013Z',
  createdAt: '2022-11-29T13:54:55.013Z',
  status: '',
  documents: [],
  contract: {
    id: '',
    title: '',
    workflowId: '',
    companyId: 0,
    contractNumber: '',
    deadlineContract: '',
    contractValue: 0,
    description: '',
    paymentMethodId: 0,
    management: '',
    isAdditive: true,
    statusTitle: '',
    createdAt: '',
    additiveId: '',
    companyName: '',
    approverId: 0,
    approverName: '',
    secondApproverId: 0,
    secondApproverName: '',
    costCenterAllocations: [],
    entityId: '',
    currency: '',
    financeCanManage: false,
    approvers: [],
  },
  vendor: {
    companyName: '',
    shortName: '',
    identifier: '',
    jurisdictionId: '',
    jurisdictionOfOperations: [
      {
        id: '',
        name: '',
      },
    ],
    activityTypes: [
      {
        id: '',
        name: '',
      },
    ],
    formOrganizationId: '',
  },
  feedBacks: [],
  permission: EmptyPermission
};

export { additiveByIdInitialValue };
