
interface Files {
  files: [];
}

const PAYMENT_WITH_CONTRACT = 1;
const PAYMENT_WITHOUT_CONTRACT = 2;

import moment from 'moment';
import { Money } from 'v-money';
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { AuthService } from '@/services/authService';
import CurrencyData, { currencyFormater } from '@/utils/currencyData';
import Endpoint from '@/utils/endpoint';
import Rules from '@/utils/rules';

import ModalLoading from '@/components/actions/ModalLoading.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';

import PaymentActivity from '@/types/PaymentActivity';
import User from '@/types/auth/user';
import Contract, { contractInitialValue } from '@/types/contracts';
import ApprovalMode from '@/types/ApprovalMode';
import NewPaymentPayload, { Services } from '@/types/newPaymenPayload';
import Selects, { FintiCompany, selectsInitialValue } from '@/types/selects';
import { contractByIdInitialValue } from '@/types/contractsById';
import { setErrorText } from '@/utils/errorHandler';
@Component({
  components: { FileDisplay, ModalLoading, Money },
})
export default class ModalNewPayment extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];
  @Ref('formIsVendorStep1') formIsVendorStep1!: any;
  @Ref('formIsVendorStep2') formIsVendorStep2!: any;
  @Getter('getUser', { namespace: 'modals' }) user!: User;
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Prop(Boolean) public newPaymentModal!: boolean;
  @Prop(Boolean) public creatingContract!: boolean;
  @Prop(Boolean) public gettingContract!: boolean;
  @Prop(Number) public paymentTypeSelect!: number;
  @Prop(Object) public requesterCompanies!: Selects[];
  @Prop(Array) public companies!: Selects[];
  @Prop(Array) public managers!: Selects[];
  @Prop(Array) public vendors!: Selects[];
  @Prop(Array) public contracts!: Contract[];
  @Prop(Array) public paymentMethods!: Selects[];
  @Prop(Array) public documentTypes!: Selects[];
  @Prop(Array) public approverData!: Selects[];

  gettingCompanies = false;
  fintiCompanies: FintiCompany[] = [];
  loadingMessage: string = '';
  approvers: { [key: number]: Selects } = {};
  approversContract: any[] = [];
  userHasBalance = false;

  @Watch('newPayment.contractSelected')
  async setContractDependenciesByContractSelected() {
    // console.log('Contract Select:', this.newPayment.contractSelected);
    this.loadingVendor = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(
        this.endpoint.contract.base + `/${this.newPayment.contractSelected.id}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      // this.contractsSelected = data;
      this.newPayment.lesteUserEmail = data.lesteUserEmail;
      this.newPayment.contractId = data.id;
      this.vendorEmail = data.vendorEmail;
      this.approversContract = data.approvers;
      this.newPayment.entityId = data.vendor.entityId;
      this.newPayment.approverSelected = data.approvers
        .sort((a: Selects, b: Selects) => (a.order ?? 0) - (b.order ?? 0))
        .map((a: Selects) => a.id);

      this.newPayment.grossValue = data.restContractValue;
      this.newPayment.vendorName = data.vendor.shortName;
      this.newPayment.currency = data.currency;
      this.newPayment.paymentFormSelected =
        this.paymentMethods.find((paymentMethod) => paymentMethod.id === data.paymentMethodId) ??
        selectsInitialValue;
    } catch (error) {
      console.log('Ocorreu um erro ao obter o aprovador deste contrato');
    }
    this.loadingVendor = false;
  }

  @Watch('newPayment.vendorSelected')
  getContractByVendorSelected() {
    this.$emit('getContractsByVendor', this.newPayment.vendorSelected.entityId);
  }

  private endpoint = new Endpoint();
  authService = new AuthService();
  validate() {
    if (this.paymentSteps === 1) {
      if (this.formIsVendorStep1.validate()) {
        this.paymentSteps = 2;
      }
    } else if (this.paymentSteps === 2) {
      if (this.formIsVendorStep2.validate()) {
        this.handleCreateContract();
      }
    }
  }

  get title() {
    return this.paymentTypeSelect === PAYMENT_WITH_CONTRACT
      ? 'Payment with contract '
      : 'Payment without Contract';
  }

  paymentSteps = 1;
  isDraggingPaymentDocument = false;
  uploadPaymentContractInput = 0;
  paymentDocuments: Blob[] = [];

  contractsSelected: Contract = contractInitialValue;
  vendorEmail: string = '';

  currencyData = CurrencyData;
  currencyFormater = currencyFormater;

  activitiesRestValue = 0;

  activitiesValueExceededPaymentValue = false;

  loadingVendor = false;

  paymentActivities: PaymentActivity[] = [];

  rules = new Rules();
  loadingApprovers = false;

  currencsy: Record<string, any> = {
    EUR: {
      currencyFormater: currencyFormater.euro,
      type: '€',
    },
    BRL: { currencyFormater: currencyFormater.real, type: 'R$' },
    GBP: {
      currencyFormater: currencyFormater.libra,
      type: '£',
    },
    USD: { currencyFormater: currencyFormater.dolar, type: '$' },
  };

  @Watch('paymentActivities', { deep: true })
  async verifyIfActivitesValueExceedPaymentTotalValue(
    val: PaymentActivity[],
    oldVal: PaymentActivity[]
  ) {
    const activityTotalValue = this.getActivityTotalValue();
    await this.updateApprovers(val);
    if (activityTotalValue > this.newPayment.grossValue) {
      this.activitiesValueExceededPaymentValue = true;
    } else {
      this.activitiesValueExceededPaymentValue = false;
    }
  }

  @Watch('newPayment.grossValue', { immediate: true })
  handleSetFirstActivityValue() {
    if (
      this.paymentTypeSelect === PAYMENT_WITHOUT_CONTRACT &&
      this.paymentActivities.length === 1
    ) {
      this.paymentActivities[0].grossValue = this.newPayment.grossValue;
    }
  }

  async updateApprovers(activities: PaymentActivity[]): Promise<void> {
    this.loadingApprovers = true;
    const tasks: Promise<void>[] = [];
    for (const activity of activities) {
      if (activity.payingCompanySelected === undefined) continue;
      if (this.approvers[activity.payingCompanySelected.id] === undefined) {
        tasks.push(this.setApproversForPayingCompany(activity.payingCompanySelected.id));
      }
    }
    await Promise.all(tasks);
    this.loadingApprovers = false;
  }

  async setApproversForPayingCompany(payingCompanyId: number): Promise<void> {
    const approvers = await this.$http.get(
      this.endpoint.company.approvers + `/${payingCompanyId}`,
      {
        headers: {
          lesteKey: this.endpoint.auth.fintiServicesKey!,
          'API-Key': this.endpoint.auth.fintiKey!,
          'x-vendor-email': this.user.email,
        },
      }
    );
    // console.log("APPROVERS: ", approvers)
    this.approvers[payingCompanyId] = approvers;
    // console.log("APPROVERS COMPANY: ", this.approvers[payingCompanyId])
  }

  getActivityTotalValue() {
    let activityTotalValue = 0;
    this.paymentActivities.forEach((activity) => (activityTotalValue += activity.grossValue));
    return activityTotalValue;
  }

  getActivitiesRestValue() {
    const activityTotalValue = this.getActivityTotalValue();
    if (this.newPayment.grossValue - activityTotalValue <= 0) {
      return 0;
    }
    return this.newPayment.grossValue - activityTotalValue;
  }

  addNewActivity() {
    const newActivity = new PaymentActivity();
    newActivity.grossValue = this.getActivitiesRestValue();
    this.paymentActivities.push(newActivity);
  }

  setActivityHeaderName(index: number) {
    return `Activity ${index + 1}`;
  }

  removeActivity(index: number) {
    this.paymentActivities.splice(index, 1);
  }

  limitOnlyNumber(evt: any) {
    const dot = 46;
    const comma = 44;
    const firstDigit = 48;
    const lastDigit = 57;

    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < firstDigit || charCode > lastDigit) && charCode !== dot) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  // companies: Selects[] = [];
  // managers: Selects[] = [];
  // paymentMethods: Selects[] = [];
  gettingPaymentMethods = false;
  gettingManagers = false;
  showPaymentDeadline = false;
  showIssueDate = false;
  loadingModes = false;
  approvalModes: ApprovalMode[] = [];
  approvalModeSelected: number = 3;
  newPayment = {
    name: '',
    contractSelected: contractByIdInitialValue,
    approverSelected: [] as number[],
    vendorSelected: selectsInitialValue,
    managerSelected: selectsInitialValue,
    documentTypeSelected: selectsInitialValue,
    documentNumber: '',
    paymentFormSelected: selectsInitialValue,
    barCode: '',
    currency: '',
    grossValue: 0,
    expirationDate: '',
    issueDate: '',
    paymentDealine: '',
    paymentDescription: '',
    vendorName: '',
    entityId: '',
    lesteUserEmail: '',
    contractId: '',
    approvalModeId: 3,
  };

  paymentReason = '';

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isUniqueVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.length === 1 && this.userRoles.includes('Vendor.Vendor');
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  get isManagement() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Management');
  }

  closeModal() {
    this.clearFields();
    this.$emit('closeNewPaymentModal');
  }

  clearFields() {
    this.newPayment = {
      name: '',
      contractSelected: contractByIdInitialValue,
      approverSelected: [],
      vendorSelected: selectsInitialValue,
      managerSelected: selectsInitialValue,
      documentTypeSelected: selectsInitialValue,
      documentNumber: '',
      paymentFormSelected: selectsInitialValue,
      barCode: '',
      currency: '',
      grossValue: 0,
      expirationDate: '',
      issueDate: '',
      paymentDealine: '',
      paymentDescription: '',
      vendorName: '',
      entityId: '',
      lesteUserEmail: '',
      contractId: '',
      approvalModeId: 3,
    };
    this.paymentSteps = 1;
    this.$emit('resetUserHasBalance');

    this.paymentActivities = [new PaymentActivity()];
  }

  setPaymentDocument() {
    this.uploadPaymentContractInput++;
    const pymentDocuments = (this.$refs.paymentDocumentsForm as any).files as FileList;
    for (let [key, value] of Object.entries(pymentDocuments)) {
      this.paymentDocuments.push(value);
    }
  }

  validateAmount(money: any) {
    if (money < 0) {
      this.newPayment.grossValue = 0;
    }
  }

  paymentDocumentDragOver(e: any) {
    e.preventDefault();
    this.isDraggingPaymentDocument = true;
  }

  paymentDocumentDragLeave() {
    this.isDraggingPaymentDocument = false;
  }

  dropPaymentDocument(e: any) {
    e.preventDefault();
    (this.$refs.paymentDocumentsForm as any).files = e.dataTransfer.files;
    this.setPaymentDocument();
    this.isDraggingPaymentDocument = false;
  }

  removeFile(key: number) {
    this.paymentDocuments.splice(key, 1);
  }

  setActivityWrapperHeightByActivitiesSize() {
    return 276 * this.paymentActivities.length + 160;
  }

  setStepperheight() {
    if (
      this.paymentSteps === 2 &&
      this.paymentTypeSelect === PAYMENT_WITHOUT_CONTRACT &&
      this.paymentActivities.length > 1
    ) {
      return `height: ${this.setActivityWrapperHeightByActivitiesSize()}px`;
    }
  }

  public handleFormatDate(date: string | undefined): string {
    if (!date) return '';
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }

  handleCreateContract() {
    this.paymentTypeSelect === PAYMENT_WITH_CONTRACT
      ? this.createPaymentWithContract()
      : this.createPaymentWithoutContract();
  }

  async verifyContractBalance() {
    const params = {
      contractId: this.newPayment.contractSelected.id as string,
      paymentValue: this.newPayment.grossValue,
    };
    this.creatingContract = true;
    this.loadingMessage = 'Checking contract balance';
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(
        this.endpoint.contract.base + `/${params.contractId}/check-contract-balance`,
        {
          params,
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      this.userHasBalance = data as boolean;
      console.log(
        '🚀 ~ file: ModalNewPayment.vue:1467 ~ ModalNewPayment ~ verifyContractBalance ~ userHasBalance:',
        this.userHasBalance
      );
      this.loadingMessage = '';
      if (this.userHasBalance) {
        await this.createPaymentWithContract();
      }
      if (!this.userHasBalance) {
        setTimeout(() => (this.paymentSteps = 3), 200);
      }
      if (!data) this.creatingContract = false;
    } catch (error) {
      console.log('Ocorreu um erro ao verificar saldo deste contrato', error);
      this.userHasBalance = false;
    }
  }

  async createPaymentWithContract() {
    const params: NewPaymentPayload = {
      title: this.newPayment.name,
      contractId: this.newPayment.contractId,
      documentTypeId: this.newPayment.documentTypeSelected.id as number,
      paymentMethodId: this.newPayment.paymentFormSelected.id as number,
      editableRow: this.newPayment.barCode,
      currency: this.newPayment.currency,
      paymentValue: this.newPayment.grossValue,
      issueDate: this.newPayment.issueDate,
      expirationDate: this.newPayment.expirationDate,
      lesteUserEmail: this.newPayment.lesteUserEmail,
      vendorEmail: this.vendorEmail,
      EntityVendorId: this.newPayment.entityId,
      paymentDescription: `${this.newPayment.name} - ${this.newPayment.issueDate} \n ${this.newPayment.paymentDescription ?? ''
        }`,
      attachments: this.paymentDocuments,
      services: [],
      approvalModeId: this.newPayment.approvalModeId,
    };
    console.log('PARAMS:', params);
    this.creatingContract = true;
    this.loadingMessage = 'Creating payment, please wait...';
    try {
      const token = await this.authService.getIdToken();
      const formData = new FormData();

      for (const [key, value] of Object.entries(params)) {
        if (key === 'attachments') {
          params.attachments.forEach((item) => formData.append('Attachments', item));
        } else {
          formData.append(key, value);
        }
      }
      await this.$http.post(this.endpoint.payment.base, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      });
      this.creatingContract = false;
      this.closeModal();
      this.loadingMessage = '';
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Payment Successfully created',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      this.$emit('getPayments');
    } catch (error: any) {
      this.closeModal();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      this.creatingContract = false;
      console.log(error);
    } finally {
      this.closeModal();
    }
  }
  entityId: string | undefined = '';
  gettingEntityIdIsVendor() {
    this.vendors.forEach((item) => {
      if (item.email?.toLowerCase() === this.user.email?.toLowerCase()) {
        this.entityId = item!.entityId;
      }
    });
  }
  createPaymentWithoutContract() {
    const params: NewPaymentPayload = {
      title: this.newPayment.name,
      contractId: null,
      documentTypeId: this.newPayment.documentTypeSelected.id as number,
      paymentMethodId: this.newPayment.paymentFormSelected.id as number,
      editableRow: this.newPayment.barCode,
      currency: this.newPayment.currency,
      paymentValue: this.newPayment.grossValue,
      issueDate: this.newPayment.issueDate,
      expirationDate: this.newPayment.expirationDate,
      lesteUserEmail: this.isVendor
        ? (this.newPayment.managerSelected.email as string)
        : this.user.email,
      vendorEmail: this.isVendor ? this.user.email : null,
      EntityVendorId: !this.isVendor
        ? (this.newPayment.vendorSelected.entityId as string)
        : this.entityId!,
      paymentDescription: this.newPayment.paymentDescription,
      attachments: [],
      services: this.setServicesToCreatePayment(this.paymentActivities),
      approvalModeId: this.approvalModeSelected,
    };
    this.$emit('createPaymentWithoutContract', params);
  }

  setServicesToCreatePayment(activities: PaymentActivity[]) {
    let services: Services[] = [];

    services = activities.map((activity) => {
      const mapped: Services = {
        title: activity.activity,
        requesterEntityId: activity.lesteRequester.id as string,
        buRequesterId: activity.lesteRequester.businessUnityId as string,
        grossTotal: activity.grossValue,
        runtime: activity.deadline as string,
        usedHours: parseFloat(activity.hoursUsed.toString()),
        paymentCompanyId: activity.payingCompanySelected?.id!,
        approversId: activity.approversSelected,
      };
      mapped.approvalModeId = activity.approvalModeId;
      return mapped;
    });

    return services;
  }

  async getApprovalModes() {
    try {
      this.loadingModes = true;
      const data = await this.$http.get<ApprovalMode[]>(this.endpoint.approval.modes, {
        headers: {
          lesteKey: this.endpoint.auth.fintiServicesKey!,
        },
      });
      this.approvalModes = data;
    } catch {
      this.approvalModes = [];
    } finally {
      this.loadingModes = false;
    }
  }
  async getFintiCompanies() {
    this.gettingCompanies = true;
    try {
      const companies = await this.$http.get<FintiCompany[]>(this.endpoint.company.base, {
        headers: {
          lesteKey: this.endpoint.auth.fintiServicesKey!,
          'API-Key': this.endpoint.auth.fintiKey!,
          'x-vendor-email': this.user.email,
        },
      });
      this.fintiCompanies = companies.filter((c) => c.enabled === true && c.name.trim() !== '');
      this.gettingCompanies = false;
    } catch (error) {
      this.gettingCompanies = false;
      console.log('Ocorreu um erro ao obter lista de planos companies', error);
    }
  }
  created() {
    this.addNewActivity();
    this.getApprovalModes();
    this.getFintiCompanies();
    this.gettingEntityIdIsVendor();
  }
}
