<template>
  <div>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 8C1 12.2 4.073 15 7.8642 15C10.9456 15 13.432 13.3655 14.3 10.8M15 8C15 3.8 11.927 1 8.1358 1C5.0537 1 2.568 2.6345 1.7 5.2"
        :stroke="disabled ? '#AEAFB2' : '#34383F'"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 11.5014L14.3 10.8014L15 13.6014M4.5 4.50137L1.7 5.20137L1 2.40137"
        :stroke="disabled ? '#AEAFB2' : '#34383F'"
        stroke-linejoin="round"
      />
      <path
        d="M8.78571 9.22988H6.70714L6.35 10.25H5.5L7.27857 5.25H8.22143L10 10.25H9.14286L8.78571 9.22988ZM8.55714 8.56178L7.75 6.24138L6.93571 8.56178H8.55714Z"
        :fill="disabled ? '#AEAFB2' : '#34383F'"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ['disabled'],
};
</script>

<style lang="scss"></style>
