
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ServiceResponse from '@/types/services/response';
import DocumentData from '@/components/contracts/DocumentData.vue';

import Selects from '@/types/selects';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import setStatusClass from '@/utils/setStatus';

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import qs from 'qs';

import DocumentPayload, { documentPayloadInitialValue } from '@/types/documentsPayload';
import PaymentsById, { Services } from '@/types/paymentById';
import RemoveDocumentPayload from '@/types/removeDocumentPayload';

import EditingModal from '@/components/Modals/EditingModal.vue';
import ModalApprove from '@/components/Modals/ModalApprove.vue';
import ModalCancel from '@/components/Modals/ModalCancel.vue';
import ModalNewAlocation from '@/components/Modals/ModalNewAlocation.vue';
import ModalSend from '@/components/Modals/ModalSend.vue';
import ModalLoading from '@/components/actions/ModalLoading.vue';

import FileDisplay from '@/components/contracts/FileDisplay.vue';
import Contract from '@/types/contracts';
import CostCenter from '@/types/costCenter';
import FinancialBudget from '@/types/financialBudget';

import VueSkeletonLoader from 'skeleton-loader-vue';

@Component({
  components: {
    DocumentData,
    FileDisplay,
    ModalApprove,
    EditingModal,
    ModalNewAlocation,
    ModalSend,
    ModalCancel,
    VueSkeletonLoader,
    ModalLoading,
  },
})
export default class ViewModal extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Prop(Object) photos!: {[key: string] : string};
  @Prop(Boolean) showContractPaymentModal!: boolean;
  @Prop(Boolean) showSkeleton!: boolean;
  @Prop(Boolean) loadingSendNF!: boolean;
  @Prop(Boolean) removingInvoicePayment!: boolean;
  @Prop(Object) paymentById!: PaymentsById;
  @Prop(Array) paymentMethods!: Selects[];
  @Prop(Array) documentTypes!: Selects[];
  @Prop(Array) requesterCompanies!: Selects[];
  @Prop(Array) contractData!: Contract[];
  @Prop(Array) companiesData!: Selects[];
  @Prop(Array) costCenterData!: CostCenter[];
  @Prop(Array) financialBudgetData!: FinancialBudget[];
  @Prop(Number) tab!: number;  
  @Watch('paymentById.documents', { immediate: true })
  setDocumentsPayment() {
    if (this.showContractPaymentModal) {
      if (this.paymentById.documents.length > 0){
        const document = this.paymentById.documents[0];        
        this.paymentNFFile = {
          id: document.id,
          contractId: document.contractId,
          fileName: document.fileName,
          sharepointPath: document.sharepointPath,
          fileExtension: document.fileExtension,
          type: document.type,
          createdAt: document.createdAt,
        };
      } else {
        this.paymentNFFile = documentPayloadInitialValue;
      }
    }
  }

  formatDate = FormatDate;
  formatMoney = FormatMoney;
  setStatusClass = setStatusClass;
  authService = new AuthService();
  private endpoint = new Endpoint();

  addAlocation = false;
  addContractSign = false;
  showModalCancel = false;
  showModalAprove = false;
  showModalSend = false;
  showSendField = false;
  showModalEdit = false;
  showNewAlocationModal = false;
  isDraggingContract = false;
  feedbackText = '';
  btnText = '';

  uploadContractInput = 0;

  additionalFiles: Blob[] = [];
  files: Blob = new Blob();

  paymentNFFile: DocumentPayload = documentPayloadInitialValue;
  get isOnlyViewer() {
    if (this.userRoles === undefined) return true;
    const result = this.userRoles.some(r => r.toLowerCase().includes('financeviewer'))
    && !this.userRoles.some(r => r.toLowerCase() === 'vendor.finance' 
                          || r.toLowerCase() === 'vendor.management'
                          || r.toLowerCase() === 'vendor.vendor');
    return result;
    //return this.userRoles.includes('Vendor.Finance');
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  get isManagement() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Management');
  }

  get showNFTab() {
    if (this.paymentById.contractId === null && this.paymentById.statusTitle === 'Waiting Invoice')
      return true;
    if (this.paymentById.contractId === null && this.paymentById.statusTitle === 'Waiting Payment')
      return true;
    return false;
  }

  get showActivityTab() {
    if (this.paymentById.contractId !== null) return false;
    return true;
  }

  get showEditActivityButton() {
    if (this.isManagement && this.paymentById.statusTitle === 'Owner Review') return true;
    if (this.isFinance && this.paymentById.statusTitle === 'Finance Review') return true;
    return false;
  }

  get showWaitingNFMessage() {
    if (
      this.isVendor === false &&
      this.paymentById.statusTitle === 'Waiting NF' &&
      (this.paymentById.documents.length > 0 || this.files.size > 0)
    )
      return true;
    return false;
  }

  async handleDownloadFile(fileData: any) {
    try {
      const token = await this.authService.getIdToken();
      const response = await this.$http.get(this.endpoint.files.download, {
        params: {
          fileUrl: fileData.fileUrl,
        },
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          'content-type': 'blob',
          Authorization: `${token}`,
        },
        responseType: 'blob',
      });

      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        const result: string | ArrayBuffer = reader.result ? reader.result : '';
        const downloadLink = document.createElement('a');
        downloadLink.href = result.toString();
        downloadLink.download = fileData.fileName;
        downloadLink.click();
      };
    } catch (error) {
      console.log('Ocorreu um erro ao baixar arquivo', error);
    }
  }

  setAllocationsName(allocationName: string) {
    return allocationName !== null ? allocationName : '--';
  }

  checkActivitiesValues(value: string | number | null | undefined) {
    return value === null || value === '' ? '--' : value;
  }

  concatApprovers(approvers: Selects[]) {
    return approvers.map(a => a.name).join(', ');
  }

  handleSeeContract(id: string) {
    this.$router.push({
      name: 'ViewContract',
      params: { id: id, type: 'contract' },
    });
  }

  getPaymentType(paymentTypeId: number) {
    const paymentType = this.paymentMethods.find((item) => item.id === paymentTypeId)?.name;

    return paymentType !== undefined ? paymentType : '--';
  }

  getDocumentType(documentTypeId: number) {
    const documentType = this.documentTypes.find((item) => item.id === documentTypeId)?.name;

    return documentType !== undefined ? documentType : '--';
  }

  getContractName(contractId: string) {
    const contract = this.contractData.find((contract) => contract.id === contractId)?.title;

    return contract !== undefined ? contract : '--';
  }

  getCompanyName(companyId: number) {
    if (companyId === null) return '--';

    const company = this.companiesData.find((company) => company.id === companyId)?.name;

    return company !== undefined ? company : '--';
  }

  companyRequiresTwoApprovers(companyId: number): boolean {
    if (companyId === null || companyId === 0) return false;

    const company = this.companiesData.find((company) => company.id === companyId);          
    return company?.flag ?? false;
  }

  getRequesterCompanyName(requesterEntityId: string) {
    if (requesterEntityId === null) return '--';

    const requesterCompany = this.requesterCompanies.find(
      (company) => company.id === requesterEntityId
    )?.name;

    return requesterCompany !== undefined ? requesterCompany : '--';
  }

  getLesteCompany() {
    const lesteCompany = this.companiesData.find(
      (company) => company.id === this.paymentById.services[0].payingCompany
    )?.name;

    return lesteCompany !== undefined ? lesteCompany : '--';
  }

  setDocumentFileName(document: any[]) {
    // if (!document.length) return '--';
    const name = document.map((item) => item.fileName);
    const extension = document.map((item) => item.fileExtension);
    return `${name}${extension}`;
  }

  setDocumentFileDownload(document: any[]) {
    // if (!document.length) return '--';
    const url = document.map((item) => item.sharepointPath);
    return url;
  }

  addUploadInvoice() {
    this.$emit('addUploadInvoice');
  }

  async setContractFile() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractFile as any).files[0] as File;
    this.$emit('setContractFile', this.files);
  }

  contractDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  contractDragleave() {
    this.isDraggingContract = false;
  }

  dropContractFile(e: any) {
    e.preventDefault();
    (this.$refs.contractFile as any).files = e.dataTransfer.files;
    this.setContractFile();
    this.isDraggingContract = false;
  }

  removeFile(item: RemoveDocumentPayload) {
    // this.paymentById.documents.splice(this.paymentById.documents.indexOf(item), 1);
    this.$emit('removeFile', item.documentId);
  }

  handleApprovePayment() {
    if (
      this.paymentById.statusTitle === 'Finance Review' &&
      this.paymentById.contractId === null &&
      this.isFinance
    ) {
      this.$emit('openModalPaymentFinanceActions');
    } else {
      this.$emit('showModalApprove');
    }
  }

  showModalReprove() {
    this.$emit('showModalReprove');
  }

  showSendNFModal() {
    this.$emit('showSendNFModal');
  }

  showEditPaymentModal() {
    this.$emit('showEditPaymentModal');
  }

  showEditActivityModal(index: number, activity: ServiceResponse) {    
    this.$emit('showEditActivityModal', { index, activity });
  }

  closeModal() {
    this.paymentNFFile = documentPayloadInitialValue;
    this.$emit('closeModalView');
  }

  isStatusForApproval(): boolean {      
    return !(this.paymentById.statusTitle === 'Waiting Payment')
        && !(this.paymentById.statusTitle === 'Canceled')
        && !(this.paymentById.statusTitle === 'Paid')
  }

  disableInvoice(): boolean {
    if (this.paymentById.statusTitle === 'Waiting Invoice')
      return this.paymentNFFile.fileName === ''  
    return false
  }
}
