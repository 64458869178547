
import SkeletonLoader from '@/components/SkeletonLoader.vue';
import ContractDetail from '@/types/contractDetail';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AdditivesTable from './AdditivesTable.vue';



@Component({
  components: { AdditivesTable, SkeletonLoader },
})
export default class AditiveData extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(Boolean) gettingContract!: boolean;

  openNewAditiveModal = false;

  showNewAditiveModal() {
    this.$emit('showNewAditiveModal');
  }

  refreshContract() {
    this.$emit('refreshContract');
  }
}
