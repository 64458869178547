
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalApproveDenied extends Vue {
  @Getter('getModalApproveDeniedState', { namespace: 'modals' })
  public modalApproveDenied!: boolean;
  @Action('setModalApproveDeniedState', { namespace: 'modals' })
  public setModalApproveDeniedState!: any;

  approveDeniedReason = '';

  public closeModal(): void {
    this.setModalApproveDeniedState(false);
  }
}
