
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import CurrencyData, { currencyFormater } from '@/utils/currencyData';

@Component({})
export default class ModalDeleteContract extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];
  @Prop(Boolean) showDeleteModal!: boolean;
  @Prop(Boolean) loading!: boolean;
  @Prop(String) id!: string;
  @Prop(String) text!: string;
  authService = new AuthService();
  private endpoint = new Endpoint();
  typeCurrency = '';
  disableDelete: any = [];
  closeModal() {
    this.$emit('closeModal');
  }
  deleteContract() {
    this.$emit('deleteContract', this.id);
  }
  currencsy: Record<string, any> = {
    EUR: {
      currencyFormater: currencyFormater.euro,
      type: '€',
    },
    BRL: { currencyFormater: currencyFormater.real, type: 'R$' },
    GBP: {
      currencyFormater: currencyFormater.libra,
      type: '£',
    },
    USD: { currencyFormater: currencyFormater.dolar, type: '$' },
  };
  async beforeMount() {
    const token = await this.authService.getIdToken();
    const { data } = await this.$http.get(this.endpoint.contract.base + `/${this.id}`, {
      headers: { Authorization: `${token}` },
    });
    if (data) {
      this.typeCurrency = data.currency;
      if (data.payments.length > 0) {
        this.disableDelete = [
          {
            contractValue: data.contractValue,
            usedContractValue: data.usedContractValue,
            payments: data.payments.length,
            currency: data.currency,
          },
        ];
      } else {
        this.disableDelete = [
          {
            contractValue: '',
            usedContractValue: '',
            payments: 0,
            currency: '',
          },
        ];
      }
    }
  }
}
