import FinancialBudget from '@/types/financialBudget';
import CostCenter, { costCenterInitialValue } from '@/types/costCenter';
import Selects from '@/types/selects';

export default interface activitySelectsData {
  approverData: Selects[];
  companies: Selects[];
  costCenterData: CostCenter[];
  financialBudgetData: FinancialBudget[];
}

const activitySelectsDataInitialValue: activitySelectsData = {
  approverData: [],
  companies: [],
  costCenterData: [],
  financialBudgetData: [],
};

export { activitySelectsDataInitialValue };
