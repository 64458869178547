
interface Files {
  files: [];
}
import moment from 'moment';
import { Money } from 'v-money';
import { Component, Prop, Vue } from 'vue-property-decorator';

import ModalLoading from '@/components/actions/ModalLoading.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';

import NewAdditivePayload from '@/types/newAdditivePayload';
import RemoveDocumentPayload from '@/types/removeDocumentPayload';

@Component({
  components: { FileDisplay, ModalLoading, Money },
})
export default class ModalNewAditive extends Vue {
  @Prop(Boolean) public openNewAditiveModal!: boolean;
  @Prop(Boolean) public loadingAdditive!: boolean;

  aditiveSteps = 1;
  isDraggingAditive = false;
  uploadAditiveInput = 0;
  files: Blob = new Blob();
  isDraggingAdditional = false;
  uploadAdditionalInput = 0;
  additionalFiles: File[] = [];

  newAditive = {
    name: '',
    executionDealine: '',
    scope: '',
    value: '',
  };

  // TODO: Criar uma utils ou  algo do tipo pra salvar todas as possibilidades de currrencies e usar nas pages

  dolar = {
    decimal: '.',
    thousands: ',',
    precision: 2,
  };

  showExecutionDeadline = false;

  closeModal() {
    this.$emit('closeModal');
    this.clearFields();
  }

  clearFields() {
    this.newAditive = {
      name: '',
      executionDealine: '',
      scope: '',
      value: '',
    };
    this.files = new Blob();

    this.aditiveSteps = 1;
  }

  setAditiveFile() {
    this.uploadAditiveInput++;
    this.files = (this.$refs.AditiveFile as any).files[0];
  }

  aditiveDragover(e: any) {
    e.preventDefault();
    this.isDraggingAditive = true;
  }

  aditiveDragleave() {
    this.isDraggingAditive = false;
  }

  dropAditiveFile(e: any) {
    e.preventDefault();
    (this.$refs.AditiveFile as any).files = e.dataTransfer.files;
    this.setAditiveFile();
    this.isDraggingAditive = false;
  }

  setAdditionalFile() {
    this.uploadAdditionalInput++;
    const additionalFiles = (this.$refs.additionalFile as any).files as FileList;
    for (let [key, value] of Object.entries(additionalFiles)) {
      this.additionalFiles.push(value);
    }
  }

  additionalDragover(e: any) {
    e.preventDefault();
    this.isDraggingAditive = true;
  }

  additionalDragleave() {
    this.isDraggingAditive = false;
  }

  dropAdditionalFile(e: any) {
    e.preventDefault();
    (this.$refs.additionalFile as any).files = e.dataTransfer.files;
    this.setAdditionalFile();
    this.isDraggingAditive = false;
  }

  removeFile(payload: RemoveDocumentPayload) {
    if (payload.key === null) {
      this.files = new Blob();
    } else {
      this.additionalFiles.splice(payload.key, 1);
    }
  }

  public handleFormatDate(date: string): string {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }

  addAdditive() {
    const params: NewAdditivePayload = {
      contractId: this.$route.params.id,
      additiveValue: this.newAditive.value,
      description: this.newAditive.scope,
      title: this.newAditive.name,
      deadlineAdditive: this.newAditive.executionDealine,
      additiveFileContract: this.files,
    };

    this.$emit('addAdditive', params);
  }
}
