
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import TruncateFile from '@/utils/truncateFile';
import FormatDate from '@/utils/formatDate';

@Component({})
export default class FileDisplay extends Vue {
  @Prop(String) fileName!: string;
  @Prop(String) lastModifiedDate!: string | number;
  @Prop(String) downloadUrl!: string;
  @Prop(Number) index!: number | null;
  @Prop(String) documentId!: string | null;
  @Prop(Boolean) remove!: boolean;
  @Prop(Boolean) download!: boolean;
  @Prop(Boolean) loadingRemoveDocument!: boolean;
  @Prop(Boolean) aditive!: boolean;
  @Prop(Boolean) downloadingFile!: boolean;

  truncateFile = TruncateFile;
  formatDate = FormatDate;

  fileToDownload = '';

  handleRemoveItem() {
    this.$emit('handleRemoveItem', { key: this.index, documentId: this.documentId });
  }

  handleDownloadFile(fileName: string, downloadUrl: string) {
    this.fileToDownload = fileName;
    this.$emit('handleDownloadFile', { fileUrl: downloadUrl, fileName });
  }
}
