
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { activitySelectsDataInitialValue } from '@/types/ActivitySelectsData';
import Alocation, { alocationInitialValue } from '@/types/alocation';
import ContractsById, { CostCenterAllocation } from '@/types/contractsById';
import CostCenter from '@/types/costCenter';
import CostCenterContract from '@/types/costCenterContract';
import FinancialBudget from '@/types/financialBudget';
import { Services } from '@/types/paymentById';
import Selects, { selectsInitialValue } from '@/types/selects';
import UpdateServiceCommand from '@/types/services/update'
import ServiceResponse from '@/types/services/response';
@Component({})
export default class ModalEditActivity extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Prop(Boolean) readonly editActivityModal!: boolean;
  @Prop(Boolean) readonly showSendField!: boolean;
  @Prop(Boolean) readonly gettingApprovers!: boolean;
  @Prop(Boolean) editingActivity!: boolean;
  @Prop(Boolean) loadingCreateCostCenter!: boolean;
  @Prop(Boolean) isEdit!: boolean;
  @Prop(Boolean) hasError!: boolean;
  @Prop(Number) indexActivityIsEditing!: number | null;
  @Prop(Object) activityIsEditing!: ServiceResponse;
  @Prop(String) readonly feedbackText!: string;
  @Prop(String) readonly btnText!: string;
  @Prop(Number) alocationPercentageValue!: number;
  @Prop(Object) contractsById!: ContractsById;
  @Prop(Array) currentAllocations!: CostCenterAllocation[];
  @Prop(Array) public requesterCompanies!: Selects[];
  @Prop(Object) alocationItems!: Alocation;
  @Prop(String) textError!: string;

  @Prop(Array) public approverData!: Selects[];
  @Prop(Array) companies!: Selects[];
  @Prop(Array) costCenterData!: CostCenter[];
  @Prop(Boolean) gettingCostCenters!: boolean;
  @Prop(Array) financialBudgetData!: FinancialBudget[];

  @Watch('approverData', { immediate: true })
  @Watch('companies', { immediate: true })
  @Watch('costCenterData', { immediate: true })
  @Watch('financialBudgetData', { immediate: true })
  setActivitySelectsData() {    
    this.activitySelectsData = {
      approverData: [...this.approverData],
      companies: [...this.companies],
      costCenterData: [...this.costCenterData],
      financialBudgetData: [...this.financialBudgetData],
    };
  }

  activitySelectsData = activitySelectsDataInitialValue;

  @Watch('editActivity.payingCompanySelected')
  getCostCenterBasedInPayingCompanySelected() {    
    if (this.editActivity?.payingCompanySelected){
      this.$emit('getCostCenterByPayingCompany', this.editActivity.payingCompanySelected.id);
      this.$emit('getApproversByPayingCompany', this.editActivity.payingCompanySelected.id);
    }
  }

  @Watch('approverData')
  handleSetActivityApprover() {    
    this.editActivity.approversSelected = this.activityIsEditing.approvers.map(a => a.id as number ?? 0);    
  }

  @Watch('costCenterData')
  updateActicityCostCenterData() {    
    this.activityCostCenters.forEach((item) => (item.costCenterData = this.costCenterData));    
  }

  @Watch('costCenterData')
  handleSetActivityCostCenter() {
    if (this.activityIsEditing.costCenterAllocations.length > 0) {
      this.setActivityCostCenters();
    }
  }

  @Watch('activityCostCenters', { deep: true, immediate: true })
  handlePercentageIsFull() {    
    const allocationsPercentageTotal = this.activityCostCenters
      .map((item) => item.percentage)
      .reduce((partialSum, a) => partialSum + a, 0);

    allocationsPercentageTotal >= 100
      ? (this.percentageIsFull = true)
      : (this.percentageIsFull = false);
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  activityCostCenters: CostCenterContract[] = [];
  restPercentage = 100 - this.alocationPercentageValue;
  totalValuePercentage = 0;
  alocationPercentageTotalValue = this.alocationPercentageValue;
  percentageIsFull = false;
  showPaymentDate = false;

  allocationWhoisChanging = {} as CostCenterContract;
  oldAllocation = {} as CostCenterContract;
  allocationNewValue = {} as CostCenterContract;
  allocationOldValue = {} as CostCenterContract;
  allocationWhoisChangingFlag = '';

  alocationDados: Alocation = alocationInitialValue;

  financialBudgetsChangeds: number[] = [];
  costCenterChangeds: number[] = [];

  financialSelecteds: FinancialBudget[] = [];
  costCentersSelecteds: CostCenter[] = [];

  editActivity = {
    approversSelected: [] as number[],    
    payingCompanySelected: selectsInitialValue,
    paymentDate: '',
    approvalModeId: 3
  };

  get allocationTitle() {
    if (this.isEdit) return 'Edit Allocation';
    return 'New Allocation';
  }

  created() {
    this.activityCostCenters = [];
    this.setActivity(this.activityIsEditing);
  }

  setActivity(activity: ServiceResponse) {  
    this.editActivity = {
      approversSelected: activity.approvers.map(a => a.id as number ?? 0),
      payingCompanySelected: this.companies.find(
        (item) => item.id === activity.payingCompany
      ) as Selects ?? selectsInitialValue,
      paymentDate: activity.paymentDate ?? '',
      approvalModeId: activity.approvalModeId
    };
    if (activity.costCenterAllocations.length === 0) {
      this.addNewActivitiesCostCenter();
    }
  }

  setActivityCostCenters() {
    this.activityIsEditing.costCenterAllocations.map((item) => {
      const activityCostCenter = {
        costCenterSelected: this.costCenterData.find(
          (costCenter) => costCenter.id === item.costCenterId
        ) as CostCenter,
        financialBudgeSelected: this.financialBudgetData.find(
          (financial) => financial.id === item.financialBudgetId
        ) as FinancialBudget,
        percentage: item.percentage,
        costCenterData: this.costCenterData,
        financialBudgetData: this.financialBudgetData,
      };

      this.activityCostCenters.push(activityCostCenter);
    });

    this.activityCostCenters.map((activity) => {
      this.financialBudgetsChangeds.push(activity.financialBudgeSelected.id);
      this.costCenterChangeds.push(activity.costCenterSelected.id);
    });

    this.activityCostCenters.forEach((item) =>
      this.disableFieldsIfCombinationAlredyExists(item, 'Cost Center')
    );
    this.activityCostCenters.forEach((item) =>
      this.disableFieldsIfCombinationAlredyExists(item, 'Financial Budget')
    );
  }

  disableFieldsIfCombinationAlredyExists(allocationData: CostCenterContract, flag: string) {
    this.allocationWhoisChanging = allocationData;
    this.allocationWhoisChangingFlag = flag;

    if (flag === 'Cost Center') this.blockFinancialBudgetsByCombination(allocationData);
    else this.blockCostCentersByCombination(allocationData);
  }

  get allocationWhoisChangingParsed() {
    return JSON.stringify(this.allocationWhoisChanging);
  }

  @Watch('allocationWhoisChangingParsed', { deep: true })
  testeeeAloc(newValue: string, oldValue: string) {    
    const allocationNewValue = JSON.parse(newValue) as CostCenterContract;
    const allocationOldValue = JSON.parse(oldValue) as CostCenterContract;

    this.oldAllocation = allocationOldValue;

    if (
      this.allocationWhoisChangingFlag === 'Financial Budget' &&
      allocationOldValue.financialBudgeSelected !== undefined &&
      allocationNewValue.financialBudgeSelected.id !== allocationOldValue.financialBudgeSelected.id
    ) {
      if (allocationNewValue.financialBudgeSelected.id !== 0) {
        if (this.financialBudgetsChangeds.includes(allocationOldValue.financialBudgeSelected.id)) {
          const newAllocationIndex = this.activityCostCenters.findIndex(
            (item) =>
              item.financialBudgeSelected.id === allocationNewValue.financialBudgeSelected.id
          );

          this.financialBudgetsChangeds[newAllocationIndex] =
            allocationNewValue.financialBudgeSelected.id;
        } else {
          this.financialBudgetsChangeds.push(allocationNewValue.financialBudgeSelected.id);
        }
      }
    }

    if (this.allocationWhoisChangingFlag !== 'Cost Center') return;
    if (allocationOldValue.costCenterSelected === undefined) return;
    if (allocationNewValue.costCenterSelected.id === allocationOldValue.costCenterSelected.id)
      return;

    if (allocationNewValue.costCenterSelected.id !== 0) {
      if (this.costCenterChangeds.includes(allocationOldValue.costCenterSelected.id)) {
        const newAllocationIndex = this.activityCostCenters.findIndex(
          (item) => item.costCenterSelected.id === allocationNewValue.costCenterSelected.id
        );

        this.costCenterChangeds[newAllocationIndex] = allocationNewValue.costCenterSelected.id;
      } else {
        this.costCenterChangeds.push(allocationNewValue.costCenterSelected.id);
      }
    }
  }

  // neste caso estou mudando o COST CENTER
  blockFinancialBudgetsByCombination(newAllocationData: CostCenterContract) {    
    newAllocationData.financialBudgetData = newAllocationData.financialBudgetData.map(
      (financialBudgetData) => ({
        ...financialBudgetData,
        disabled: !this.canAssignFinancialPlanByIndex(
          financialBudgetData,
          [...this.activityCostCenters],
          this.activityCostCenters.indexOf(newAllocationData)
        ),
      })
    );

    if (newAllocationData.financialBudgeSelected.name == '') return;

    this.activityCostCenters.map((activity) => {
      if (activity.financialBudgeSelected.id === newAllocationData.financialBudgeSelected.id) {
        activity.costCenterData.find(
          (item) => item.id === newAllocationData.costCenterSelected.id
        )!.disabled = true;

        activity.costCenterData = newAllocationData.costCenterData;
      }
    });
  }

  blockCostCentersByCombination(newAllocationData: CostCenterContract) {    
    newAllocationData.costCenterData = newAllocationData.costCenterData.map((costCenterData) => ({
      ...costCenterData,
      disabled: !this.canAssignCostCenterByIndex(
        costCenterData,
        [...this.activityCostCenters],
        this.activityCostCenters.indexOf(newAllocationData)
      ),
    }));

    this.activityCostCenters.map((activity) => {
      if (activity.costCenterSelected.id === newAllocationData.costCenterSelected.id) {
        activity.financialBudgetData.find(
          (item) => item.id === newAllocationData.financialBudgeSelected.id
        )!.disabled = true;

        activity.financialBudgetData = newAllocationData.financialBudgetData;
      }
    });
  }

  canAssignFinancialPlanByIndex(
    financialPlan: FinancialBudget,
    activityCostCenters: CostCenterContract[],
    index: number
  ): boolean {    
    const currentActivityCostCenter = activityCostCenters[index];
    if (activityCostCenters.length === 1) return true;

    return activityCostCenters.every(
      (activityCostCenter) =>
        activityCostCenter.costCenterSelected.name !==
          currentActivityCostCenter.costCenterSelected.name ||
        activityCostCenter.financialBudgeSelected.name !== financialPlan.name
    );
  }

  canAssignCostCenterByIndex(
    costCenter: CostCenter,
    activityCostCenters: CostCenterContract[],
    index: number
  ): boolean {    
    const currentActivityCostCenter = activityCostCenters[index];

    if (activityCostCenters.length === 1) return true;

    return activityCostCenters.every(
      (activityCostCenter) =>
        activityCostCenter.financialBudgeSelected.name !==
          currentActivityCostCenter.financialBudgeSelected.name ||
        activityCostCenter.costCenterSelected.name !== costCenter.name
    );
  }

  public addNewActivitiesCostCenter() {
    const newAlocation = new CostCenterContract(this.costCenterData, this.financialBudgetData);
    newAlocation.percentage = this.getPercentageValue();
    this.activityCostCenters.push(newAlocation);
  }

  limitOnlyNumber(evt: any) {    
    const dot = 46;
    const comma = 44;
    const firstDigit = 48;
    const lastDigit = 57;

    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < firstDigit || charCode > lastDigit) && charCode !== dot) {      
      evt.preventDefault();
    } else {      
      return true;
    }
  }

  public handleFormatDate(date: string | null): string {    
    if (date === null)
      return '';
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];    
    return date ? dateResult : '';
  }


  public getPercentageValue() {    
    const totalValue = this.restPercentage;

    let totalPercentage = 0;
    this.activityCostCenters.forEach((item) => (totalPercentage += item.percentage));
    if (totalValue - totalPercentage <= 0) {
      return 0;
    }    
    return totalValue - totalPercentage;
  }

  removeAllocation(index: number) {    
    this.activityCostCenters.splice(index, 1);
    this.activityCostCenters.map((alocation) =>
      alocation.financialBudgetData.find((item) => {
        if (item.id === this.financialBudgetsChangeds[index]) {
          item.disabled = false;
        }
      })
    );
    this.activityCostCenters.map((alocation) =>
      alocation.costCenterData.find((item) => {
        if (item.id === this.costCenterChangeds[index]) {
          item.disabled = false;
        }
      })
    );
    this.financialBudgetsChangeds.splice(index, 1);
    this.costCenterChangeds.splice(index, 1);    
  }

  closeModal() {    
    this.$emit('closeModal');
    this.editActivity = {
      approversSelected: [],      
      payingCompanySelected: selectsInitialValue,
      paymentDate: '',
      approvalModeId: 3
    };
    this.activityCostCenters = [];
    this.costCenterChangeds = [];
    this.financialBudgetsChangeds = [];
    this.activitySelectsData = activitySelectsDataInitialValue;
  }

  async submitEditedActivity() {
    let alocations: any = [];
    try {
      alocations = this.activityCostCenters.map((item) => ({
        costCenterId: item.costCenterSelected.id,
        financialBudgetId: item.financialBudgeSelected.id,
        percentage: item.percentage,
      }));
    }
    catch {
      alocations = [];
    }
    const params: UpdateServiceCommand = {
      id: this.activityIsEditing.id,
      requesterEntityId: this.activityIsEditing.requesterEntityId ?? '',
      buRequesterId: this.activityIsEditing.buRequester ?? '',
      payingCompany: this.editActivity.payingCompanySelected?.id as number ?? 0,
      approversId: this.editActivity.approversSelected,      
      paymentDate: this.editActivity.paymentDate,
      grossTotal: this.activityIsEditing.total ?? 0,
      costCenterAllocations: alocations,
      approvalModeId: this.activityIsEditing.approvalModeId,
    };
    this.$emit('submitEditedActivity', params);
    setTimeout(() => {
      this.$forceUpdate();
    }, 10000);
  }
}
