
import DocumentData from '@/components/contracts/DocumentData.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';
import EditingModal from '@/components/Modals/EditingModal.vue';
import ModalApprove from '@/components/Modals/ModalApprove.vue';
import ModalCancel from '@/components/Modals/ModalCancel.vue';
import ModalNewAlocation from '@/components/Modals/ModalNewAlocation.vue';
import ModalSend from '@/components/Modals/ModalSend.vue';
import { AuthService } from '@/services/authService';
import AdditiveById from '@/types/additiveById';
import Alocation from '@/types/alocation';

import CostCenter from '@/types/costCenter';
import FinancialBudget from '@/types/financialBudget';

import RemoveDocumentPayload from '@/types/removeDocumentPayload';
import Selects from '@/types/selects';
import Endpoint from '@/utils/endpoint';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import setStatusClass from '@/utils/setStatus';
import qs from 'qs';
import VueSkeletonLoader from 'skeleton-loader-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CONTRACT_STATUS } from '@/types/general/contractStatus';

@Component({
  components: {
    DocumentData,
    FileDisplay,
    ModalApprove,
    EditingModal,
    ModalNewAlocation,
    ModalSend,
    ModalCancel,
    VueSkeletonLoader,
  },
})
export default class ViewModal extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Prop(Object) photos!: {[key: string] : string};
  @Prop(Boolean) showViewModal!: boolean;
  @Prop(Boolean) showSkeleton!: boolean;
  @Prop(Boolean) loadingSignedContract!: boolean;
  @Prop(Boolean) loadingRemoveDocument!: boolean;
  @Prop(Boolean) removingAllocation!: boolean;
  @Prop(Object) additiveById!: AdditiveById;
  @Prop(Array) jurisdictions!: Selects[];
  @Prop(Array) costCenterData!: CostCenter[];
  @Prop(Array) financialBudgetData!: FinancialBudget[];

  @Watch('additiveById.status', { deep: true})
  handleSetTab(){
    if (!this.showViewModal)
      return;
    switch (this.additiveById.status) {
      case CONTRACT_STATUS.DIGITAL_SIGNATURE:
        this.tab = 3;
        break;
      default:
        this.tab = 0;
        break;
    }
  }

  @Watch('additiveById.documents', { deep: true })
  handleAdditivesDocument() {
    this.alreadyExistsSignedAdditive = this.additiveById.documents.some(
      (item) => item.type === 'Signed Contract'
    );

    this.alreadyExistsAdditionalDocumentAdditive = this.additiveById.documents.some(
      (item) => item.type === 'Additional'
    );
  }
  formatDate = FormatDate;
  formatMoney = FormatMoney;
  setStatusClass = setStatusClass;
  authService = new AuthService();
  private endpoint = new Endpoint();

  tab = 0;
  addAlocation = false;
  addContractSign = false;
  showModalCancel = false;
  showModalSend = false;
  showSendField = false;
  showModalEdit = false;
  showNewAlocationModal = false;
  addSignedContracts = false;
  isDraggingContract = false;
  alreadExistSignedContract = false;
  alreadExistDocumentAdditional = false;
  alreadyExistsSignedAdditive = false;
  alreadyExistsAdditionalDocumentAdditive = false;
  feedbackText = '';
  btnText = '';
  uploadContractInput = 0;
  indexAllocationWasRemoving = 0;

  files: Blob = new Blob();

  get contractStatus() {
    return this.additiveById.status;
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  get isManagement() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Management');
  }

  get isLegal() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Legal');
  }

  get isLineManager() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.LineManager');
  }

  get isApproverMaster() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.ApproverMaster');
  }

  get isApprover() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Approver');
  }

  get isCollaborator() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Approver');
  }

  get showAllocationTab() {
    if (this.isVendor) return false;
    if (!this.isManagement) return false;
    if (this.contractStatus === 'Canceled') return false;
    return true;
  }

  get showFeedbackTab() {
    const feedbacks = this.additiveById.feedBacks;
    if (feedbacks.length === 0) return false;
    return true;
  }

  get setDocumentWidth() {
    const permissions = this.additiveById.permission;

    if (this.alreadExistSignedContract) return 'contract-file';
    if (!permissions.userCanInsertSignedContract) return 'contract-file';
    return 'file-name';
  }

  get approverName() {
    return this.additiveById.contract.approverName ?? '--';
  }

  get secondApproverName() {
    return this.additiveById.contract.secondApproverName ?? '--';
  }

  get costCenterAllocations(){
    return this.additiveById.contract.costCenterAllocations;
  }

  getCostCenterName(costCenter: number) {
    const costCenterName = this.costCenterData.find((item) => item.id === costCenter)?.name;
    return costCenterName !== undefined ? costCenterName : '--';
  }

  getFinancialBudgetName(financial: number) {
    const financialBudgetName = this.financialBudgetData.find(
      (item) => item.id === financial
    )?.name;

    return financialBudgetName !== undefined ? financialBudgetName : '--';
  }

  async handleDownloadFile(fileData: any) {
    try {
      const token = await this.authService.getIdToken();
      const response = await this.$http.get(this.endpoint.files.download, {
        params: {
          fileUrl: fileData.fileUrl,
        },
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          'content-type': 'blob',
          Authorization: `${token}`,
        },
        responseType: 'blob',
      });

      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        const result: string | ArrayBuffer = reader.result ? reader.result : '';
        const downloadLink = document.createElement('a');
        downloadLink.href = result.toString();
        downloadLink.download = fileData.fileName;
        downloadLink.click();
      };
    } catch (error) {
      console.log('Ocorreu um erro ao baixar arquivo', error);
    }
  }

  approveAditive() {
    this.$emit('approveAditive', this.additiveById.id);
  }

  reproveAditive() {
    this.$emit('reproveAditive', this.additiveById.id);
  }

  removeAlocation(item: Alocation, index: number) {
    this.indexAllocationWasRemoving = index;
    this.$emit('removeAlocation', item);
  }

  setMainJurisdiction(jurisdictionId: string) {
    if (jurisdictionId === null) return '--';

    const mainJurisdiction = this.jurisdictions.find((item) => item.id === jurisdictionId)?.name;

    return mainJurisdiction === undefined ? '--' : mainJurisdiction;
  }

  setSecondaryJurisdiction(jurisdictions: Selects[]) {
    if (!jurisdictions.length) return '--';

    return jurisdictions.map((item) => item.name).join(', ');
  }

  setActivityTypes(activity: Selects[]) {
    if (!activity.length) return '--';

    return activity.map((item) => item.name).join(', ');
  }

  formatCNPJ(identifier: string) {
    const cnpj = identifier.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return cnpj;
  }

  async setContractSignedFile() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractAdditiveFile as any).files[0] as File;
    this.$emit('setContractSignedFile', this.files);
  }

  async setContractFileAdditive() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractAdditiveFile as any).files[0] as File;
    this.$emit('setContractFileAdditive', this.files);
  }

  contractDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  contractDragleave() {
    this.isDraggingContract = false;
  }

  dropContractFileAdditive(e: any) {
    e.preventDefault();
    (this.$refs.contractAdditiveFile as any).files = e.dataTransfer.files;
    this.setContractFileAdditive();
    this.isDraggingContract = false;
  }

  removeFile(item: RemoveDocumentPayload) {
    const data = {
      id: this.additiveById.id,
      documentId: item.documentId,
      isAdditive: true
    }

    this.$emit('removeFile', data);
  }

  additiveNeedsSigning(): boolean {
    return this.additiveById.status === 'Digital Signature'
        && !this.additiveById.documents.some((document) => document.type === 'Signed Contract');
  }

  showCancelModal() {
    this.$emit('showCancelModal');
  }

  showAproveModal() {
    this.$emit('showAproveModal');
  }

  showContractSignedModal() {
    this.$emit('showContractSignedModal');
  }

  showModalReprove() {
    this.$emit('showModalReprove');
  }

  showSendModal() {
    this.showModalSend = true;
    this.showViewModal = false;
  }

  showEditModal() {
    this.closeModal(); // fechar o VIEWMODAL
    this.$emit('openEditAdditiveModal'); // emitir a abertura do modal de ação
    // this.showViewModal = false;
  }

  closeModalSend() {
    this.showModalSend = false;
    this.showViewModal = true;
  }

  closeModalEdit() {
    this.showModalEdit = false;
    this.showViewModal = true;
  }

  closeModal() {
    this.$emit('closeModalView');
  }

  isStatusForEditing() {
    return this.additiveById.status === 'Owner Review' || this.additiveById.status === 'Finance Review';
  }

  hasApprover(): boolean {
    return this.additiveById.contract.approverId !== null;
  }

  hasSecondApprover(): boolean {
    return this.additiveById.contract.secondApproverId !== null && this.additiveById.contract.secondApproverId !== 0;
  }

  hasAllocations(): boolean {
    return this.additiveById.contract.costCenterAllocations.length > 0;
  }
}
