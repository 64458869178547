import { feedbackInitialValue } from '@/types/general/feedback';

export default {
  user: null,

  modalApprove: false,
  modalDeny: false,
  modalApproveDenied: false,
  modalPendency: false,
  modalPendencyComment: false,

  onboardingId: '',

  feedback: feedbackInitialValue,
  pendencyComment: '',

  canList: false,
  canApprove: false,
  canCreate: false,
  canUpdate: false,
  canApproveDenied: false,

  modalBackDocuments: false,
  disclaimerForm: false,

  isCollaborator: false,
  isApprover: false,
  isApproverMaster: false,
  isVendor: false,
};
