import { ActionContext } from 'vuex';
import { ActionsState } from './types';

import User from '@/types/auth/user';
import Area from '@/types/auth/areas';
import CurrentRoute from '@/types/auth/currentRoute';

import Feedback from '@/types/general/feedback';
import Profiles from '@/types/profiles';

export default {
  setUser(store: ActionContext<ActionsState, any>, user: User): void {
    store.commit('setUser', user);
  },

  setAreas(store: ActionContext<ActionsState, any>, areas: Area[]): void {
    store.commit('setAreas', areas);
  },

  setProfiles(store: ActionContext<ActionsState, any>, profiles: Profiles[]) {
    store.commit('setProfiles', profiles);
  },

  canList(store: ActionContext<ActionsState, any>, areas: Area[]): void {
    store.commit('canList', areas);
  },
  canApprove(store: ActionContext<ActionsState, any>, areas: Area[]): void {
    store.commit('canApprove', areas);
  },
  canCreate(store: ActionContext<ActionsState, any>, areas: Area[]): void {
    store.commit('canCreate', areas);
  },
  canUpdate(store: ActionContext<ActionsState, any>, areas: Area[]): void {
    store.commit('canUpdate', areas);
  },
  canApproveDenied(store: ActionContext<ActionsState, any>, areas: Area[]): void {
    store.commit('canApproveDenied', areas);
  },

  setIsCollaborator(store: ActionContext<ActionsState, any>, flag: boolean): void {
    store.commit('setIsCollaborator', flag);
  },
  setIsApprover(store: ActionContext<ActionsState, any>, flag: boolean): void {
    store.commit('setIsApprover', flag);
  },
  setIsApproverMaster(store: ActionContext<ActionsState, any>, flag: boolean): void {
    store.commit('setIsApproverMaster', flag);
  },
  setIsVendor(store: ActionContext<ActionsState, any>, flag: boolean): void {
    store.commit('setIsVendor', flag);
  },

  setCurrentRoute(store: ActionContext<ActionsState, any>, currentRoute: CurrentRoute): void {
    store.commit('setCurrentRoute', currentRoute);
  },

  disconnectToken(store: ActionContext<ActionsState, any>): void {
    store.commit('setUser', null);
  },

  setModalApproveState(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setModalApproveState', show);
  },
  setModalDenyState(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setModalDenyState', show);
  },
  setModalApproveDeniedState(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setModalApproveDeniedState', show);
  },
  setModalPendencyState(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setModalPendencyState', show);
  },
  setModalPendencyCommentState(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setModalPendencyCommentState', show);
  },

  setModalBackDocuments(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setModalBackDocuments', show);
  },
  setDisclaimerForm(store: ActionContext<ActionsState, any>, show: boolean): void {
    store.commit('setDisclaimerForm', show);
  },

  setOnboardingId(store: ActionContext<ActionsState, any>, id: string): void {
    store.commit('setOnboardingId', id);
  },

  setFeedback(store: ActionContext<ActionsState, any>, feedback: Feedback) {
    store.commit('setFeedback', feedback);
  },
  setPendencyComment(store: ActionContext<ActionsState, any>, comment: Feedback) {
    store.commit('setPendencyComment', comment);
  },
};
