import Selects from '@/types/selects';

export default class Rules {
  public required: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0) return 'Required field';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return 'Required Field';
    },
  ];

  public requiredString: Array<any> = [
    (v: any) => !!v || 'Required field',
    (v: Selects) => v.id !== '' || 'Required field',
  ];

  public requiredValue: Array<any> = [(v: any) => v !== 0 || 'Value must be more than zero'];

  public email: Array<any> = [
    (v: any) => !!v || 'Required field',
    (v: string) => /\S+@\S+\.\S+/.test(v) || 'Invalid email format',
  ];

  public pendencyReason: Array<any> = [
    (v: any) => !!v || 'Required field',
    (v: any) => (v && v.length <= 100) || 'Pendency reason must be less than 100 characters',
  ];

  public approveReason: Array<any> = [
    (v: any) => !!v || 'Required field',
    (v: any) => (v && v.length <= 100) || 'Approve reason must be less than 100 characters',
  ];

  public denyReason: Array<any> = [
    (v: any) => !!v || 'Required field',
    (v: any) => (v && v.length <= 100) || 'Deny reason must be less than 100 characters',
  ];

  public requiredFile: Array<any> = [
    (v: any) => v !== null || 'File is required',
    (v: any) => (v && v.size > 0) || 'File is required',
  ];

  public notes: Array<any> = [
    (v: any) => (v && v.length <= 500) || 'Note must be less than 500 characters',
  ];

  public surveyRequired: Array<any> = [(v: any) => v !== null || 'Required field'];

  public disclaimerTerms: Array<any> = [(v: any) => !!v || 'Required field'];
}
