
interface editActivityPayload {
  index: number;
  activity: ServiceResponse;
}
import qs from 'qs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import Contract from '@/types/contracts';
import CostCenter from '@/types/costCenter';
import EditPaymentPayload from '@/types/editPaymentPayload';
import Filter from '@/types/filter';
import FilterPayload, { filterPayloadInitialValue } from '@/types/filtersPayload';
import FinancialBudget from '@/types/financialBudget';
import NewPaymentPayload from '@/types/newPaymenPayload';
import PaymentsById, { paymentByIdInitialValue, Services } from '@/types/paymentById';
import Payments from '@/types/payments';
import Selects, { FintiCompany } from '@/types/selects';
import VerifyContracyBalancePayload from '@/types/VerifyContracyBalancePayload';

import FilterPayments from '@/components/filter/FilterIndex.vue';
import ModalApprovePayment from '@/components/Modals/ModalApprovePayment.vue';
import ModalBundlePayments from '@/components/payments/ModalBundlePayments.vue';
import ModalEditActivity from '@/components/payments/ModalEditActivity.vue';
import ModalEditPayment from '@/components/payments/ModalEditPayment.vue';
import ModalNewPayment from '@/components/payments/ModalNewPayment.vue';
import ModalPaymentFinanceActions from '@/components/payments/ModalPaymentFinanceActions.vue';
import ModalSelectPaymentForm from '@/components/payments/ModalSelectPaymentForm.vue';
import ModalSendNFPayment from '@/components/payments/ModalSendPayment.vue';
import ViewModal from '@/components/payments/ViewModalPayment.vue';

import { DefaultPhoto, getUserPhotoFromShieldByEmail } from '@/components/common/shieldService';
import { AuthService } from '@/services/authService';
import User from '@/types/auth/user';
import { PaymentStatusTypes } from '@/types/general/paymentStatus';
import PaymentFlow from '@/types/PaymentFlow';
import Endpoint from '@/utils/endpoint';
import { setErrorText } from '@/utils/errorHandler';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import setStatusClass, { setPaymentModalTabByStatus } from '@/utils/setStatus';
import AsyncComputed from 'vue-async-computed-decorator';
import ServiceResponse from '@/types/services/response';

@Component({
  components: {
    FilterPayments: FilterPayments,
    ViewModal: ViewModal,
    ModalSelectPaymentForm,
    ModalNewPayment,
    ModalApprovePayment,
    ModalSendNFPayment,
    ModalEditActivity,
    ModalEditPayment,
    ModalBundlePayments,
    ModalPaymentFinanceActions,
  },
})
export default class ListPayment extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;

  @Getter('getUser', { namespace: 'modals' })
  user!: User;

  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Watch('paymentType')
  async handleGetPaymentsByType() {
    this.controller.abort();
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    // this.restartRequest = true;
    await this.getPayments(this.filtersPayments)

  }
 t =process.env.VUE_APP_NAME_DEPLOY
  @Watch('showViewModalPayment')
  async handleGetPaymentById() {
    if (this.showViewModalPayment === true) {
      await this.getPaymentsById(this.paymentId);
    }
  }

  async getPaymentsFiltered() {
    await this.getPayments(this.filtersPayments);
  }

  paymentType = null;
  filterItems = false;
  showViewModalPayment = false;
  showApprovePaymentModal = false;
  modalReprove = false;
  approvingPayment = false;
  bundlingPayment = false;
  showSendModal = false;
  editPaymentModal = false;
  editActivityModal = false;
  newBundlePaymentsModal = false;
  modalPaymentFinanceActions = false;

  openSelectPaymentModal = false;
  newPaymentModal = false;
  paymentTypeSelect: number | null = null;

  gettingPayments = false;
  gettingContract = false;
  creatingContract = false;
  showSkeleton = false;
  activeModalTab = 0;
  userHasBalance: boolean | null = null;
  wasFiltering = false;
  loadingSendNF = false;
  removingInvoicePayment = false;
  gettingCostCenters = false;
  editingPayment = false;
  gettingApprovers = false;
  editingActivity = false;

  uploadNewContractModal = 0;
  indexActivityIsEditing: number | null = null;
  activityIsEditing = {} as ServiceResponse;

  paymentId = '';
  loadingMessage = '';
  textError = '';
  hasError = false;


  inProgressPayments: Payments[] = [];
  finishedPayments: Payments[] = [];
  paymentById: PaymentsById = paymentByIdInitialValue;

  filterCheckboxs: Filter[] = [];

  filtersPayments: FilterPayload = filterPayloadInitialValue;

  financialBudgetData: FinancialBudget[] = [];
  costCenterData: CostCenter[] = [];
  requesterCompanies: Selects[] = [];

  private endpoint = new Endpoint();
  authService = new AuthService();
  controller = new AbortController();
  signal = this.controller.signal;
  // restartRequest = false;
  formatMoney = FormatMoney;
  formatDate = FormatDate;
  setStatusClass = setStatusClass;

  headers = [
    {
      text: 'Payment title',
      align: 'start',
      sortable: true,
      value: 'title',
    },
    {
      text: 'Contract name',
      align: 'start',
      sortable: true,
      value: 'contractTitle',
    },
    {
      text: 'Vendor',
      align: 'start',
      sortable: true,
      value: 'vendorEmail',
    },
    {
      text: 'Leste Company',
      align: 'start',
      sortable: true,
      value: 'requesterCompanyId',
    },
    {
      text: 'Accountable Manager',
      align: 'start',
      sortable: true,
      value: 'responsibleManagers',
    },
    {
      text: 'Value',
      align: 'start',
      sortable: true,
      value: 'totalAmount',
    },
    {
      text: 'Deadline',
      align: 'start',
      sortable: true,
      value: 'expirationDate',
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'statusTitle',
    },
  ];

  contracts: Contract[] = [];
  companies: Selects[] = [];
  withoutFundCompanies: Selects[] = [];
  vendors: Selects[] = [];
  managers: Selects[] = [];
  paymentMethods: Selects[] = [];
  documentTypes: Selects[] = [];
  approverData: Selects[] = [];
  fintiCompanies: FintiCompany[] = [];
  gettingCompanies = false;
  photos: { [key: string]: string } = {};
  canCreate = false;
  @AsyncComputed()
  async getCanCreate() {
    this.canCreate = await this.verifyUserPermissions({
      resource: 'Payment',
      action: 'Create',
    });
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    const result = this.userRoles.some(r => r.toLowerCase().includes('finance'));
    return result;
    //return this.userRoles.includes('Vendor.Finance');
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isManagement() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Management');
  }

  get alocationPercentageValue() {
    let percentageValue = 0;

    if (this.indexActivityIsEditing !== null) {
      this.paymentById.services[this.indexActivityIsEditing].costCenterAllocations.forEach(
        (item) => (percentageValue = percentageValue + item.percentage)
      );
    }

    return percentageValue;
  }
  get payments() {
    if (this.paymentType === 0) return this.inProgressPayments;
    return this.finishedPayments;
  }


  statusName(item: string) {
    const deployName = process.env.VUE_APP_NAME_DEPLOY;
    if (deployName === 'LORE' && item === 'Legal Review') {
      return 'Opportunity'
    } else {
      return item
    }
  }
  handleRowClick(item: Payments) {
    if (item.contractId !== null && !this.isReviewStatus(item.statusTitle)) {
      this.$router.push({ name: 'ViewContract', params: { id: item.contractId! } })
      return;
    }
    this.showModalView(item);
  }

  openNewPaymentModal(paymnetType: number) {
    this.paymentTypeSelect = paymnetType;
    this.newPaymentModal = true;
  }

  closeNewPaymentModal() {
    this.creatingContract = false;
    this.newPaymentModal = false;
  }

  closeModalSelectPaymentForm() {
    this.openSelectPaymentModal = false;
  }

  showModalView(item: Payments) {
    this.paymentId = item.id;
    this.showViewModalPayment = true;
  }

  showModalApprove() {
    this.modalReprove = false;
    this.showApprovePaymentModal = true;
    this.showViewModalPayment = false;
  }

  showModalReprove() {
    this.modalReprove = true;
    this.showApprovePaymentModal = true;
    this.showViewModalPayment = false;
  }

  showEditPaymentModal() {
    this.showViewModalPayment = false;
    this.editPaymentModal = true;
  }

  closeEditPaymentModal() {
    this.editPaymentModal = false;
  }

  showEditActivityModal(payload: editActivityPayload) {
    this.indexActivityIsEditing = payload.index;
    this.activityIsEditing = payload.activity;
    this.editingActivity = false;
    this.editActivityModal = true;
    this.showViewModalPayment = false;
  }

  closeEditActivityModal() {
    this.editActivityModal = false;
    this.showViewModalPayment = true;
  }

  showSendNFModal() {
    this.showSendModal = true;
    this.showViewModalPayment = false;
  }

  closeModalApprovePayment() {
    this.showApprovePaymentModal = false;
  }

  closeViewModal() {
    this.showViewModalPayment = false;
  }

  openBundlePaymentsModal() {
    this.newBundlePaymentsModal = true;
  }

  closeNewBundlePaymentsModal() {
    this.newBundlePaymentsModal = false;
  }

  openModalPaymentFinanceActions() {
    this.modalPaymentFinanceActions = true;
    this.showViewModalPayment = false;
  }

  closeModalPaymentFinanceActions() {
    this.modalPaymentFinanceActions = false;
  }

  showBundlePaymentsModal() {
    this.newBundlePaymentsModal = true;
    this.modalPaymentFinanceActions = false;
  }

  closeFilter() {
    this.filterItems = false;
  }

  setWasFiltering(state: boolean) {
    this.wasFiltering = state;
  }

  resetUserHasBalance() {
    this.userHasBalance = null;
  }

  closeSendModal() {
    this.showSendModal = false;
  }

  goToDetails() {
    this.$router.push({ name: 'ViewContract' });
  }

  getCompanyByCompanyId(companyIds: number[]) {
    if (companyIds.length === 1) {
      const companyName = companyIds.map(
        (id) => this.withoutFundCompanies.find((company) => company.id === id)?.name
      );

      return companyName !== undefined ? companyName[0] : '--';
    }

    if (companyIds.length > 1) {
      const companiesFounded = companyIds.flatMap((id) =>
        this.withoutFundCompanies.filter((company) => company.id === id)
      );

      return companiesFounded.length > 0 ? `${companiesFounded.length} empresas` : '--';
    }

    return '--';
  }

  async getNonFundCompanies(): Promise<void> {
    try {
      const response = await this.$http.get<Selects[]>(this.endpoint.company.base + '/without-fundcompanies', {
        headers: {
          "lesteKey": this.endpoint.auth.fintiServicesKey!,
          "API-Key": `${this.endpoint.auth.fintiKey}`,
        },
      });
      this.withoutFundCompanies = response;
    }
    catch {
      this.withoutFundCompanies = [];
    }
  }

  async getPayments(paymentPayload: FilterPayload) {
    this.setPaymentsFilterValues(paymentPayload);
    this.gettingPayments = true;
    const token = await this.authService.getIdToken();
    try {
      const params = {
        InProcess: this.paymentType === 0 ? true : false,
        ...(this.validateStatus(paymentPayload.status) && { ListStatus: paymentPayload.status }),
        ...(this.validateDeadline(paymentPayload.startDeadlineDate) && {
          StartDeadlineDate: paymentPayload.startDeadlineDate,
        }),
        ...(this.validateDeadline(paymentPayload.endDeadlineDate) && {
          EndDeadlineDate: paymentPayload.endDeadlineDate,
        }),
        ...(this.validateContractValue(paymentPayload.startValue) && {
          StartValue: paymentPayload.startValue,
        }),
        ...(this.validateContractValue(paymentPayload.endValue) && {
          EndValue: paymentPayload.endValue,
        }),
        ...(this.validateListCompaniesAndManagers(paymentPayload.listCompanies) && {
          SelectCompanies: paymentPayload.listCompanies,
        }),
        ...(this.validateListCompaniesAndManagers(paymentPayload.managersSelected) && {
          SelectManagers: paymentPayload.managersSelected,
        }),
        ...(this.validateListCompaniesAndManagers(paymentPayload.listVendors) && {
          SelectVendors: paymentPayload.listVendors,
        }),
      };
      const { data } = await this.$http.get(this.endpoint.payment.base, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
        headers: { Authorization: `${token}`, contentType: 'application/json', dataType: 'json' },
        signal: this.signal,
      });
      if (this.paymentType === 0) {
        this.inProgressPayments = data;
      } else {
        this.finishedPayments = data;
      }
    }
    catch (error) {
      // console.log('Ocorreu um erro ao obter lista de payments', error);
    }
    finally {

      this.gettingPayments = false;

    }
  }

  async getPaymentsById(id: string) {
    this.gettingPayments = true;
    this.showSkeleton = true;
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.payment.base + `/${id}`, {
        headers: { Authorization: `${token}` },
      });
      const payment = data as PaymentsById;
      const emails = [...new Set(payment.feedBacks.map(feedback => feedback.email))];
      this.activeModalTab = setPaymentModalTabByStatus(payment.statusTitle as PaymentStatusTypes);
      for (let email of emails) {
        if (this.photos[email] === undefined)
          await this.getPhotoStringForEmail(email);
      }
      this.paymentById = data;
      // if (this.paymentById.documents.length > 0){
      //   const document = this.paymentById.documents[0];
      //     this.paymentNFFile = {
      //       id: document.id,
      //       contractId: document.contractId,
      //       fileName: document.fileName,
      //       sharepointPath: document.sharepointPath,
      //       fileExtension: document.fileExtension,
      //       type: document.type,
      //       createdAt: document.createdAt,
      //     };
      // }
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de payments', error);
    }
    this.gettingPayments = false;
    this.showSkeleton = false;
  }

  async getApproversByPayingCompany(companyId: string) {
    this.gettingApprovers = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(this.endpoint.selects.approvers + `/${companyId}`, {
        headers: { Authorization: `${token}` },
      });
      this.approverData = data;
      console.log("APPROVERDATA", data);
    } catch (error) {
      console.log('Ocorreu um erro ao obter dados de aprovadores deste contrato');
    }
    this.gettingApprovers = false;
  }

  isExpiring(expirationDate: string, deadlineContract: string): boolean {
    if (expirationDate === null || deadlineContract === null) return false;

    if (new Date(deadlineContract).getTime() < new Date(expirationDate).getTime()) {
      return true;
    }
    return false;
  }

  exceedsContractBalance(totalAmount: number, contractBalance: number): boolean {
    if (totalAmount === null || contractBalance === null) return false;

    if (contractBalance < 0) return true;

    return false;
  }

  isNotFinished(status: string): boolean {
    switch (status) {
      case 'Paid':
      case 'Canceled':
        return false;
      default:
        return true;
    }
  }

  isReviewStatus(status: string): boolean {
    switch (status) {
      case 'Owner Review':
      case 'Finance Review':
      case 'Legal Review':
      case 'Line Manager Review':
      case 'Internal Process':
        return true;
      default:
        return false;
    }
  }

  validateStatus(status: string[]) {
    if (status.length !== 0 && status.length !== this.filterCheckboxs.length) {
      return true;
    }
    return false;
  }

  validateDeadline(deadlineDate: string) {
    if (deadlineDate !== '') return true;
    return false;
  }

  validateContractValue(contractValue: number | null) {
    if (contractValue !== null) return true;
    return false;
  }

  validateListCompaniesAndManagers(list: Selects[]) {
    if (list.length !== 0) return true;
    return false;
  }

  setPaymentsFilterValues(contractPayload: FilterPayload) {
    this.filtersPayments = contractPayload;
  }

  async getFilterStatusName() {
    try {
      const token = await this.authService.getIdToken();
      const params = {
        IsContract: false,
        InProcess: this.paymentType === 0 ? true : false,
      };
      const { data } = await this.$http.get(this.endpoint.filter.base, {
        params,
        headers: { Authorization: `${token}` },
      });
      this.filterCheckboxs = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de status', error);
    }
  }

  async getCompanies() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.companies, {
        headers: { Authorization: `${token}` },
      });
      this.companies = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de empresas', error);
    }
  }

  async getVendors() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.vendor, {
        headers: { Authorization: `${token}` },
      });
      this.vendors = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de vendors', error);
    }
  }

  async getManagers() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.managers, {
        headers: { Authorization: `${token}` },
      });
      this.managers = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de gestores', error);
    }
  }

  async getPaymentMethods() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.paymentMethods, {
        headers: { Authorization: `${token}` },
      });
      this.paymentMethods = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de tipo de pagamentos', error);
    }
  }

  async getDocumentTypes() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.documentTypes, {
        headers: { Authorization: `${token}` },
      });
      this.documentTypes = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de tipo de pagamentos', error);
    }
  }

  async getContracts(entityId: string = '') {
    try {
      this.gettingContract = true;
      const token = await this.authService.getIdToken();
      const endpoint = this.isManagement
        ? this.endpoint.contract.byUser
        : this.endpoint.contract.byVendorSelected;

      const { data } = await this.$http.get(endpoint, {
        ...(!this.isVendor && { params: { entityId } }),
        headers: { Authorization: `${token}` },
      });

      this.contracts = data;
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de contratos');
    }
    this.gettingContract = false;
  }

  async getFinancialBudget() {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.financialBudget.base, {
        headers: {
          Authorization: `${token}`,
          "lesteKey": this.endpoint.auth.fintiServicesKey!,
          "API-Key": `${this.endpoint.auth.fintiKey}`,
        },
      });
      this.financialBudgetData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de planos financeiros', error);
    }
  }

  async getCostCenterByPayingCompany(companyId: number) {
    this.gettingCostCenters = true;
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.costCenter.base + `/${companyId}`, {
        headers: {
          Authorization: `${token}`,
          "lesteKey": this.endpoint.auth.fintiServicesKey!
        },
      });
      this.costCenterData = response;
    } catch (error) {
      this.costCenterData = [];
    }
    this.gettingCostCenters = false;
  }

  async getRequesterCompanies() {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.selects.requesterCompanies, {
        headers: { Authorization: `${token}` },
      });
      this.requesterCompanies = response.data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de cost centers', error);
    }
  }

  async handleEditPayment(payload: EditPaymentPayload) {
    this.editingPayment = true;
    const token = await this.authService.getIdToken();
    try {
      await this.$http.put(this.endpoint.payment.base + `/${this.paymentById.id}`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.closeEditPaymentModal();
      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Payment Successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getPayments(this.filtersPayments);
    } catch (error) {
      console.log('Ocorreu um erro ao editar esse pagamento');
      this.hasError = true;
      this.textError = setErrorText(error);
    }
    this.editingPayment = false;
  }

  async submitEditedActivity(payload: any) {
    this.editingActivity = true;
    try {
      const token = await this.authService.getIdToken();

      await this.$http.put(this.endpoint.services.base + `/${this.activityIsEditing.id}`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.closeEditActivityModal();
      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Payment Successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getPaymentsById(this.paymentById.id);
    } catch (error) {
      console.log('ocorreu um erro ao editar esta atividade', error);
      this.hasError = true;
      this.textError = setErrorText(error);
      this.editingActivity = false;
    }
    this.editingActivity = false;
  }

  async sendNFPayment(file: any) {
    this.loadingSendNF = true;
    try {
      const token = await this.authService.getIdToken();
      const formData = new FormData();

      formData.append('InvoicePayment', file);
      await this.$http.post(
        this.endpoint.payment.uploadInvoice + `/${this.paymentById.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        }
      );
      await this.getPaymentsById(this.paymentById.id);
    } catch (error: any) {
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.loadingSendNF = false;
    this.closeSendModal();
  }

  async removeNFPayment(documentId: string) {
    this.removingInvoicePayment = true;
    try {
      const token = await this.authService.getIdToken();
      const response = await this.$http.patch(
        this.endpoint.payment.removeInvoice + `/${documentId}`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      );
      await this.getPaymentsById(this.paymentById.id);
    } catch (error: any) {
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.removingInvoicePayment = false;
  }

  async createPaymentWithoutContract(newPaymentPayload: NewPaymentPayload) {
    this.creatingContract = true;
    this.loadingMessage = 'Criando Pagamento';
    try {
      const token = await this.authService.getIdToken();

      await this.$http.post(this.endpoint.payment.withoutContract, newPaymentPayload, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.creatingContract = false;
      this.loadingMessage = '';
      this.closeNewPaymentModal();
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Payment Successfully created',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getPayments(this.filtersPayments);
    } catch (error: any) {
      this.closeNewPaymentModal();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      this.creatingContract = false;
      this.loadingMessage = '';
    }
  }

  async approvePayment(flow: PaymentFlow) {
    this.approvingPayment = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        paymentId: this.paymentById.id,
        feedback: flow.feedback,
        isRequestAdditive: flow.isRequestAdditive,
      };
      const response = await this.$http.patch(this.endpoint.payment.approve, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalApprovePayment();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Payment Successfully approved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getPayments(this.filtersPayments);
      }
    } catch (error: any) {
      this.closeModalApprovePayment();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 8000);
    }
    this.closeModalApprovePayment();
    this.approvingPayment = false;
    this.closeModalPaymentFinanceActions();
  }

  async reprovePayment(reprovePaymentField: string) {
    this.approvingPayment = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        paymentId: this.paymentById.id,
        feedback: reprovePaymentField,
      };
      const response = await this.$http.patch(this.endpoint.payment.reprove, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalApprovePayment();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Payment Successfully reproved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getPayments(this.filtersPayments);
      }
    } catch (error: any) {
      this.closeModalApprovePayment();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.closeModalApprovePayment();
    this.approvingPayment = false;
  }

  async bundlePayments(payload: any) {
    this.bundlingPayment = true;
    try {
      const token = await this.authService.getIdToken();
      const response = await this.$http.patch(this.endpoint.payment.bundle, payload, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeNewBundlePaymentsModal();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Payment Successfully Bundled',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getPayments(this.filtersPayments);
      }
    } catch (error) {
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.bundlingPayment = false;
    this.closeNewBundlePaymentsModal();
  }

  public async getPhotoStringForEmail(email: string): Promise<void> {
    try {
      const resultPhoto = await getUserPhotoFromShieldByEmail(email);
      if (resultPhoto) {
        this.photos[email] = `data:;base64,${resultPhoto}`;
      }
      else
        this.photos[email] = DefaultPhoto;
    }
    catch (err) {
      this.photos[email] = DefaultPhoto;
    }
  }


  async created() {
    this.getCompanies();
    this.getNonFundCompanies();
    this.getFilterStatusName();
    this.getContracts();
    this.getVendors();
    this.getManagers();
    this.getPayments(this.filtersPayments);
    this.getRequesterCompanies();
    this.getPaymentMethods();
    this.getDocumentTypes();
    this.getFinancialBudget();
  }
}
