
import { Component, Vue } from 'vue-property-decorator';
import UsersHeader from '@/components/headers/users-headers';
import Headers from '@/types/general/headers';
import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import ModalNewProfile from '@/components/Modals/ModalNewProfile.vue';
import profile from '@/types/general/profile';
import AsyncComputed from 'vue-async-computed-decorator';
import { Action, Getter } from 'vuex-class';

@Component({ components: { ModalNewProfile } })
export default class Onboarding extends Vue {
  loading = false;
  search = '';
  id = '';
  emailUser = '';
  title = 'Create New Profile';
  authService = new AuthService();
  openNewProfileModal = false;
  isAdditional = false;
  isEdit = false;
  isEditAdditional = false;

  canCreate = false;
  profile: profile[] = [];
  private endpoint = new Endpoint();

  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;

  @AsyncComputed()
  async getCanCreate() {
    this.canCreate = await this.verifyUserPermissions({
      resource: 'users',
      action: 'manage',
    });
  }

  public usersHeaders: Headers[] = UsersHeader.header;

  async success() {
    this.openNewProfileModal = false;
    await this.getUserProfiles();
  }
  public handleRowCLickTransaction(id: string, email: string) {
    this.isAdditional = true;
    this.isEdit = false;
    this.title = 'Setting Transaction Limit';
    this.openNewProfileModal = true;
    this.id = id;

    this.emailUser = email;
  }
  public handleRowCLickEdit(id: string, email: string) {
    this.isEdit = true;
    this.isAdditional = false;
    this.title = 'Editing Profile';
    this.openNewProfileModal = true;
    this.id = id;
    this.emailUser = email;
  }
  async getUserProfiles() {
    const token = await this.authService.getIdToken();
    this.profile = await this.$http.get(this.endpoint.users.profiles, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${token}`,
      },
    });
  }
  closeModal() {
    this.openNewProfileModal = false;
    this.isEdit = false;
    this.isAdditional = false;
    this.id = '';
    this.emailUser = '';
  }
  async mounted() {
    this.loading = true;
    await this.getUserProfiles();
    this.loading = false;
  }
}
