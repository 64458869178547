
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import SkeletonLoader from '@/components/SkeletonLoader.vue';

import ContractDetail, { contractDetailInitialValue } from '@/types/contractDetail';
import Selects from '@/types/selects';

@Component({ components: { SkeletonLoader } })
export default class VendorData extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(Array) jurisdictions!: Selects[];
  @Prop(Boolean) gettingContract!: boolean;

  setVendorType(onShore: boolean | null) {
    if (onShore === null) return '--';

    return onShore ? 'Brazil' : 'Non Brazil';
  }

  setActivityTypes(activity: Selects[]) {
    if (!activity.length) return '--';

    return activity.map((item) => item.name).join(', ');
  }

  setMainJurisdiction(jurisdictionId: string) {
    if (jurisdictionId === null) return '--';

    const mainJurisdiction = this.jurisdictions.find((item) => item.id === jurisdictionId)?.name;

    return mainJurisdiction === undefined ? '--' : mainJurisdiction;
  }

  setSecondaryJurisdiction(jurisdictions: Selects[]) {
    if (!jurisdictions.length) return '--';

    return jurisdictions.map((item) => item.name).join(', ');
  }
}
