
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AuthService } from '../services/authService';
import Endpoint from '../utils/endpoint';

import User from '@/types/auth/user';

interface Error {
  errorCode: string;
}
@Component({})
export default class Login extends Vue {
  @Getter('getUser', { namespace: 'modals' }) loggedUser!: User;
  @Action('setUser', { namespace: 'modals' }) setUser: any;

  @Action('canList', { namespace: 'modals' }) canList: any;
  @Getter('getCanList', { namespace: 'modals' }) getCanList!: any;
  @Action('canApprove', { namespace: 'modals' }) canApprove: any;
  @Action('canCreate', { namespace: 'modals' }) canCreate: any;
  @Action('canDelete', { namespace: 'modals' }) canDelete: any;
  @Action('canUpdate', { namespace: 'modals' }) canUpdate: any;
  @Action('canApproveDenied', { namespace: 'modals' }) canApproveDenied: any;

  @Action('setAreas', { namespace: 'modals' }) setAreas: any;
  @Getter('getAreas', { namespace: 'modals' }) getAreas!: any;

  @Prop(String) public token!: string | null;

  loadingLogin = false;
  errorLogin = false;
  isLogged = false;
  private endpoint = new Endpoint();
  authService = new AuthService();

  async mounted() {
    // const hash = window.location.hash.substr(1);
    // if (!hash) {
    await this.initialize();
    // } else {
    //   await this.authService.handleRedirect(this.setUser);
    // }
  }

  async initialize(): Promise<void> {
    try {
      const userData = await this.authService.getTokenData();

      if (!userData) {
        try {
          await this.authService.handleRedirect(this.setUser);
        } catch (error) {
          const responseError = error as Error;
          this.setUser(null);
          if (responseError.errorCode === 'user_cancelled') {
            sessionStorage.clear();
            localStorage.clear();
          }
        }
        this.loadingLogin = false;
      } else {
        await this.setUser(userData);
        localStorage.setItem('userEmail', userData.email);
        this.loadingLogin = true;
        const redirectRoute = localStorage.getItem('route');
        redirectRoute !== null || !!redirectRoute
          ? this.$router.push({ path: `${redirectRoute}` })
          : (window.location.href = '/');
      }
    } catch (error) {
      this.cancelLogin();
    }
    this.loadingLogin = false;
  }

  async tryLogin(): Promise<void> {
    try {
      this.authService.tryLoginSilentOrRedirect(this.setUser);
    } catch (error) {
      this.setUser(null);
      const responseError = error as Error;
      if (responseError.errorCode === 'user_cancelled') {
        sessionStorage.clear();
        localStorage.clear();
      }
    }
  }

  cancelLogin(): void {
    console.log('cancel login');
    sessionStorage.clear();
    // localStorage.clear();
    this.$router.push({ name: 'Logout' });
  }
}
