import { selectsInitialValue } from './selects';

export default class OnboardingContacts {
  name = '';
  email = '';
  phone = '';
  document = '';
  individualId = '';
  contactRelationClassificationSelected = selectsInitialValue;
  individualEntityRelationClassification = selectsInitialValue;
  contactTelephone? = '';
  contactLogin? = true;
  documentType? = '';
  relationClassification? = '';
}
