export enum PaymentStatusTypes {
  OWNER_REVIEW =  'Owner Review',      
  FINANCE_REVIEW =  'Finance Review',
  WAITING_PAYMENT =  'Waiting Payment',
  WAITING_INVOICE = 'Waiting Invoice',
  WAITING_NF = 'Waiting NF',
  CANCELED = 'Canceled',
  OPEN = 'Open',
  PAID = 'Paid',
  PROCESSING = 'Processing',
  BLOCKED = 'Blocked',
  CLOSED = 'Closed'
}

export const PAYMENT_STATUS = PaymentStatusTypes;
