const baseAPI = process.env.VUE_APP_URL_BASE_API;
const fintiAPI = process.env.VUE_APP_URL_FINTI_API;
const fintiKey = process.env.VUE_APP_FINTI_KEY;
const fintiServicesKey = process.env.VUE_APP_FINTI_GATE_KEY;
const shieldAPI = process.env.VUE_APP_URL_SHIELD_API;
const lesteKey = process.env.VUE_APP_SHIELD_KEY;
const appName = process.env.VUE_APP_NAME;
export default class Endpoint {
  public appName = appName;
  public auth = {
    security: `${baseAPI}/api/Security/my-menus`,
    profiles: `${baseAPI}/api/Security/my-profiles`,
    lestShield: `${shieldAPI}/GetAppByName`,
    lesteKey: lesteKey,
    fintiKey: fintiKey,
    fintiServicesKey: fintiServicesKey,
  };

  public onboarding = {
    base: `${baseAPI}/api/Onboarding`,
    send: `${baseAPI}/api/Onboarding/send`,
    pendency: `${baseAPI}/api/Onboarding/pendency`,
    result: `${baseAPI}/api/Onboarding/result`,
  };

  public users = {
    editProfile: `${baseAPI}/api/user-profiles/`,
    editProfiletransactionlimit: `${baseAPI}/api/transactionlimit/`,
    transactionLimit: `${baseAPI}/api/transactionlimit`,
    newProfile: `${baseAPI}/api/user-profiles`,
    findProfileById: `${baseAPI}/api/user-profiles/`,
    profiles: `${baseAPI}/api/user-profiles`,
  };

  public selects = {
    fileDocumentType: `${baseAPI}/api/select/file-document-types`,
    contactDocumentType: `${baseAPI}/api/select/contact-document-types`,
    relationClassifications: `${baseAPI}/api/select/relation-classifications`,
    jurisdictions: `${baseAPI}/api/select/jurisdictions`,
    formOrganizations: `${baseAPI}/api/select/form-organizations`,
    activityTypes: `${baseAPI}/api/select/activity-types`,
    companies: `${baseAPI}/api/select/companies`,
    managers: `${baseAPI}/api/select/managers`,
    vendor: `${baseAPI}/api/Select/vendors`,
    cities: `${baseAPI}/api/select/cities`,
    paymentMethods: `${baseAPI}/api/select/payment-methods`,
    vendorBank: `${baseAPI}/api/Select/vendor-bank-data`,
    documentTypes: `${baseAPI}/api/Select/DocumentTypes`,
    requesterCompanies: `${baseAPI}/api/Select/requester-companies`,
    approvers: `${baseAPI}/api/Select/approvers`,
    contract: `${baseAPI}/api/contract`,
  };

  public costCenter = {
    base: `${fintiAPI}/api/CostCenter/by-companyid`,
    create: `${baseAPI}/api/CostCenterAllocation`,
  };

  public financialBudget = {
    base: `${fintiAPI}/api/FinancialBudget`,
  };

  public company = {
    base: `${fintiAPI}/api/Company`,
    approvers: `${fintiAPI}/api/Company/approver`
  }
  public aditive = {
    base: `${baseAPI}/api/Additive`,
    approve: `${baseAPI}/api/Additive/approve`,
    reprove: `${baseAPI}/api/Additive/reprove`,
    cancel: `${baseAPI}/api/Additive/cancel`,
    uploadSignedContractAdditive: `${baseAPI}/api/Additive/upload-signed-contract`,
  };

  public files = {
    download: `${baseAPI}/api/File/download`,
    backgroundCheck: `${baseAPI}/api/onboarding/back-document`,
  };

  public contract = {
    base: `${baseAPI}/api/contract`,
    cancel: `${baseAPI}/api/contract/cancel`,
    approve: `${baseAPI}/api/contract/approve`,
    reprove: `${baseAPI}/api/contract/reprove`,
    uploadSignedContract: `${baseAPI}/api/contract/upload-signed-contract`,
    byUser: `${baseAPI}/api/contract/by-user`,
    byVendorSelected: `${baseAPI}/api/contract/payment`,
    removeSignedContract: `${baseAPI}/api/Contract/remove-document`,
    delete:  `${baseAPI}/api/Contract/`,
  };

  public payment = {
    base: `${baseAPI}/api/Payment`,
    withoutContract: `${baseAPI}/api/payment/without-contract`,
    approve: `${baseAPI}/api/Payment/approve`,
    reprove: `${baseAPI}/api/Payment/reprove`,
    uploadInvoice: `${baseAPI}/api/Payment/upload-invoice`,
    removeInvoice: `${baseAPI}/api/Payment/remove-invoice`,
    bundle: `${baseAPI}/api/Payment/bundle`,
  };

  public filter = {
    base: `${baseAPI}/api/Filter/status`,
  };

  public additive = {
    base: `${baseAPI}/api/additive`,
  };

  public services = {
    base: `${baseAPI}/api/service`,
  };

  public approval = {
    modes: `${fintiAPI}/api/approval-modes`
  }



}
