
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { mask } from 'vue-the-mask';

import AsyncComputedPlugin from 'vue-async-computed';
import AsyncComputed from 'vue-async-computed-decorator';

Vue.use(AsyncComputedPlugin);

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import FormatDate from '@/utils/formatDate';
import GoBack from '@/utils/goBackPage';
import HandleSetDocumentRequired from '@/utils/handleSetDocumentRequired';
import Rules from '@/utils/rules';
import SetDocumentExpirationDate from '@/utils/setDocumentExpirationDate';
import SetPendencyProfile from '@/utils/setPendencyProfile';
import SetStatusText from '@/utils/setStatusText';
import TruncateFile from '@/utils/truncateFile';

import User from '@/types/auth/user';
import BackDocuments from '@/types/backDocuments';
import BackDraft from '@/types/backDrafts';
import { default as DocumentType, default as DocumentTypes } from '@/types/documentType';
import Onboarding from '@/types/onboarding';
import OnboardingContacts from '@/types/onboardingContacts';
import OtherDocuments from '@/types/otherDocuments';
import VendorResponse from '@/types/response/response';
import Selects, { selectsInitialValue } from '@/types/selects';
import SurveyQuestions from '@/types/suveyQuestions';

import DisclaimerForm from '@/components/onboardingsActions/DisclaimerForm.vue';
import ModalPendencyComment from '@/components/onboardingsActions/ModalSetPendency.vue';

import { BankData, BaseBanks } from '@/types/bankData';
import { setErrorText } from '@/utils/errorHandler';
import moment from 'moment';
import { VForm } from './NewOnboarding.vue';

@Component({
  directives: { mask },
  components: {
    ModalPendencyComment,
    DisclaimerForm,
  },
})
export default class NewOnboarding extends Vue {
  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Getter('getIsVendor', { namespace: 'modals' }) isVendor!: boolean;
  @Getter('getIsCollaborator', { namespace: 'modals' }) isCollaborator!: boolean;
  @Getter('getIsApprover', { namespace: 'modals' }) isApprover!: boolean;
  @Getter('getIsApproverMaster', { namespace: 'modals' }) isApproverMaster!: boolean;

  @Action('setModalPendencyCommentState', { namespace: 'modals' })
  public setModalPendencyCommentState!: any;

  @Action('setDisclaimerForm', { namespace: 'modals' }) public setDisclaimerForm!: any;

  @Getter('getPendencyComment', { namespace: 'modals' }) pendencyComment!: string;

  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;

  @Prop(String) public id!: string;
  @Prop(String) public token!: string | null;

  @Watch('$refs.form')
  public enableSubmitButton() {
    if (
      this.companyName !== '' &&
      (this.companyCnpj !== '' || this.companyEIN !== '') &&
      this.companyAddress !== '' &&
      this.onboardingContacts[0].name !== '' &&
      this.onboardingContacts[0].document !== '' &&
      this.onboardingContacts[0].email !== ''
    ) {
      return false;
    }
    return true;
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  @AsyncComputed()
  async getCanList() {
    this.canList = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'List',
    });
  }

  testando(e: any) {
    console.log(e);
  }

  oarquivo(item: any) {
    console.log('arquivo: ', item);
  }

  validateForm() {
    this.$validator.validateAll().then((result) => {
      if (result) {
        // eslint-disable-next-line
        alert('All Passes!');
        return;
      }
      alert('Oh NO!');
    });
  }

  setVFileTruncate() {
    return this.$vuetify.breakpoint.xl === true ? '25' : 15;
  }

  public handleContactTypeItems() {
    const mainPointOfContact = this.contactRelationClassifications.find(
      (item) => item.id === '715be7b4-463c-43d3-b30b-cc57a4141cb0'
    );
    if (!mainPointOfContact) return;
    mainPointOfContact['disabled'] = true;
  }

  data = 0;
  backData = 0;
  showHide = false;

  loadingOnboarding = false;
  laodingRelationClassifications = false;
  loading = false;
  loadingJuridisction = false;
  loadingFileDocumentTypes = false;
  loadingFormOrganization = false;
  loadingCompanyActivities = false;
  uploadingBackDocuments = false;
  editingBackDocuments = false;
  loadingBackDocuments = false;
  fileIsDeleting: number | null = null;
  itemIsDeleting = '';
  hasAttachmentDeleting = false;
  onboarding = {} as Onboarding;

  socialContract = {
    socialContractExpirationDate: '',
    socialContractExpiration: false,
    socialContractArchive: new Blob(),
  };

  cnpjCard = {
    cnpjExpirationDate: '',
    cnpjExpiration: false,
    cnpjArchive: new Blob(),
  };

  antiCorruption = {
    antiCorruptionExpirationDate: '',
    antiCorruptionExpiration: false,
    antiCorruptionPolicyArchive: new Blob(),
  };

  otherArchive: Blob | unknown = new Blob();
  otherDocuments: OtherDocuments[] = [];
  backDocuments: BackDocuments[] = [];
  backDraft: BackDraft[] = [];

  companyName = '';
  companyDocumentTypes: Selects[] = [];
  companyDocumentTypeSelected: Selects = selectsInitialValue;
  companyType = null;
  companyCnpj = '';
  companyEIN = '';
  companyAddress = '';  
  companyActivities: Selects[] = [];
  companyActivitiesSelected: string[] = [];
  secondaryActivity = '';
  secondaryActivityItens: string[] = [];
  mainActivityJurisdiction = '';
  mainActivityJurisdictionItens: string[] = [];
  secondaryActivityJurisdiction = '';
  secondaryActivityJurisdictionItens: string[] = [];

  formOrganizations: Selects[] = [];
  formOrganizationSelected: Selects = selectsInitialValue;

  mainActivityJurisdictions: Selects[] = [];
  mainActivityJurisdictionSelected: Selects = selectsInitialValue;
  operatingJurisdictionSelected: string[] = [];
  banks = BaseBanks;  
  selectedBank: BankData = { id: '', name: '' };
  selectedAgency = '';
  selectedAgencyDigit = '';
  selectedAccount = '';
  selectedAccountDigit = '';
  public contactRelationClassifications: Selects[] = [];
  public contactRelationClassificationSelected: Selects = selectsInitialValue;

  onboardingContacts: OnboardingContacts[] = [];

  notes = '';

  fileDocumentTypes: DocumentType[] = [];
  contactDocumentTypes: DocumentType[] = [];

  surveys: SurveyQuestions[] = [];

  termConditionsCheck = false;
  showErrors = false;

  canList = false;

  private endpoint = new Endpoint();
  authService = new AuthService();
  rules = new Rules();
  formatDate = FormatDate;
  setStatusText = SetStatusText;
  setPendencyProfile = SetPendencyProfile;
  setDocumentExpirationDate = SetDocumentExpirationDate;
  handleSetDocumentRequired = HandleSetDocumentRequired;
  goBack = GoBack;
  truncateFile = TruncateFile;

  get handleShowStatus(): boolean {
    return this.$route.name !== 'EditOnboarding' ? true : false;
  }

  public addNewComment(): void {
    this.setModalPendencyCommentState(true);
  }

  public setPendencyComment(data: any) {
    console.log(
      'aqui em',
      moment(data.date).format('DD/MMM/YYYY') +
        ` - ${data.user.split('@')[0]}(${data.userUpdateAs}): `
    );
  }

  public setStatusClass(status: number): string | undefined {
    if (status === 1) return 'pending-vendor';
    if (status === 2) return 'requested';
    if (status === 3) return 'review-vendor';
    if (status === 4) return 'review-leste';
    if (status === 5) return 'approved';
    if (status === 6) return 'denied';
  }

  public newContact(): void {
    this.onboardingContacts.push(new OnboardingContacts());
  }

  public removeContact(contact: OnboardingContacts): void {
    this.onboardingContacts.splice(this.onboardingContacts.indexOf(contact), 1);
  }

  public addSecondaryActivities(): void {
    if (this.secondaryActivity !== '') {
      this.secondaryActivityItens.push(this.secondaryActivity);
      this.secondaryActivity = '';
    }
  }

  public removeSecondaryActivities(item: string): void {
    this.secondaryActivityItens.splice(this.secondaryActivityItens.indexOf(item), 1);
  }

  public addMainActivityJurisdiction(): void {
    if (this.mainActivityJurisdiction !== '') {
      this.mainActivityJurisdictionItens.push(this.mainActivityJurisdiction);
      this.mainActivityJurisdiction = '';
    }
  }

  public removeMainActivityJurisdiction(item: string): void {
    this.mainActivityJurisdictionItens.splice(this.mainActivityJurisdictionItens.indexOf(item), 1);
  }

  public addSecondaryActivityJurisdiction(): void {
    if (this.secondaryActivityJurisdiction !== '') {
      this.secondaryActivityJurisdictionItens.push(this.secondaryActivityJurisdiction);
      this.secondaryActivityJurisdiction = '';
    }
  }

  public removeSecondaryActivityJurisdiction(item: string): void {
    this.secondaryActivityJurisdictionItens.splice(
      this.secondaryActivityJurisdictionItens.indexOf(item),
      1
    );
  }

  public getContactPosition(item: OnboardingContacts): number {
    return this.onboardingContacts.indexOf(item) + 1;
  }

  public addOtherAttachment(): void {
    this.otherDocuments.push(new OtherDocuments());
  }

  public removeOtherAttachment(item: OtherDocuments) {
    this.otherDocuments.splice(this.otherDocuments.indexOf(item), 1);
  }

  public addBackDocument(): void {
    this.backDraft.push(new BackDraft());
  }

  public removeBackDocument(item: BackDraft) {
    this.backDraft.splice(this.backDraft.indexOf(item), 1);
  }

  public setArchiveName(item: OtherDocuments) {
    let index = this.otherDocuments.indexOf(item);
    let file = this.otherDocuments[index].attachment;
    return file?.name;
  }

  public setBackArchiveName(item: BackDraft) {
    let index = this.backDraft.indexOf(item);
    let file = this.backDraft[index].attachment;
    return file?.name;
  }

  public removeAttachment(file: OtherDocuments) {
    this.data++;
    let index = this.otherDocuments.indexOf(file);
    this.otherDocuments[index].attachment = new File([], '');
  }

  public removeBackAttachment(file: BackDraft) {
    this.backData++;
    let index = this.backDraft.indexOf(file);
    this.backDraft[index].attachment = new File([], '');
  }

  public async getOnboardingById(token: string | null) {
    try {
      this.loadingOnboarding = true;
      const onboarding = await this.$http.get(this.endpoint.onboarding.base + `/${this.id}`, {
        headers: { Authorization: `${token}` },
      });
      this.onboarding = onboarding.data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter dados desse Onboarding');
    }
    this.loadingOnboarding = false;
  }

  public async getFormOrganization(token: string | null) {
    try {
      if (!this.canList) return;
      this.loadingFormOrganization = true;
      const formOrganization = await this.$http.get(this.endpoint.selects.formOrganizations, {
        headers: { Authorization: `${token}` },
      });
      this.formOrganizations = formOrganization.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar tipos de empresa');
    }
    this.loadingFormOrganization = false;
  }

  public async getJuridisction(token: string | null): Promise<void> {
    try {
      if (!this.canList) return;
      this.loadingJuridisction = true;
      const jurisdictions = await this.$http.get(this.endpoint.selects.jurisdictions, {
        headers: { Authorization: `${token}` },
      });
      this.mainActivityJurisdictions = jurisdictions.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar juridições');
    }
    this.loadingJuridisction = false;
  }

  public async getRelationClassifications(token: string | null) {
    try {
      if (!this.canList) return;
      this.laodingRelationClassifications = true;
      const relationClassification = await this.$http.get(
        this.endpoint.selects.relationClassifications,
        {
          headers: { Authorization: `${token}` },
        }
      );
      this.contactRelationClassifications = relationClassification.data;
      this.handleContactTypeItems();
    } catch (error) {
      console.log('Ocorreu um erro ao listar relações de contatos');
    }
    this.laodingRelationClassifications = false;
  }

  public async getActvityTypes(token: string | null) {
    try {
      if (!this.canList) return;
      this.loadingCompanyActivities = true;
      const activityTypes = await this.$http.get(this.endpoint.selects.activityTypes, {
        headers: { Authorization: `${token}` },
      });
      this.companyActivities = activityTypes.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar atividades da empresa');
    }
    this.loadingCompanyActivities = false;
  }

  public handleSetContactType(contactType: string) {
    return this.contactRelationClassifications.find(
      (contact) => (contact.id as string).toUpperCase() === contactType.toUpperCase()
    )?.name;
  }

  public setOnboardingItems() {
    this.companyName = this.onboarding.companyName;
    this.companyCnpj = this.onboarding.identifier;
    this.companyEIN = this.onboarding.identifier;
    this.companyActivitiesSelected = this.onboarding.activityTypeIds;
    this.operatingJurisdictionSelected = this.onboarding.jurisdictionOfOperationIds;
    this.companyAddress = this.onboarding.address !== null ? this.onboarding.address : '';
    this.onboardingContacts = this.onboarding.contacts.map((item) => {
      return {
        name: item.name,
        email: item.email,
        phone: item.phone,
        document: '',
        individualId: item.individualId,
        contactRelationClassificationSelected: this.contactRelationClassifications.find(
          (contact) => contact.id === item.relationClassification
        ) as Selects,
        individualEntityRelationClassification: { id: '', name: '' },
      };
    });
    this.surveys = this.onboarding.survey.map((item) => ({
      id: item.id,
      additionalInfo: item.additionalInfo,
      question: item.question,
      optionToShowText: item.optionToShowText,
      answer: this.activeOptionsAllowTextField(item) ? item.optionToShowText : item.answer,
      answerField: this.getAnswerField(item),
      options: item.options,
      placeholder: item.placeholder,
      showTextField: item.showTextField,
    }));

    if (this.$route.name === 'EditOnboarding') {
      this.mainActivityJurisdictionSelected = this.mainActivityJurisdictions.find(
        (juridisction) => juridisction.id === this.onboarding.jurisdictionId
      ) as Selects;
      this.formOrganizationSelected = this.formOrganizations.find(
        (formOrganization) => formOrganization.id === this.onboarding.formOrganizationId
      ) as Selects;
      this.otherDocuments = this.onboarding.documents.map((item) => {
        return {
          id: item.id,
          documentTypeSelected: this.fileDocumentTypes.find(
            (documentType) => documentType.id === item.documentType
          ) as DocumentTypes,
          expirationDate: item.expirationDate,
          observation: item.note,
          attachment: new File([`'${item.fileName}'`], item.fileName),
          fileName: '',
          url: '',
          note: '',
          documentType: 0,
        };
      });
      this.notes = this.onboarding.note === 'null' ? '' : this.onboarding.note;
    }
    this.backDraft = this.onboarding.backDocuments.map((item) => {
      return {
        id: item.id,
        documentTypeSelected: this.fileDocumentTypes.find(
          (documentType) => documentType.id === item.documentType
        ) as DocumentType,
        expirationDate: item.expirationDate,
        observation: item.note,
        attachment: new File([`'${item.fileName}'`], item.fileName),
        fileName: '',
        url: '',
        hasChange: false,
        hasDeleted: false,
      };
    });
  }

  public activeOptionsAllowTextField(survey: SurveyQuestions) {
    if (!survey.options) return false;
    if (!survey.answer) return false;
    return !survey.options.includes(survey.answer);
  }

  public isShowTextField(survey: SurveyQuestions) {
    if (!survey.options) return true;
    if (survey.answer === null) return false;
    return survey.answer === survey.optionToShowText;
  }

  public isOnlyTextField(survey: SurveyQuestions) {
    return survey.options === null;
  }

  public getAnswerField(survey: SurveyQuestions) {
    return this.activeOptionsAllowTextField(survey) || this.isOnlyTextField(survey)
      ? survey.answer
      : survey.answerField;
  }

  public checkIfCanEditSurvey(requester: string) {
    if (
      this.$route.name === 'EditOnboarding' &&
      (!this.isApproverMaster || requester !== this.user.email)
    ) {
      return true;
    }
    return false;
  }

  public hasDuplicatedFiles(otherDocument: OtherDocuments[]) {
    if (otherDocument.some((item) => item.attachment === null)) {
      return;
    } else {
      const onlyFilledFiles = otherDocument.filter((item) => item.attachment.name !== '');
      const fileNames = onlyFilledFiles.map((item) => item.attachment.name);
      const fileNamesArray = [...new Set(fileNames)];

      return fileNamesArray.length === onlyFilledFiles.length ? false : true;
    }
  }

  public backDocumentHasChange(index: number) {
    this.backDraft[index].hasChange = true;
  }

  public backDocumentHasDeleted(index: number) {
    this.backDraft[index].hasDeleted = true;
  }

  public async handleFilterBackDocuments() {
    const uploadBackDocument = this.backDraft.filter((item) => !item.hasDeleted && item.id === '');
    const editBackDocument = this.backDraft.filter(
      (item) => item.hasChange && !item.hasDeleted && item.id
    );

    if (uploadBackDocument.length === 0 && editBackDocument.length === 0) return;

    this.loadingBackDocuments = true;

    const token = await this.authService.getIdToken();

    await this.uploadSelectedBackDocuments(uploadBackDocument, token);
    await this.editSelectedBackDocuments(editBackDocument, token);

    await this.getOnboardingById(token);

    this.loadingBackDocuments = false;
  }

  public async uploadSelectedBackDocuments(backToUpload: BackDraft[], token: string | null) {
    this.uploadingBackDocuments = true;
    for (const backDocument of backToUpload) {
      try {
        const formData = new FormData();
        formData.append('OnboardingId', this.id);
        formData.append('DtExpiration', backDocument.expirationDate);
        formData.append('DocumentType', backDocument.documentTypeSelected.id.toString());
        formData.append('File', backDocument.attachment);
        formData.append('Note', backDocument.observation !== '' ? backDocument.observation : '');

        await this.$http.post(
          this.endpoint.files.backgroundCheck,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `${token}`,
            },
          }
        );
      } catch (error) {
        console.log('Ocorreu um erro ao enviar backDocuments');
      }
    }
    this.uploadingBackDocuments = false;
  }

  public async editSelectedBackDocuments(backToEdit: BackDraft[], token: string | null) {
    this.editingBackDocuments = true;
    for (const backEdit of backToEdit) {
      try {
        const formData = new FormData();
        formData.append('Id', backEdit.id);
        formData.append('OnboardingId', this.id);
        formData.append('DtExpiration', backEdit.expirationDate);
        formData.append('DocumentType', backEdit.documentTypeSelected.id.toString());
        formData.append('File', backEdit.attachment);
        formData.append('Note', backEdit.observation !== '' ? backEdit.observation : '');

        await this.$http.put(this.endpoint.files.backgroundCheck, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        });
      } catch (error) {
        console.log('Ocorreu um erro ao enviar backDocuments');
      }
      this.editingBackDocuments = false;
    }
  }

  public async removeSelectedBackDocuments(backToDelete: BackDraft) {
    if (backToDelete.id === '') {
      this.removeBackDocument(backToDelete);
      return;
    }

    const token = await this.authService.getIdToken();

    this.hasAttachmentDeleting = true;
    const index = this.backDraft.indexOf(backToDelete);
    this.fileIsDeleting = index;
    try {
      const formData = new FormData();
      formData.append('Id', backToDelete.id);
      const response = await this.$http.delete<VendorResponse<any>>(this.endpoint.files.backgroundCheck, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
        data: formData,
      });

      if (!response.hasErrors) this.removeBackDocument(backToDelete);
    } catch (error) {
      console.log('Ocorreu um erro ao deletar arquivos');
    }
    this.hasAttachmentDeleting = false;
    this.fileIsDeleting = null;
  }

  public async setOnboardingToAssessment() {
    if (!this.form.validate() || this.hasDuplicatedFiles(this.otherDocuments)) {
      return;
    }
    this.loading = true;

    const token = await this.authService.getIdToken();

    const formData = new FormData();

    formData.append('Id', this.id);
    formData.append('CompanyName', this.companyName);
    formData.append('Address', this.companyAddress);
    this.companyActivitiesSelected.forEach((item) => formData.append('ActivityTypeIds[]', item));
    this.operatingJurisdictionSelected.forEach((item) =>
      formData.append('JurisdictionOfOperationIds[]', item)
    );
    formData.append('Identifier', this.onboarding.onShore ? this.companyCnpj : this.companyEIN);
    formData.append('JurisdictionId', this.mainActivityJurisdictionSelected?.id as string | Blob);
    formData.append('Note', this.notes !== '' ? this.notes : '');
    formData.append('Message', this.pendencyComment !== '' ? this.pendencyComment : '');
    formData.append('InternalMessage', false.toString());
    formData.append('BankId', this.selectedBank.id.toString());
    if (this.selectedAgency)
      formData.append('Agency', this.selectedAgency);
    if (this.selectedAgencyDigit)
      formData.append('AgencyDigit', this.selectedAgencyDigit);
    // Account is required so it's always appended
    formData.append('Account', this.selectedAccount);
    if (this.selectedAccountDigit)
      formData.append('AccountDigit', this.selectedAccountDigit);
    this.onboardingContacts.map((item, index) => {
      if (item.individualId !== '') {
        formData.append(`contacts[${index}].individualId`, item.individualId);
        formData.append(`contacts[${index}].name`, item.name);
        formData.append(`contacts[${index}].email`, item.email);
        formData.append(`contacts[${index}].phone`, item.phone);
        formData.append(`contacts[${index}].documentType`, '175E8B5F-89D7-42C3-A978-C625E3203442');
        formData.append(
          `contacts[${index}].individualEntityRelationClassification`,
          item.contactRelationClassificationSelected?.id as string | Blob
        );
      } else {
        formData.append(`contacts[${index}].name`, item.name);
        formData.append(`contacts[${index}].email`, item.email);
        formData.append(`contacts[${index}].phone`, item.phone);
        formData.append(`contacts[${index}].document`, item.document);
        formData.append(`contacts[${index}].documentType`, '175E8B5F-89D7-42C3-A978-C625E3203442');
        formData.append(
          `contacts[${index}].individualEntityRelationClassification`,
          item.contactRelationClassificationSelected?.id as string | Blob
        );
      }
    });
    this.otherDocuments.map((item, index) => {
      if (item.id !== '') {
        formData.append(`Documents[${index}].id`, item.id);
        formData.append(
          `Documents[${index}].documentType`,
          item.documentTypeSelected.id.toString()
        );
        formData.append(`Documents[${index}].file`, item.attachment);
        formData.append(
          `Documents[${index}].dtExpiration`,
          moment(item.expirationDate).format('YYYY-MM-DD')
        );
        formData.append(
          `Documents[${index}].note`,
          item.observation === null ? '' : item.observation
        );
      } else {
        formData.append(
          `Documents[${index}].documentType`,
          item.documentTypeSelected.id.toString()
        );
        formData.append(`Documents[${index}].file`, item.attachment);
        formData.append(
          `Documents[${index}].note`,
          item.observation === null ? '' : item.observation
        );
      }
    });
    this.surveys.map((item, index) => {
      formData.append(`SurveyAnswers[${index}].id`, item.id);
      formData.append(
        `SurveyAnswers[${index}].answer`,
        item.answer === item.optionToShowText || item.options === null
          ? (item.answerField as string)
          : (item.answer as string)
      );
    });
    try {
      await this.$http.put(this.endpoint.onboarding.send, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      });

      this.setFeedback({
        feedback: true,
        color: 'green',
        isError: false,
        text: 'Onboarding saved',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      this.goBack();
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: '#ad1318',
        isError: true,
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.loading = false;
  }

  public async getFileDocumentType(token: string | null) {
    try {
      if (!this.canList) return;

      this.loadingFileDocumentTypes = true;
      const fileDocumentTypes = await this.$http.get(this.endpoint.selects.fileDocumentType, {
        headers: { Authorization: `${token}` },
      });
      this.fileDocumentTypes = fileDocumentTypes.data;
      if (this.$route.name === 'ViewOnboardingVendor') this.handleSetRequiredDocumentTypes();
    } catch (error) {
      console.log('Ocorreu um erro ao obter DocumentType');
    }
    this.loadingFileDocumentTypes = false;
  }

  public handleSetRequiredDocumentTypes() {
    const requiredDocumentFiles = this.onboarding.onShore
      ? this.fileDocumentTypes.filter((item) => item.requiredForOnShore === true)
      : this.fileDocumentTypes.filter((item) => item.requiredForOnShore === false);

    requiredDocumentFiles.forEach((item) => {
      const otherDocumentsData: OtherDocuments = {
        documentTypeSelected: { ...item, isBlocked: true },
        id: '',
        expirationDate: item.monthsToExpire.toString(),
        observation: '',
        documentType: 0,
        note: '',
        attachment: new File([], ''),
        fileName: '',
        url: '',
      };

      this.otherDocuments.push(otherDocumentsData);
    });
  }

  public async getContactDocumentType() {
    const token = await this.authService.getIdToken();

    try {
      const contactDocumentTypes = await this.$http.get(this.endpoint.selects.contactDocumentType, {
        headers: { Authorization: `${token}` },
      });
      this.contactDocumentTypes = contactDocumentTypes.data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter DocumentType');
    }
  }

  public openDisclaimerModal() {
    this.setDisclaimerForm(true);
  }

  public async mounted(): Promise<void> {
    const token = await this.authService.getIdToken();
    await this.getOnboardingById(token);
    await this.getRelationClassifications(token);
    this.getFileDocumentType(token);
    await this.getJuridisction(token);
    await this.getFormOrganization(token);
    await this.getActvityTypes(token);
    this.addBackDocument();
    this.setOnboardingItems();
    if (this.$route.name === 'EditOnboarding' && this.onboarding.documents.length) return;
    // this.addOtherAttachment();
    (this.$refs.form as HTMLFormElement).resetValidation();
  }
}
