import Header from '@/types/general/headers';

export default class onboardingHeaders {
  public static header: Header[] = [
    {
      text: 'Start',
      align: 'start',
      sortable: true,
      value: 'dtEntry',
      class: 'item-table-header item-table-header-onboarding',
      width: '13%',
    },
    {
      text: 'Company',
      align: 'start',
      sortable: true,
      value: 'companyName',
      class: 'item-table-header item-table-header-onboarding',
      width: '',
    },
    {
      text: 'Type',
      align: 'start',
      sortable: true,
      value: 'onShore',
      class: 'item-table-header item-table-header-onboarding',
      width: '10%',
    },
    {
      text: 'Identifier',
      align: 'start',
      sortable: true,
      value: 'identifier',
      class: 'item-table-header item-table-header-onboarding',
      width: '',
    },
    {
      text: 'Main contact',
      align: 'start',
      sortable: true,
      value: 'mainContactName',
      class: 'item-table-header item-table-header-onboarding',
      width: '13%',
    },
    {
      text: 'Requester',
      align: 'start',
      sortable: true,
      value: 'creator',
      class: 'item-table-header item-table-header-onboarding',
      width: '12%',
    },
    {
      text: 'Status',
      align: 'start',
      sortable: false,
      value: 'onboardingStatus',
      class: 'item-table-header item-table-header-onboarding',
      width: '15%',
    },
  ];
}
