export interface BankData {
  id: string;
  name: string;
}

// export const BaseBanks: BankData[] = [
//   { id: "1", name: "Banco do Brasil S.A." },
//   { id: "33", name: "Banco Santander (Brasil) S.A." },
//   { id: "104", name: "Caixa Econômica Federal" },
//   { id: "237", name: "Banco Bradesco S.A." },
//   { id: "260", name: "NU PAGAMENTOS S.A (NUBANK)" },
//   { id: "290", name: "Pagseguro Internet S.A (PagBank)" },
//   { id: "323", name: "Mercado Pago - conta do Mercado Livre" },
//   { id: "341", name: "Itaú Unibanco S.A." },
//   { id: "348", name: "BANCO XP S/A" },
//   { id: "422", name: "Banco Safra S.A." },
//   { id: "477", name: "Citibank N.A." },
//   { id: "757", name: "Banco KEB do Brasil S.A." },
// ]

export const BaseBanks: BankData[] = [
  { id: '001' , name: "Banco do Brasil S.A." },
  { id: '033' , name: "Banco Santander (Brasil) S.A." },
  { id: '104' , name: "Caixa Econômica Federal" },
  { id: '237' , name: "Banco Bradesco S.A." },
  { id: '341' , name: "Banco Itaú S.A." },
  { id: '356' , name: "Banco Real S.A. (antigo)" },
  { id: '389' , name: "Banco Mercantil do Brasil S.A." },
  { id: '399' , name: "HSBC Bank Brasil S.A. - Banco Múltiplo" },
  { id: '422' , name: "Banco Safra S.A." },
  { id: '453' , name: "Banco Rural S.A." },
  { id: '633' , name: "Banco Rendimento S.A." },
  { id: '652' , name: "Itaú Unibanco Holding S.A." },
  { id: '745' , name: "Banco Citibank S.A." },
  { id: '246' , name: "Banco ABC Brasil S.A." },
  { id: '025' , name: "Banco Alfa S.A." },
  { id: '641' , name: "Banco Alvorada S.A." },
  { id: '029' , name: "Banco Banerj S.A." },
  { id: '038' , name: "Banco Banestado S.A." },
  { id: '000' , name: "Banco Bankpar S.A." },
  { id: '740' , name: "Banco Barclays S.A." },
  { id: '107' , name: "Banco BBM S.A." },
  { id: '031' , name: "Banco Beg S.A." },
  { id: '096' , name: "Banco BM&F de Serviços de Liquidação e Custódia S.A" },
  { id: '318' , name: "Banco BMG S.A." },
  { id: '752' , name: "Banco BNP Paribas Brasil S.A." },
  { id: '248' , name: "Banco Boavista Interatlântico S.A." },
  { id: '036' , name: "Banco Bradesco BBI S.A." },
  { id: '204' , name: "Banco Bradesco Cartões S.A." },
  { id: '225' , name: "Banco Brascan S.A." },
  { id: '044' , name: "Banco BVA S.A." },
  { id: '263' , name: "Banco Cacique S.A." },
  { id: '473' , name: "Banco Caixa Geral - Brasil S.A." },
  { id: '222' , name: "Banco Calyon Brasil S.A." },
  { id: '040' , name: "Banco Cargill S.A." },
  { id: 'M08' , name: "Banco Citicard S.A." },
  { id: 'M19' , name: "Banco CNH Capital S.A." },
  { id: '215' , name: "Banco Comercial e de Investimento Sudameris S.A." },
  { id: '756' , name: "Banco Cooperativo do Brasil S.A. - BANCOOB" },
  { id: '748' , name: "Banco Cooperativo Sicredi S.A." },
  { id: '505' , name: "Banco Credit Suisse (Brasil) S.A." },
  { id: '229' , name: "Banco Cruzeiro do Sul S.A." },
  { id: '003' , name: "Banco da Amazônia S.A." },  
  { id: '707' , name: "Banco Daycoval S.A." },
  { id: 'M06' , name: "Banco de Lage Landen Brasil S.A." },
  { id: '024' , name: "Banco de Pernambuco S.A. - BANDEPE" },
  { id: '456' , name: "Banco de Tokyo-Mitsubishi UFJ Brasil S.A." },
  { id: '214' , name: "Banco Dibens S.A." },
  { id: '047' , name: "Banco do Estado de Sergipe S.A." },
  { id: '037' , name: "Banco do Estado do Pará S.A." },
  { id: '041' , name: "Banco do Estado do Rio Grande do Sul S.A." },
  { id: '004' , name: "Banco do Nordeste do Brasil S.A." },
  { id: '265' , name: "Banco Fator S.A." },
  { id: 'M03' , name: "Banco Fiat S.A." },
  { id: '224' , name: "Banco Fibra S.A." },
  { id: '626' , name: "Banco Ficsa S.A." },
  { id: '394' , name: "Banco Finasa BMC S.A." },
  { id: 'M18' , name: "Banco Ford S.A." },
  { id: '233' , name: "Banco GE Capital S.A." },
  { id: '734' , name: "Banco Gerdau S.A." },
  { id: 'M07' , name: "Banco GMAC S.A." },
  { id: '612' , name: "Banco Guanabara S.A." },
  { id: 'M22' , name: "Banco Honda S.A." },
  { id: '063' , name: "Banco Ibi S.A. Banco Múltiplo" },
  { id: 'M11' , name: "Banco IBM S.A." },
  { id: '604' , name: "Banco Industrial do Brasil S.A." },
  { id: '320' , name: "Banco Industrial e Comercial S.A." },
  { id: '653' , name: "Banco Indusval S.A." },
  { id: '630' , name: "Banco Intercap S.A." },
  { id: '249' , name: "Banco Investcred Unibanco S.A." },
  { id: '184' , name: "Banco Itaú BBA S.A." },
  { id: '479' , name: "Banco ItaúBank S.A" },
  { id: 'M09' , name: "Banco Itaucred Financiamentos S.A." },
  { id: '376' , name: "Banco J. P. Morgan S.A." },
  { id: '074' , name: "Banco J. Safra S.A." },
  { id: '217' , name: "Banco John Deere S.A." },
  { id: '065' , name: "Banco Lemon S.A." },
  { id: '600' , name: "Banco Luso Brasileiro S.A." },
  { id: '755' , name: "Banco Merrill Lynch de Investimentos S.A." },
  { id: '746' , name: "Banco Modal S.A." },
  { id: '151' , name: "Banco Nossa Caixa S.A." },
  { id: '045' , name: "Banco Opportunity S.A." },
  { id: '623' , name: "Banco Panamericano S.A." },
  { id: '611' , name: "Banco Paulista S.A." },
  { id: '643' , name: "Banco Pine S.A." },
  { id: '638' , name: "Banco Prosper S.A." },
  { id: '747' , name: "Banco Rabobank International Brasil S.A." },
  { id: 'M16' , name: "Banco Rodobens S.A." },
  { id: '072' , name: "Banco Rural Mais S.A." },
  { id: '250' , name: "Banco Schahin S.A." },
  { id: '749' , name: "Banco Simples S.A." },
  { id: '366' , name: "Banco Société Générale Brasil S.A." },
  { id: '637' , name: "Banco Sofisa S.A." },
  { id: '464' , name: "Banco Sumitomo Mitsui Brasileiro S.A." },  
  { id: 'M20' , name: "Banco Toyota do Brasil S.A." },
  { id: '634' , name: "Banco Triângulo S.A." },
  { id: '208' , name: "Banco UBS Pactual S.A." },
  { id: 'M14' , name: "Banco Volkswagen S.A." },
  { id: '655' , name: "Banco Votorantim S.A." },
  { id: '610' , name: "Banco VR S.A." },
  { id: '370' , name: "Banco WestLB do Brasil S.A." },
  { id: '021' , name: "BANESTES S.A. Banco do Estado do Espírito Santo" },
  { id: '719' , name: "Banif-Banco Internacional do Funchal (Brasil)S.A." },
  { id: '073' , name: "BB Banco Popular do Brasil S.A." },
  { id: '078' , name: "BES Investimento do Brasil S.A.-Banco de Investimento" },
  { id: '069' , name: "BPN Brasil Banco Múltiplo S.A." },
  { id: '070' , name: "BRB - Banco de Brasília S.A." },
  { id: '477' , name: "Citibank N.A." },  
  { id: '487' , name: "Deutsche Bank S.A. - Banco Alemão" },
  { id: '751' , name: "Dresdner Bank Brasil S.A. - Banco Múltiplo" },
  { id: '062' , name: "Hipercard Banco Múltiplo S.A." },
  { id: '492' , name: "ING Bank N.V." },
  { id: '488' , name: "JPMorgan Chase Bank" },
  { id: '409' , name: "UNIBANCO - União de Bancos Brasileiros S.A." },
  { id: '230' , name: "Unicard Banco Múltiplo S.A." }
]
 