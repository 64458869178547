
export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

import DocumentData from '@/components/contracts/DocumentData.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';
import ActionModal from '@/components/Modals/ModalApprove.vue';
import { AuthService } from '@/services/authService';
import AdditiveById from '@/types/additiveById';
import User from '@/types/auth/user';
import ContractFileToShow, { contractFileToShow } from '@/types/contractFileToShow';
import ContractByid, { Documents } from '@/types/contractsById';
import EditAdditivePayload from '@/types/EditAdditivePayload';
import EditContractPayload from '@/types/EditContractPayload';
import RemoveDocumentPayload from '@/types/removeDocumentPayload';
import VendorResponse from '@/types/response/response';
import Selects, { selectsInitialValue } from '@/types/selects';
import Endpoint from '@/utils/endpoint';
import Rules from '@/utils/rules';
import moment from 'moment';
import { Money } from 'v-money';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CurrencyData, { currencyFormater } from '@/utils/currencyData';

@Component({
  components: {
    DocumentData,
    FileDisplay,
    ActionModal,
    Money,
  },
})
export default class EditingModal extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Prop(Boolean) public showModalEdit!: boolean;
  @Prop(Boolean) public editingContract!: boolean;
  @Prop(Boolean) hasError!: boolean;
  @Prop(Boolean) public isAdditive!: boolean;
  @Prop(Object) public contractData!: ContractByid;
  @Prop(Object) public additiveData!: AdditiveById;
  @Prop(Array) public initialCompanies!: Selects[];
  @Prop(Array) public managers!: Selects[];
  @Prop(Array) public vendors!: Selects[];
  @Prop(Array) public jurisdictions!: Selects[];
  @Prop(Array) public paymentMethods!: Selects[];
  @Prop(String) textError!: string;
  initializing = true;
  @Watch('editContract.managerSelected')
  async refreshOwnerCompanies(){
    if (this.initializing)
      return;
    if (this.editContract.managerSelected.id !== selectsInitialValue.id) {
      await this.getCompaniesForOwner(this.editContract.managerSelected.email);
      this.editContract.lesteCompanySelected = this.getCompanyByCompanyId(this.contractData.companyId);
    }
  }

  @Watch('showModalEdit', { immediate: true })
  setFieldValues() {
    this.initializing = true;
    if (this.showModalEdit) {
      if (this.isAdditive){
        this.initializeAdditiveForEditing()
      }
      else {
        this.initializeContractForEditing()
      }
    }

    const contractDocument = this.getDocumentsDataByType('Contract') as Documents;
    const additionalDocuments = this.getDocumentsDataByType('Additional') as Documents[];

    if (contractDocument !== undefined) {
      this.contractFilesToDisplay = {
        name: contractDocument.fileName,
        lastModifiedDate: contractDocument.createdAt,
        documentId: contractDocument.id,
      };
    }

    this.additionalFilesToDisplay = [];

    if (additionalDocuments !== undefined) {
      additionalDocuments.forEach((item) =>
        this.additionalFilesToDisplay.push({
          name: item.fileName,
          lastModifiedDate: item.createdAt,
          documentId: item.id,
        })
      );
    }
    this.initializing = false;
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  setPaymentType(paymentTypeId: number) {
    const paymentType = this.paymentMethods.find((item) => item.id === paymentTypeId) as Selects;
    return paymentType;
  }

  setVendor(vendorEntityId: string) {
    const vendor = this.vendors.find((item) => item.entityId === vendorEntityId) as Selects;
    return vendor;
  }

  getDocumentsDataByType(type: string) {
    const documents: Documents[] = this.isAdditive ? this.additiveData.documents : this.contractData.documents;
    if (type === 'Contract')
      return documents.find((document) => document.type === type);
    return documents.filter((document) => document.type === type);
  }

  get companies() {
    if (this.initializing){
      return this.initialCompanies;
    } else {
      return this.ownerCompanies;
    }
  }

  get contractDataDisplayControl() {
    if (this.contractFilesToDisplay.name !== '') {
      return this.contractFilesToDisplay;
    }
    return this.files;
  }

  setAdditionalFilesToDisplay(file: File) {
    this.additionalFilesToDisplay.push({
      name: file.name,
      lastModifiedDate: file.lastModified,
      documentId: null,
    });
  }

  initializeAdditiveForEditing(): void {
    this.editAdditive = {
      id: this.additiveData.id,
      title: this.additiveData.title,
      additiveValue: this.additiveData.additiveValue,
      description: this.additiveData.description,
      deadlineAdditive: this.additiveData.deadlineAdditive,
      status: ''
    }
  }

  initializeContractForEditing(): void {
    this.editContract = {
      name: this.contractData.title,
      executionDeadline: this.contractData.deadlineContract,
      managerSelected: this.getManagerByEmail(this.contractData.lesteUserEmail),
      lesteCompanySelected: this.getCompanyByCompanyId(this.contractData.companyId),
      value: this.contractData.contractValue,
      scope: this.contractData.description,
      paymentTypeSelected: this.setPaymentType(this.contractData.paymentMethodId),
      bank: '',
      agencyNumber: '',
      account: '',
      accountDigit: '',
      vendorEmail: this.user.email,
      entityVendorId: this.setVendor(this.contractData.vendor.entityId),
      currency: this.contractData.currency,
      financeCanManage: this.contractData.financeCanManage,
      approversId: this.contractData.approvers.map(a => a.id as number)
    };
  }

  setDocumentsWillBeRemoved(documentId: string | null) {
    if (documentId) this.listDocumentsToRemove.push(documentId);
  }
  requiredId = [(v: Selects) => v.id !== 0 || 'Required Field'];
  requiredEmail = [(v: any) => v.email !== '' || 'Required Field'];
  loadingOwnerCompanies = false;
  tab = null;
  addAlocation = false;
  addContractSign = false;
  isDraggingContract = false;
  uploadContractInput = 0;
  files: Blob = new Blob();
  isDraggingAdditional = false;
  uploadAdditionalInput = 0;
  additionalFiles: File[] = [];
  ownerCompanies: Selects[] = [];
  contractFilesToDisplay: ContractFileToShow = contractFileToShow;
  additionalFilesToDisplay: ContractFileToShow[] = [];
  authService = new AuthService();
  endpoint = new Endpoint();
  listDocumentsToRemove: string[] = [];

  picker = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);

  showExecutionDeadline = false;
  currencyData = CurrencyData;
  currencyFormater = currencyFormater;
  editContract = {
    name: '',
    executionDeadline: '',
    lesteCompanySelected: selectsInitialValue || undefined,
    managerSelected: selectsInitialValue,
    value: 0,
    scope: '',
    paymentTypeSelected: selectsInitialValue,
    bank: '',
    agencyNumber: '',
    account: '',
    accountDigit: '',
    vendorEmail: '',
    entityVendorId: selectsInitialValue,
    currency: '',
    financeCanManage: false,
    approversId: [] as number[]
  };

  editAdditive = {
    id: '',
    title: '',
    additiveValue: 0,
    description: '',
    deadlineAdditive: '',
    status: ''
  }

  dolar = {
    decimal: '.',
    thousands: ',',
    precision: 2,
  };

  rules = new Rules();

  public requiresBankData(): boolean {
    return this.editContract.paymentTypeSelected.id !== 28 && this.editContract.paymentTypeSelected.id !== 33
  }

  public handleFormatDate(date: string): string {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }
  public async getCompaniesForOwner(ownerEmail: string | undefined): Promise<void> {
    this.loadingOwnerCompanies = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get<VendorResponse<Selects[]>>(
        this.endpoint.selects.companies + `?ownerEmail=${ownerEmail}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (!data || data.length === 0) {
        return;
      }
      this.ownerCompanies = data;
    } catch (error) {
      this.ownerCompanies = [];
    } finally {
      this.loadingOwnerCompanies = false;
    }
  }

  getCompanyByCompanyId(companyId: number) {
    const company = this.companies.find((company) => company.id === companyId);
    if (company !== undefined) return company;
    return { name: '', id: '' };
  }

  getManagerByEmail(managerEmail: string) {
    const manager = this.managers.find((manager) => manager.email?.toLowerCase() === managerEmail.toLowerCase());
    if (manager !== undefined)
      return manager;
    return { name: '', email: '' };
  }

  setContractFile() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractFile as any).files[0];
    console.log("SET CONTRACT FILE", this.files);
    const file = this.files as File;
    this.contractFilesToDisplay = {
      name: file.name,
      lastModifiedDate: new Date(file.lastModified).toISOString(),
      documentId: null,
    };
  }

  contractDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  contractDragleave() {
    this.isDraggingContract = false;
  }

  dropContractFile(e: any) {
    e.preventDefault();
    (this.$refs.contractFile as any).files = e.dataTransfer.files;
    this.setContractFile();
    this.isDraggingContract = false;
  }

  setAdditionalFile() {
    this.uploadAdditionalInput++;
    const additionalFiles = (this.$refs.additionalFile as any).files as FileList;
    for (let [key, value] of Object.entries(additionalFiles)) {
      this.additionalFiles.push(value);
      this.setAdditionalFilesToDisplay(value);
    }
  }

  additionalDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  additionalDragleave() {
    this.isDraggingContract = false;
  }

  dropAdditionalFile(e: any) {
    e.preventDefault();
    (this.$refs.additionalFile as any).files = e.dataTransfer.files;
    this.setAdditionalFile();
    this.isDraggingContract = false;
  }

  // fazer o mesmo esquema de additiveFiles pra contractFiles (um pra exibição e um igual so pra salvar o Blob)
  removeFile(payload: RemoveDocumentPayload) {
    this.setDocumentsWillBeRemoved(payload.documentId);

    if (payload.key !== null) {
      this.additionalFiles.splice(payload.key, 1);
      this.additionalFilesToDisplay.splice(payload.key, 1);

      return;
    }

    if (this.files.size !== 0) {
      this.files = new Blob();
      return;
    }

    if (this.contractFilesToDisplay.name !== '') {
      this.contractFilesToDisplay = contractFileToShow;
      return;
    }
  }

  clearFields() {
    this.initializing = true;
    this.editContract = {
      name: '',
      executionDeadline: '',
      lesteCompanySelected: selectsInitialValue || undefined,
      managerSelected: selectsInitialValue,
      value: 0,
      scope: '',
      paymentTypeSelected: selectsInitialValue,
      bank: '',
      agencyNumber: '',
      account: '',
      accountDigit: '',
      vendorEmail: this.user.email,
      entityVendorId: selectsInitialValue,
      currency: '',
      financeCanManage: false,
      approversId: []
    };
    this.tab = null;
    this.files = new Blob();
    this.contractFilesToDisplay = contractFileToShow;
    this.additionalFiles = [];
    this.additionalFilesToDisplay = [];
  }

  testandoValor() {
    this.editContract.value = 10.0;
  }

  closeModal() {
    this.$emit('closeModal');
    this.clearFields();
  }

  submitAdditiveEdited() {
    console.log("SUBMIT ADDITIVE EDITED");
    const params: EditAdditivePayload = {
      id: this.additiveData.id,
      title: this.editAdditive.title,
      additiveValue: this.editAdditive.additiveValue,
      description: this.editAdditive.description,
      deadlineAdditive: this.editAdditive.deadlineAdditive,
      additiveFileContract: this.files.size !== 0 ? this.files : undefined,
      ...(this.additionalFiles.length > 0 && {
        documents: this.additionalFiles,
      }),
      ...(this.listDocumentsToRemove.length > 0 && {
        listDocumentsToRemove: this.listDocumentsToRemove,
      }),
    }
    console.log("EMITTING PARAMS ",params);
    this.$emit('handleEditAdditive', params);
  }

  submitContractEdited() {
    const params: EditContractPayload = {
      id: this.contractData.id,
      title: this.editContract.name,
      approverId: 0,
      companyId: this.editContract.lesteCompanySelected.id as string,
      contractNumber: '0',
      deadlineContract: this.editContract.executionDeadline,
      contractValue: this.editContract.value,
      description: this.editContract.scope,
      managementContractEmail: this.editContract.managerSelected.email as string,
      ...(this.files.size !== 0 && {
        contractFile: this.files,
      }),
      paymentMethodId: this.editContract.paymentTypeSelected.id as string,
      status: 'Open',
      ...(this.additionalFiles.length > 0 && {
        documents: this.additionalFiles,
      }),
      ...(this.listDocumentsToRemove.length > 0 && {
        listDocumentsToRemove: this.listDocumentsToRemove,
      }),
      VendorEmail: this.isVendor
        ? this.editContract.vendorEmail
        : (this.editContract.entityVendorId.entityId as string),
      Currency: this.editContract.currency,
      FinanceCanManage: this.editContract.financeCanManage
    };

    this.$emit('handleEditContract', params);
  }
}
