
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { Action, Getter } from 'vuex-class';

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import GoBack from '@/utils/goBackPage';
import Rules from '@/utils/rules';

import Feedback from '@/types/general/feedback';
import OnboardingContacts from '@/types/onboardingContacts';
import Selects, { selectsInitialValue } from '@/types/selects';
import { setErrorText } from '@/utils/errorHandler';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({
  directives: { mask },
})
export default class NewOnboarding extends Vue {
  @Getter('getFeedback', { namespace: 'modals' })
  public feedback!: Feedback;
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Prop(String) public token!: string | null;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  loading = false;
  companyName = '';
  companyType = null;
  companyCnpj = '';
  companyEIN = '';
  public contactRelationClassification: Selects[] = [];
  public contactRelationClassificationSelected: Selects = selectsInitialValue;

  onboardingContacts: OnboardingContacts[] = [];

  private endpoint = new Endpoint();
  authService = new AuthService();
  goBack = GoBack;
  rules = new Rules();

  @Watch('$refs.form')
  public enableSubmitButton() {
    if (
      this.companyName !== '' &&
      (this.companyCnpj !== '' || this.companyEIN !== '') &&
      this.onboardingContacts[0].name !== '' &&
      this.onboardingContacts[0].document !== '' &&
      this.onboardingContacts[0].email !== ''
    ) {
      return false;
    }
    return true;
  }

  public handleContactTypeItems() {
    const mainPointOfContact = this.contactRelationClassification.find(
      (item) => item.id === '715be7b4-463c-43d3-b30b-cc57a4141cb0'
    );
    if (!mainPointOfContact) return;
    mainPointOfContact['disabled'] = true;
  }

  public newContact(): void {
    this.onboardingContacts.push(new OnboardingContacts());
  }

  public removeContact(contact: OnboardingContacts): void {
    this.onboardingContacts.splice(this.onboardingContacts.indexOf(contact), 1);
  }

  public getContactPosition(item: OnboardingContacts): string {
    return (this.onboardingContacts.indexOf(item) + 1).toString();
  }

  public setFirstContactType() {
    this.onboardingContacts[0].contactRelationClassificationSelected = {
      name: 'MAIN POINT OF CONTACT',
      id: '715be7b4-463c-43d3-b30b-cc57a4141cb0',
    };
  }

  public async getRelationClassifications() {
    const token = await this.authService.getIdToken();

    try {
      const relationClassification = await this.$http.get(
        this.endpoint.selects.relationClassifications,
        {
          headers: { Authorization: `${token}` },
        }
      );
      this.contactRelationClassification = relationClassification.data;
      this.handleContactTypeItems();
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        isError: true,
        text: "Failed to get relation classifications.",
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  public async newOnboarding() {
    if (!this.form.validate()) return;
    const token = await this.authService.getIdToken();

    this.loading = true;
    const params = {
      companyName: this.companyName,
      onShore: this.companyType === 'onShore' ? true : false,
      identifier: this.companyType === 'onShore' ? this.companyCnpj : this.companyEIN,
      formOrganizationId: '71FB6F9C-11D9-4BFC-93D9-EFC89CC13070',
      jurisdictionId: '9C24A7F0-052D-445D-9947-1F9DE5F451B1',
      contacts: this.onboardingContacts.map((item) => {
        return {
          name: item.name,
          email: item.email,
          phone: item.phone,
          documentType: '175E8B5F-89D7-42C3-A978-C625E3203442',
          individualEntityRelationClassification: item.contactRelationClassificationSelected?.id,
        };
      }),
    };
    try {
      await this.$http.post(this.endpoint.onboarding.base, params, {
        headers: { Authorization: `${token}` },
      });

      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'Onboarding saved',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      this.goBack();
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        isError: true,
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      // console.log('Ocorreu um erro ao criar onboarding');
    }
    this.loading = false;
  }

  public async mounted(): Promise<void> {
    this.newContact();
    await this.getRelationClassifications();
    this.setFirstContactType();
  }
}
