
import { Component, Prop, Vue } from 'vue-property-decorator';

import SkeletonLoader from '@/components/SkeletonLoader.vue';

import ContractDetail from '@/types/contractDetail';

import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';

@Component({ components: { SkeletonLoader } })
export default class ContractData extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(Boolean) gettingContract!: boolean;
  @Prop(String) type!: string;

  formatDate = FormatDate;
  formatMoney = FormatMoney;
}
