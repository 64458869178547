function formatMoney(valueToFormat: number, currency?: string): string {
  if(!currency){
    const formater = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formater.format(valueToFormat);    
  }
  let formater;
  switch (currency) {
    case "BRL":
      formater = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      break;
    case "EUR":
      formater = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
      break;
    case "GBP":
      formater = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });
      break;
    case "USD":
    default:
      formater = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      break;
  }
  return formater.format(valueToFormat);
  
}

export default formatMoney;
