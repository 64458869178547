import Vue from 'vue';
import Vuex from 'vuex';

import modals from '../vuex/modules/modals';
import permissions from '@/vuex/modules/permissions';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    modals,
    permissions,
  },
});
