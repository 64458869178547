import { ShieldResponse, UserGraph } from '@/types/shield/responses';
import Endpoint from '@/utils/endpoint';
import axios from 'axios';

const shieldAPI = process.env.VUE_APP_URL_SHIELD_API;
const endpoint = new Endpoint();

export async function getUserPhotoFromShield(id: string) {
  const url = `${shieldAPI}/user/${id}/photo`

  const headers = { lesteKey: endpoint.auth.lesteKey! }

  const { data } = await axios.get(url, {
    headers,
    responseType: 'arraybuffer'
  })

  return typeof window !== 'undefined'
    ? window.btoa(
        new Uint8Array(data).reduce(
          (data, byte) => `${data}${String.fromCharCode(byte)}`,
          ''
        )
      )
    : ''
}

export async function getUserPhotoFromShieldByEmail(email: string): Promise<string> {
  const headers = { lesteKey: endpoint.auth.lesteKey! }
  const response = await axios.get(`${shieldAPI}/GetUserByEmail/${email}`, {
    headers
  });
  const user = (response.data as ShieldResponse<UserGraph>).data;
  if (user === null)
    return '';    
  const url = `${shieldAPI}/user/${user.id}/photo`;
  const { data } = await axios.get(url, {
    headers,
    responseType: 'arraybuffer'
  })

  return typeof window !== 'undefined'
    ? window.btoa(
        new Uint8Array(data).reduce(
          (data, byte) => `${data}${String.fromCharCode(byte)}`,
          ''
        )
      )
    : ''
}

export const DefaultPhoto: string = 'https://i.pinimg.com/originals/ff/a0/9a/ffa09aec412db3f54deadf1b3781de2a.png';
