import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';
import Onboarding from '../views/Onboarding.vue';
import ListContracts from '../views/contracts/ListContracts.vue';
import ListPayments from '../views/payments/ListPayments.vue';
import ViewContract from '../views/contracts/ViewContract.vue';
import NewOnboarding from '../views/NewOnboarding.vue';
import ViewOnboarding from '../views/ViewOnboarding.vue';
import ViewOnboardingVendor from '../views/ViewOnboardingVendor.vue';
import Users from '../views/Users.vue';

// import RouteRedirect from '@/utils/routeRedirect';

// const routeRedirect = new RouteRedirect();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Onboarding,
    props: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    props: true,
  },
  {
    path: '/onboarding/new',
    name: 'NewOnboarding',
    component: NewOnboarding,
    props: true,
  },
  {
    path: '/onboarding/:id',
    name: 'ViewOnboarding',
    component: ViewOnboarding,
    props: true,
  },
  {
    path: '/onboarding/vendor/:id',
    name: 'ViewOnboardingVendor',
    component: ViewOnboardingVendor,
    props: true,
  },
  {
    path: '/onboarding/edit/:id',
    name: 'EditOnboarding',
    component: ViewOnboardingVendor,
    props: true,
  },
  {
    path: '/contracts',
    name: 'ListContracts',
    component: ListContracts,
    props: true,
  },
  {
    path: '/contract/:id',
    name: 'ViewContract',
    component: ViewContract,
    props: true,
  },
  {
    path: '/payments',
    name: 'ListPayments',
    component: ListPayments,
    props: true,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
