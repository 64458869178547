export enum ContractStatusTypes {
  OWNER_REVIEW =  'Owner Review',      
  FINANCE_REVIEW =  'Finance Review',
  CANCELED = 'Canceled',
  OPEN = 'Open',
  INTERNAL_PROCESS = 'Internal Process',
  BLOCKED = 'Blocked',
  CLOSED = 'Closed',
  DIGITAL_SIGNATURE = 'Digital Signature',
}

export const CONTRACT_STATUS = ContractStatusTypes;