export default interface DocumentsPayload {
  id: string;
  contractId: string;
  fileName: string;
  sharepointPath: string;
  fileExtension: string;
  type: string;
  createdAt: string;
}

const documentPayloadInitialValue: DocumentsPayload = {
  id: '',
  contractId: '',
  fileName: '',
  sharepointPath: '',
  fileExtension: '',
  type: '',
  createdAt: '',
};

export { documentPayloadInitialValue };
