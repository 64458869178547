export default interface DocumentType {
  id: number;
  name: string;
  dtEntry: string;
  monthsToExpire: number;
  requiredForOnShore: boolean;
  requiredForOffShore: boolean;
  isBlocked: boolean;
}

const documentTypeInitialValue: DocumentType = {
  id: 0,
  name: '',
  dtEntry: '',
  monthsToExpire: 0,
  requiredForOnShore: false,
  requiredForOffShore: false,
  isBlocked: false,
};

export { documentTypeInitialValue };
