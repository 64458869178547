
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalDeleteSubTask extends Vue {
  //   @Getter('getShowModalDelete', { namespace: 'entity' })
  //   showModalDelete!: boolean;
  //   @Action('setShowModalDelete', { namespace: 'entity' })
  //   setShowModalDelete!: any;
  //   @Getter('getSubTaskKey', { namespace: 'checklist' })
  //   subTaskKey!: boolean;
  //   @Action('setSubTaskKey', { namespace: 'checklist' })
  //   setSubTaskKey!: any;
  //   @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  //   checklistEntityStageKey!: string;

  @Prop(Boolean) readonly showSendModal!: boolean;
  @Prop(Boolean) readonly showSendField!: boolean;
  @Prop(String) readonly feedbackText!: string;
  @Prop(String) readonly btnText!: string;

  //   @Watch('loading')
  //   handleReloadSubtaskData() {
  //     if (this.loading === false) {
  //       this.$emit('reloadSubtaskData');
  //     }
  //   }
  //   removeEntity() {
  //     this.$emit('removeEntity');
  //   }

  closeModal() {
    this.$emit('closeModal');
  }
}
