
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import ContractByid from '@/types/contractsById';
import PaymentsById from '@/types/paymentById';
import PaymentFlow from '@/types/PaymentFlow';

@Component({})
export default class ModalApprovePayment extends Vue {
  @Prop(Boolean) readonly showApprovePaymentModal!: boolean;
  @Prop(Boolean) readonly modalReprove!: boolean;
  @Prop(Boolean) readonly approvingPayment!: boolean;
  @Prop(Object) contractById!: ContractByid;
  @Prop(Object) paymentById!: PaymentsById;

  @Watch('showApprovePaymentModal')
  handleClearReproveField() {
    if (this.showApprovePaymentModal === false) {
      this.approvePaymentField = '';
    }
  }

  approvePaymentField = '';
  isAdditivePayment = false; 
  handleApprove() {
    if (this.modalReprove) {
      this.reprovePayment();
    } else {
      this.approvePayment();
    }
  }

  approvePayment() {
    const paymentFlow: PaymentFlow = {
      feedback: this.approvePaymentField,
      isRequestAdditive: this.isAdditivePayment,
    }    
    this.$emit('approvePayment', paymentFlow);
  }

  reprovePayment() {
    this.$emit('reprovePayment', this.approvePaymentField);
  }

  closeModal() {

    this.$emit('closeModal');
  }
}
