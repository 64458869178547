
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Selects from '@/types/selects';
import FormatDate from '@/utils/formatDate';

import ContractDetail, { contractDetailInitialValue } from '@/types/contractDetail';

@Component({})
export default class PaymentData extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(Array) companies!: Selects[];
  formatDate = FormatDate;

  headers = [
    {
      text: 'Payment title',
      align: 'start',
      sortable: true,
      value: 'title',
    },
    {
      text: 'Leste Company',
      align: 'start',
      sortable: true,
      value: 'lesteCompany',
    },
    {
      text: 'Value',
      align: 'start',
      sortable: true,
      value: 'totalAmount',
    },
    {
      text: 'Deadline',
      align: 'start',
      sortable: true,
      value: 'expirationDate',
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'statusTitle',
    },
  ];

  public setStatusClass(status: string): string | undefined {
    if (status === 'Owner Review') return 'owner-review';
    if (status === 'Vendor Review') return 'vendor-review';
    if (status === 'Internal Process') return 'internal-proccess';
    if (status === 'Finance Review') return 'finance-review';
    if (status === 'Waiting Payment') return 'digital-signature';
    if (status === 'Waiting NF') return 'waiting-nf';
    if (status === 'Canceled') return 'canceled';
    if (status === 'Open') return 'open';
    if (status === 'Paid') return 'open';
    if (status === 'Processing') return 'open';
    if (status === 'Closed') return 'closed';
  }

  getCompanyByCompanyId(companyId: number) {
    const companyName = this.companies.find((company) => company.id === companyId)?.name;
    return companyName !== undefined ? companyName : '--';
  }
}
