
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import FinancialBudget from '@/types/financialBudget';
import CostCenter, { costCenterInitialValue } from '@/types/costCenter';
import CostCenterContract from '@/types/costCenterContract';
import ContractsById, { CostCenterAllocation } from '@/types/contractsById';

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import Alocation, { alocationInitialValue } from '@/types/alocation';
import { VListItemContent } from 'vuetify/lib';

@Component({})
export default class ModalNewAllocation extends Vue {
  @Prop(Boolean) readonly showNewAlocationModal!: boolean;
  @Prop(Boolean) readonly showSendField!: boolean;
  @Prop(Boolean) readonly hasError!: boolean;
  @Prop(Boolean) loadingCreateCostCenter!: boolean;
  @Prop(Boolean) isEdit!: boolean;
  @Prop(Number) indexAllocationWasEditing!: number | null;
  @Prop(String) readonly feedbackText!: string;
  @Prop(String) readonly btnText!: string;
  @Prop(Number) alocationPercentageValue!: number;
  @Prop(Object) contractsById!: ContractsById;
  @Prop(Array) currentAllocations!: CostCenterAllocation[];
  @Prop(Object) alocationItems!: Alocation;
  @Prop(String) textError!: string;

  @Prop(Array) costCenterData!: CostCenter[];
  @Prop(Array) financialBudgetData!: FinancialBudget[];

  @Watch('loadingCreateCostCenter')
  handleClearAlocation() {
    if (!this.loadingCreateCostCenter) {
      this.alocations = [];
    }
  }

  @Watch('alocations', { deep: true, immediate: true })
  handlePercentageIsFull() {
    const allocationsPercentageTotal = this.alocations
      .map((item) => item.percentage)
      .reduce((partialSum, a) => partialSum + a, 0);

    if (this.restPercentage === 100) {
      allocationsPercentageTotal >= 100
        ? (this.percentageIsFull = true)
        : (this.percentageIsFull = false);
      return;
    }

    if (this.alocationPercentageValue + allocationsPercentageTotal >= 100) {
      this.percentageIsFull = true;
      return;
    }

    this.percentageIsFull = false;
  }

  alocations: CostCenterContract[] = [];
  restPercentage = 100 - this.alocationPercentageValue;
  totalValuePercentage = 0;
  alocationPercentageTotalValue = this.alocationPercentageValue;
  percentageIsFull = false;

  allocationWhoisChanging = {} as CostCenterContract;
  allocationNewValue = {} as CostCenterContract;
  allocationOldValue = {} as CostCenterContract;
  allocationWhoisChangingFlag = '';

  alocationDados: Alocation = alocationInitialValue;

  financialBudgetsChangeds: number[] = [];
  costCenterChangeds: number[] = [];

  authService = new AuthService();
  private endpoint = new Endpoint();

  get allocationTitle() {
    if (this.isEdit) return 'Edit Allocation';
    return 'New Allocation';
  }

  created() {
    this.alocations = [];
    this.addNewAlocation();
    if (this.isEdit) {
      this.setAlocationItems(this.alocationItems);
    }
  }

  setAlocationItems(item: Alocation): void {
    if (item.id !== undefined || item.id !== null) {
      this.alocations.map((x) => {
        x.costCenterSelected = this.costCenterData.find(
          (x) => x.id === item.costCenterId
        ) as CostCenter;
        x.financialBudgeSelected = this.financialBudgetData.find(
          (x) => x.id === item.financialBudgetId
        ) as FinancialBudget;
        x.percentage = item.percentage;
      });
    }
  }

  disableFieldsIfCombinationAlredyExists(allocationData: CostCenterContract, flag: string) {
    this.allocationWhoisChanging = allocationData;
    this.allocationWhoisChangingFlag = flag;

    if (flag === 'Cost Center') this.blockFinancialBudgetsByCombination(allocationData);
    else this.blockCostCentersByCombination(allocationData);
  }

  get allocationWhoisChangingParsed() {
    return JSON.stringify(this.allocationWhoisChanging);
  }

  @Watch('allocationWhoisChangingParsed', { deep: true })
  testeeeAloc(newValue: string, oldValue: string) {
    const allocationNewValue = JSON.parse(newValue) as CostCenterContract;
    const allocationOldValue = JSON.parse(oldValue) as CostCenterContract;

    if (
      this.allocationWhoisChangingFlag === 'Financial Budget' &&
      allocationOldValue.financialBudgeSelected !== undefined &&
      allocationNewValue.financialBudgeSelected.id !== allocationOldValue.financialBudgeSelected.id
    ) {
      if (allocationNewValue.financialBudgeSelected.id !== 0) {
        if (this.financialBudgetsChangeds.includes(allocationOldValue.financialBudgeSelected.id)) {
          const newAllocationIndex = this.alocations.findIndex(
            (item) =>
              item.financialBudgeSelected.id === allocationNewValue.financialBudgeSelected.id
          );

          this.financialBudgetsChangeds[newAllocationIndex] =
            allocationNewValue.financialBudgeSelected.id;
        } else {
          this.financialBudgetsChangeds.push(allocationNewValue.financialBudgeSelected.id);
        }
      }

      this.alocations.map((alocation) =>
        alocation.financialBudgetData.filter((item) => {
          if (this.financialBudgetsChangeds.includes(item.id)) {
            item.disabled = true;
          } else {
            item.isExternal === true ? (item.disabled = true) : (item.disabled = false);
          }
        })
      );
    }

    if (
      this.allocationWhoisChangingFlag === 'Cost Center' &&
      allocationOldValue.costCenterSelected !== undefined &&
      allocationNewValue.costCenterSelected.id !== allocationOldValue.costCenterSelected.id
    ) {
      if (allocationNewValue.costCenterSelected.id !== 0) {
        if (this.costCenterChangeds.includes(allocationOldValue.costCenterSelected.id)) {
          const newAllocationIndex = this.alocations.findIndex(
            (item) => item.costCenterSelected.id === allocationNewValue.costCenterSelected.id
          );

          this.costCenterChangeds[newAllocationIndex] = allocationNewValue.costCenterSelected.id;
        } else {
          this.costCenterChangeds.push(allocationNewValue.costCenterSelected.id);
        }
      }

      this.alocations.map((alocation) =>
        alocation.costCenterData.filter((item) => {
          if (this.costCenterChangeds.includes(item.id)) {
            item.disabled = true;
          } else {
            item.isExternal === true ? (item.disabled = true) : (item.disabled = false);
          }
        })
      );
    }
  }

  blockFinancialBudgetsByCombination(newAllocationData: CostCenterContract) {
    const allocationsAlreadyCreated = this.currentAllocations.filter(
      (item) => item.costCenterId == newAllocationData.costCenterSelected.id
    );

    const allocationsCreated = this.alocations.filter(
      (item) => item.costCenterSelected.id == newAllocationData.costCenterSelected.id
    );

    newAllocationData.financialBudgetData = newAllocationData.financialBudgetData.map(
      (financialBudgetData) => ({
        ...financialBudgetData,
        disabled:
          allocationsAlreadyCreated
            .map((allocation) => allocation.financialBudgetId)
            .includes(financialBudgetData.id) ||
          allocationsCreated
            .map((allocation) => allocation.financialBudgeSelected.id)
            .includes(financialBudgetData.id),
        isExternal: allocationsAlreadyCreated
          .map((allocation) => allocation.financialBudgetId)
          .includes(financialBudgetData.id),
      })
    );
  }

  blockCostCentersByCombination(newAllocationData: CostCenterContract) {
    const allocationsAlreadyCreated = this.currentAllocations.filter(
      (item) => item.financialBudgetId == newAllocationData.financialBudgeSelected.id
    );

    const allocationsCreated = this.alocations.filter(
      (item) => item.financialBudgeSelected.id == newAllocationData.financialBudgeSelected.id
    );

    newAllocationData.costCenterData = newAllocationData.costCenterData.map((costCenterData) => ({
      ...costCenterData,
      disabled:
        allocationsAlreadyCreated
          .map((allocation) => allocation.costCenterId)
          .includes(costCenterData.id) ||
        allocationsCreated
          .map((allocation) => allocation.costCenterSelected.id)
          .includes(costCenterData.id),
      isExternal: allocationsAlreadyCreated
        .map((allocation) => allocation.costCenterId)
        .includes(costCenterData.id),
    }));
  }

  public addNewAlocation() {
    const newAlocation = new CostCenterContract(this.costCenterData, this.financialBudgetData);
    newAlocation.percentage = this.getPercentageValue();
    this.alocations.push(newAlocation);
  }

  limitOnlyNumber(evt: any) {
    const dot = 46;
    const comma = 44;
    const firstDigit = 48;
    const lastDigit = 57;

    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < firstDigit || charCode > lastDigit) && charCode !== dot) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  get setAllocationModalHeight() {
    if (this.alocations.length > 0 && this.alocations.length <= 2) return 'height: 480px';
    return 'height: 600px';
  }

  public getPercentageValue() {
    const totalValue = this.restPercentage;

    let totalPercentage = 0;
    this.alocations.forEach((item) => (totalPercentage += item.percentage));
    if (totalValue - totalPercentage <= 0) {
      return 0;
    }

    return totalValue - totalPercentage;
  }

  createCostCenters() {
    this.$emit('createCostCenters', this.contractsById.id, this.alocations);
  }

  editCostCenters() {
    this.alocations.map((item) => {
      this.alocationDados = {
        costCenterId: item.costCenterSelected.id,
        financialBudgetId: item.financialBudgeSelected.id,
        percentage: item.percentage,
      };
    });
    this.$emit('editCostCenters', this.alocationItems.id, this.alocationDados);
  }

  handleAlocation() {
    if (this.isEdit) {
      this.editCostCenters();
    } else {
      this.createCostCenters();
    }
  }

  setAllocationHeaderName(index: number) {
    const positiionNumber =
      this.indexAllocationWasEditing === null ? index + 1 : this.indexAllocationWasEditing + 1;

    return `Allocation ${positiionNumber}`;
  }

  removeAllocation(index: number) {
    this.alocations.splice(index, 1);
    this.alocations.map((alocation) =>
      alocation.financialBudgetData.find((item) => {
        if (item.id === this.financialBudgetsChangeds[index]) {
          item.disabled = false;
        }
      })
    );
    this.alocations.map((alocation) =>
      alocation.costCenterData.find((item) => {
        if (item.id === this.costCenterChangeds[index]) {
          item.disabled = false;
        }
      })
    );
    this.financialBudgetsChangeds.splice(index, 1);
    this.costCenterChangeds.splice(index, 1);
  }

  closeModal() {
    this.$emit('closeModal');
    this.alocations = [];
    this.costCenterChangeds = [];
    this.financialBudgetsChangeds = [];
  }
}
