
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalDeleteSubTask extends Vue {
  //   @Getter('getShowModalDelete', { namespace: 'entity' })
  //   showModalDelete!: boolean;
  //   @Action('setShowModalDelete', { namespace: 'entity' })
  //   setShowModalDelete!: any;
  //   @Getter('getSubTaskKey', { namespace: 'checklist' })
  //   subTaskKey!: boolean;
  //   @Action('setSubTaskKey', { namespace: 'checklist' })
  //   setSubTaskKey!: any;
  //   @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  //   checklistEntityStageKey!: string;

  @Prop(Boolean) readonly showApproveModal!: boolean;
  @Prop(Boolean) readonly approvingContract!: boolean;
  @Prop(Boolean) isAdditive!: boolean;
  @Prop(String) approveMember!: string;

  @Watch('showApproveModal')
  handleClearReproveField() {
    if (this.showApproveModal === false) {
      this.approveField = '';
    }
  }

  approveField = '';

  handleApprove() {
    if (this.isAdditive) {
      this.approveAditive();
    } else {
      this.approveContract();
    }
  }

  approveAditive() {
    this.$emit('approveAditive', this.approveField);
  }

  approveContract() {
    this.$emit('approveContract', this.approveField);
  }

  //   @Watch('loading')
  //   handleReloadSubtaskData() {
  //     if (this.loading === false) {
  //       this.$emit('reloadSubtaskData');
  //     }
  //   }
  //   removeEntity() {
  //     this.$emit('removeEntity');
  //   }

  closeModal() {
    this.$emit('closeModal');
  }
}
