
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class DisclaimerForm extends Vue {
  @Getter('getDisclaimerForm', { namespace: 'modals' }) public disclaimerForm!: boolean;
  @Action('setDisclaimerForm', { namespace: 'modals' }) public setDisclaimerForm!: any;

  public closeModal() {
    this.setDisclaimerForm(false);
  }
}
