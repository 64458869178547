import Selects from "./selects";

export default interface ContractsById {
  id: string;
  title: string;
  companyId: number;
  contractNumber: string;
  deadlineContract: string;
  contractValue: number;
  description: string;
  paymentMethodId: number;
  lesteUserEmail: string;
  vendorEmail: string;
  isAdditive: boolean;
  statusTitle: string;
  usedContractValue: number;
  restContractValue: number;
  onShore: null;
  address: null;
  approverId: number;
  secondApproverId: number;
  approverName: string;
  secondApproverName: string;
  createdAt: string;
  vendor: Vendor;
  documents: Documents[];
  payments: string[];
  additives: string[];
  permission: Permissions;
  feedBacks: FeedBack[];
  costCenterAllocations: CostCenterAllocation[];
  currency: string;
  financeCanManage: boolean;
  approvers: Selects[];
}

export interface Vendor {
  entityId: string;
  companyName: string;
  shortName: string;
  identifier: string;
  jurisdictionId: string;
  jurisdictionOfOperations: JurisdictionOfOperations[];
  activityTypes: JurisdictionOfOperations[];
  formOrganizationId: string;
}

export interface CostCenterAllocation {
  id: string;
  paymentDetailId: string;
  costCenterId: number;
  financialBudgetId: number;
  percentage: number;
}

export interface Permissions {
  userCanEdit: boolean;
  userCanCancel: boolean;
  userCanApprove: boolean;
  approveMember: string;
  userCanDenied: boolean;
  deniedMember: string;
  userCanCreateAllocation: boolean;
  userCanBlock: boolean;
  userCanCreateAdditive: boolean;
  userCanInsertSignedContract: boolean;
}

export interface Documents {
  contractId: string;
  createdAt: string;
  fileExtension: string;
  fileName: string;
  id: string;
  sharepointPath: string;
  type: string;
}

export interface JurisdictionOfOperations {
  id: string;
  name: string;
}

export interface FeedBack {
  email: string;
  occupation: string;
  content: string;
  name: string;
  createdDate: string;
}

const contractByIdInitialValue: ContractsById = {
  id: '',
  title: '',
  companyId: 0,
  contractNumber: '',
  deadlineContract: '',
  contractValue: 0,
  description: '',
  paymentMethodId: 0,
  lesteUserEmail: '',
  vendorEmail: '',
  isAdditive: false,
  statusTitle: '',
  usedContractValue: 0,
  restContractValue: 0,
  onShore: null,
  address: null,
  approverId: 0,
  createdAt: '',
  vendor: {
    entityId: '',
    companyName: '',
    shortName: '',
    identifier: '',
    jurisdictionId: '',
    jurisdictionOfOperations: [],
    activityTypes: [],
    formOrganizationId: '',
  },
  documents: [],
  payments: [],
  additives: [],
  costCenterAllocations: [],
  permission: {
    userCanEdit: false,
    userCanCancel: false,
    userCanApprove: false,
    approveMember: '',
    userCanDenied: false,
    deniedMember: '',
    userCanCreateAllocation: false,
    userCanBlock: false,
    userCanCreateAdditive: false,
    userCanInsertSignedContract: false,
  },
  feedBacks: [],
  approverName: '',
  secondApproverName: '',
  secondApproverId: 0,
  currency: '',
  financeCanManage: false,
  approvers: [],
};

export { contractByIdInitialValue };
