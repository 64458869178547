const currencies = ['GBP', 'BRL', 'EUR', 'USD'];

const currencyFormater = {
  real: {
    decimal: ',',
    thousands: '.',
    precision: 2,
  },

  dolar: {
    decimal: '.',
    thousands: ',',
    precision: 2,
  },

  libra: {
    decimal: '.',
    thousands: ',',
    precision: 2,
  },

  euro: {
    decimal: '.',
    thousands: ',',
    precision: 2,
  },
};

export default currencies;
export { currencyFormater };
