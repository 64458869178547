
export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

import moment from 'moment';
import { Money } from 'v-money';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Rules from '@/utils/rules';

import PaymentActivity from '@/types/PaymentActivity';
import User from '@/types/auth/user';
import EditPaymentPayload from '@/types/editPaymentPayload';
import PaymentsById, { Services } from '@/types/paymentById';
import Selects, { FintiApprover, FintiCompany } from '@/types/selects';

import { currencyFormater } from '@/utils/currencyData';

import ActionModal from '@/components/Modals/ModalApprove.vue';
import DocumentData from '@/components/contracts/DocumentData.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';
import ServiceResponse from '@/types/services/response';

@Component({
  components: {
    DocumentData,
    FileDisplay,
    ActionModal,
    Money,
  },
})
export default class EditPaymentModal extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Prop(Boolean) public editPaymentModal!: boolean;
  @Prop(Boolean) public editingPayment!: boolean;
  @Prop(Boolean) public hasError!: boolean;
  @Prop(Object) public paymentData!: PaymentsById;
  @Prop(Array) public requesterCompanies!: Selects[];
  @Prop(Number) public paymentTotalValue!: number;
  @Prop(String) textError!: string;

  @Watch('paymentActivities', { deep: true })
  verifyIfActivitesValueExceedPaymentTotalValue() {
    const activityTotalValue = this.getActivityTotalValue();

    if (activityTotalValue > this.paymentData.totalAmount) {
      this.activitiesValueExceededPaymentValue = true;
    } else {
      this.activitiesValueExceededPaymentValue = false;
    }
  }

  @Watch('editPaymentModal', { immediate: true })
  setFieldValues() {
    if (this.editPaymentModal) {
      this.editPayment = {
        paymentName: this.paymentData.title,
        paymentDescription: this.paymentData.description,
      };
    }

    this.setPaymentActivities(this.paymentData.services);
  }

  limitOnlyNumber(evt: any) {
    const dot = 46;
    const comma = 44;
    const firstDigit = 48;
    const lastDigit = 57;

    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < firstDigit || charCode > lastDigit) && charCode !== dot) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  setPaymentActivities(paymentActivities: ServiceResponse[]) {
    paymentActivities.forEach((payload) => {
      const paymentActivity: PaymentActivity = {
        id: payload.id,
        activity: payload.description,
        lesteRequester: this.findLesteRequesterById(payload.requesterEntityId ?? '') as Selects,
        grossValue: payload.total ?? 0,
        runtime: payload.runTime ?? '',
        hoursUsed: payload.hoursAmount ?? 0,
        payingCompanySelected: { id: 0, name: '' } as FintiCompany,
        approversSelected: payload.approvers.map((approver) => approver.id as number),
        approvalModeId: payload.approvalModeId,
      };

      this.paymentActivities.push(paymentActivity);
    });
  }

  findLesteRequesterById(id: string) {
    return this.requesterCompanies.find((company) => company.id === id);
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  currencyFormater = currencyFormater;

  tab = null;

  showExecutionDeadline = false;
  activitiesValueExceededPaymentValue = false;

  paymentActivities: PaymentActivity[] = [];

  servicesToRemove: string[] = [];

  editPayment = {
    paymentName: '',
    paymentDescription: '',
  };

  rules = new Rules();

  public handleFormatDate(date: string): string {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }

  getActivityTotalValue() {
    let activityTotalValue = 0;
    this.paymentActivities.forEach((activity) => (activityTotalValue += activity.grossValue));

    return activityTotalValue;
  }

  getActivitiesRestValue() {
    const activityTotalValue = this.getActivityTotalValue();

    if (this.paymentData.totalAmount - activityTotalValue <= 0) {
      return 0;
    }

    return this.paymentData.totalAmount - activityTotalValue;
  }

  addNewActivity() {
    const newActivity = new PaymentActivity();
    newActivity.grossValue = this.getActivitiesRestValue();

    this.paymentActivities.push(newActivity);
  }

  removeActivity(activity: PaymentActivity) {
    const activityPosition = this.paymentActivities.indexOf(activity);

    if (activity.id !== '') {
      this.servicesToRemove.push(activity.id as string);
    }

    this.paymentActivities.splice(activityPosition, 1);
  }

  setActivityHeaderName(index: number) {
    return `Activity ${index + 1}`;
  }

  clearFields() {
    this.editPayment = {
      paymentName: '',
      paymentDescription: '',
    };
    this.tab = null;
    this.paymentActivities = [];
  }

  closeModal() {
    this.$emit('closeModal');
    this.clearFields();
  }

  submitEditPayment() {
    const params: EditPaymentPayload = {
      title: this.editPayment.paymentName,
      description: this.editPayment.paymentDescription,
      services: this.paymentActivities.map((activity) => ({
        ...(activity.id !== '' && { id: activity.id as string }),
        title: activity.activity,
        requesterEntityId: activity.lesteRequester.id as string,
        buRequesterId: activity.lesteRequester.businessUnityId as string,
        grossTotal: activity.grossValue,
        runtime: activity.runtime,
        usedHours: activity.hoursUsed,
        paymentCompanyId: 0,
        approverId: 0,
      })),
      servicesToRemove: this.servicesToRemove,
    };
    this.$emit('handleEditPayment', params);
  }
}
