
import qs from 'qs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { Action, Getter } from 'vuex-class';

import AsyncComputedPlugin from 'vue-async-computed';
import AsyncComputed from 'vue-async-computed-decorator';

Vue.use(AsyncComputedPlugin);

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import FormatDate from '@/utils/formatDate';
import GoBack from '@/utils/goBackPage';
import SetOnboardingType from '@/utils/setOnboardingType';
import SetPendencyProfile from '@/utils/setPendencyProfile';
import SetStatusText from '@/utils/setStatusText';
import TruncateFile from '@/utils/truncateFile';

import User from '@/types/auth/user';
import BackDraft from '@/types/backDrafts';
import DocumentType from '@/types/documentType';
import Onboarding from '@/types/onboarding';
import History from '@/types/onboardingHistory';
import VendorResponse from '@/types/response/response';
import Selects from '@/types/selects';

// import BackDocumentsForm from '@/components/backDocuments/BackDocumentsCreate.vue';
import ModalApproveOnboarding from '@/components/onboardingsActions/ModalApproveOnboarding.vue';
import ModalBackDocuments from '@/components/onboardingsActions/ModalBackDocuments.vue';
import ModalDeny from '@/components/onboardingsActions/ModalDenyOnboarding.vue';
import ModalPendency from '@/components/onboardingsActions/ModalPendencyOnboarding.vue';
import VueSkeletonLoader from 'skeleton-loader-vue';

// const PENDING_VENDOR = 1;
// const REQUESTED = 2;
// const REVIEW_VENDOR = 3;
// const REVIEW_LESTE = 4;
// const APPROVED = 5;
// const DENIED = 6;

@Component({
  directives: { mask },
  components: {
    ModalPendency,
    ModalApproveOnboarding,
    ModalDeny,
    ModalBackDocuments,
    VueSkeletonLoader,
  },
})
export default class NewOnboarding extends Vue {
  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Action('setOnboardingId', { namespace: 'modals' }) public setOnboardingId!: any;
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Getter('getIsVendor', { namespace: 'modals' }) isVendor!: boolean;
  @Getter('getIsCollaborator', { namespace: 'modals' }) isCollaborator!: boolean;
  @Getter('getIsApprover', { namespace: 'modals' }) isApprover!: boolean;
  @Getter('getIsApproverMaster', { namespace: 'modals' }) isApproverMaster!: boolean;

  @Getter('getModalApproveState', { namespace: 'modals' }) public modalApprove!: boolean;
  @Action('setModalApproveState', { namespace: 'modals' }) public setModalApproveState!: any;

  @Getter('getModalApproveDeniedState', { namespace: 'modals' })
  public modalApproveDenied!: boolean;
  @Action('setModalApproveDeniedState', { namespace: 'modals' })
  public setModalApproveDeniedState!: any;

  @Getter('getModalDenyState', { namespace: 'modals' }) public modalDeny!: boolean;
  @Action('setModalDenyState', { namespace: 'modals' }) public setModalDenyState!: any;

  @Getter('getModalPendencyState', { namespace: 'modals' }) public modalPendency!: boolean;
  @Action('setModalPendencyState', { namespace: 'modals' }) public setModalPendencyState!: any;

  @Action('setModalBackDocuments', { namespace: 'modals' }) public setModalBackDocuments!: any;

  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;

  @Watch('reloadPage')
  public async handleReloadPage() {
    const token = await this.authService.getIdToken();
    this.getOnboardingById(token);
  }

  @Prop(String) public id!: string;
  @Prop(String) public token!: string | null;

  @AsyncComputed()
  async getCanApprove() {
    this.canApprove = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'Approve',
    });
  }

  @AsyncComputed()
  async getCanUpdate() {
    this.canUpdate = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'Update',
    });
  }

  @AsyncComputed()
  async getCanCreate() {
    this.canCreate = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'Create',
    });
  }

  @AsyncComputed()
  async getCanApproveDenied() {
    this.canApproveDenied = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'ApproveDenied',
    });
  }

  loading = false;
  reloadPage = false;
  showSkeleton = false;
  reloadBackModal = 0;
  backModalComponent = 'ModalBackDocuments';
  rounded = true;
  onboarding = {} as Onboarding;
  documentTypes: DocumentType[] = [];
  formOrganizations: Selects[] = [];
  companyActivities: Selects[] = [];
  mainActivityJurisdictions: Selects[] = [];
  contactRelationClassifications: Selects[] = [];
  cities: Selects[] = [];
  handleArchiveIsDownloaded = '';
  handleArchiveIsDeleting = '';

  backDraft: BackDraft[] = [];
  loadingBackDocuments = false;

  fileIsDeleting: number | null = null;
  itemIsDeleting = '';
  hasAttachmentDeleting = false;

  canApprove = false;
  canUpdate = false;
  canCreate = false;
  canApproveDenied = false;

  private endpoint = new Endpoint();
  authService = new AuthService();
  formatDate = FormatDate;
  setOnboardingType = SetOnboardingType;
  setPendencyProfile = SetPendencyProfile;
  setStatusText = SetStatusText;
  truncateFile = TruncateFile;
  goBack = GoBack;

  setResultDate(history: History[]) {
    if (this.onboarding.onboardingStatus == 5) {
      return this.formatDate(history.find((item) => item.onboardingStatus == 5)?.date);
    }
    if (this.onboarding.onboardingStatus == 6) {
      return this.formatDate(history.find((item) => item.onboardingStatus == 6)?.date);
    }
  }

  public contactType = [
    { type: 'DIRECTOR', value: '7EBDE364-287B-44A3-8DB2-EB0A9A135E8D' },
    { type: 'ASSOCIATED', value: 'E3225C78-7A58-4B97-BF0E-7BBA751F404D' },
  ];

  public async getOnboardingById(token: string | null): Promise<void> {
    this.showSkeleton = true;
    try {
      const onboardingData = await this.$http.get(this.endpoint.onboarding.base + `/${this.id}`, {
        headers: { Authorization: `${token}` },
      });
      this.onboarding = onboardingData.data;
    } catch (error) {
      console.log('Ocorreu um erro ao coletar dados');
    }
    this.showSkeleton = false;
  }

  public async getRelationClassifications(token: string | null) {
    try {
      const relationClassification = await this.$http.get(
        this.endpoint.selects.relationClassifications,
        {
          headers: { Authorization: `${token}` },
        }
      );
      this.contactRelationClassifications = relationClassification.data;
      this.handleContactTypeItems();
    } catch (error) {
      console.log('Ocorreu um erro ao listar relações de contatos');
    }
  }

  public handleContactTypeItems() {
    const mainPointOfContact = this.contactRelationClassifications.find(
      (item) => item.id === '715be7b4-463c-43d3-b30b-cc57a4141cb0'
    );
    if (!mainPointOfContact) return;
    mainPointOfContact['disabled'] = true;
  }

  public handleSetContactType(relationId: string | undefined): string {	
    if (!relationId)
      return '';
    const findRelation = this.contactRelationClassifications.find(
      (relation) => relation.id?.toString().toUpperCase() === relationId.toUpperCase()
    );
    if (!findRelation) return 'ASSOCIATED';
    return findRelation.name;
  }

  public setStatusClass(status: number): string | undefined {
    if (status === 1) return 'pending-vendor';
    if (status === 2) return 'requested';
    if (status === 3) return 'review-vendor';
    if (status === 4) return 'review-leste';
    if (status === 5) return 'approved';
    if (status === 6) return 'denied';
  }

  get setIdentifierLabel(): string {
    return this.onboarding.onShore ? 'CNPJ' : 'Alphanumeric Identifier';
  }

  public goToEdit(onboarding: Onboarding) {
    this.$router.push({ name: 'EditOnboarding', params: { id: onboarding.id } });
  }

  public async getFormOrganization(token: string | null) {
    try {
      const formOrganization = await this.$http.get(this.endpoint.selects.formOrganizations, {
        headers: { Authorization: `${token}` },
      });
      this.formOrganizations = formOrganization.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar tipos de empresa');
    }
  }

  public handleSetFormOrganization(formOrganizationId: string): string | undefined {
    return this.formOrganizations.find((item) => item.id === formOrganizationId)?.name;
  }

  public async getJuridisction(token: string | null): Promise<void> {
    try {
      const jurisdictions = await this.$http.get(this.endpoint.selects.jurisdictions, {
        headers: { Authorization: `${token}` },
      });
      this.mainActivityJurisdictions = jurisdictions.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar juridições');
    }
  }

  public handleSetJuridisction(jurisdictionId: string): string | undefined {
    return this.mainActivityJurisdictions.find((item) => item.id === jurisdictionId)?.name;
  }

  public handleSetOperatingJuridisction(operatingJuridisctions: string[]) {
    const activities = operatingJuridisctions.map((item) =>
      this.mainActivityJurisdictions.find(
        (operatingJuridisctions) => operatingJuridisctions.id === item
      )
    );
    return activities.map((item) => item?.name).join(', ');
  }

  public async getActivityTypes(token: string | null) {
    try {
      const activityTypes = await this.$http.get(this.endpoint.selects.activityTypes, {
        headers: { Authorization: `${token}` },
      });
      this.companyActivities = activityTypes.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar atividades da empresa');
    }
  }

  public handleSetCompanyActivities(companyActivities: string[]) {
    const activities = companyActivities.map((item) =>
      this.companyActivities.find((companyActivity) => companyActivity.id === item)
    );
    return activities.map((item) => item?.name).join(', ');
  }

  public async getFileDocumentType(token: string | null): Promise<void> {
    try {
      const documentTypes = await this.$http.get(this.endpoint.selects.fileDocumentType, {
        headers: { Authorization: `${token}` },
      });
      this.documentTypes = documentTypes.data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter os tipos de documentos');
    }
  }

  public handleSetDocumentType(documentId: number): string | undefined {
    return this.documentTypes.find((item) => item.id === documentId)?.name;
  }

  public handleDownloadFile(payload: BackDraft) {
    this.donwloadFile(payload.url, payload.fileName);
  }

  public async donwloadFile(fileUrl: string, fileName: string) {
    const token = await this.authService.getIdToken();
    this.handleArchiveIsDownloaded = fileName;
    try {
      const response = await this.$http.get(this.endpoint.files.download, {
        params: {
          fileUrl: fileUrl,
        },
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          'content-type': 'blob',
          Authorization: `${token}`,
        },
        responseType: 'blob',
      });
      this.handleArchiveIsDownloaded = '';
      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        const result: string | ArrayBuffer = reader.result ? reader.result : '';
        const downloadLink = document.createElement('a');
        downloadLink.href = result.toString();
        downloadLink.download = fileName;
        downloadLink.click();
      };
    } catch (error) {
      console.log('Ocorreu um erro ao gerar arquivo para download');
    }
  }

  public openApproveModal() {
    this.setOnboardingId(this.onboarding.id);
    this.onboarding.onboardingStatus === 6
      ? this.setModalDenyState(true)
      : this.setModalApproveState(true);
  }

  public async getCities(token: string | null): Promise<void> {
    try {
      const cities = await this.$http.get(this.endpoint.selects.cities, {
        headers: { Authorization: `${token}` },
      });
      this.cities = cities.data;
    } catch (error) {
      console.log('Ocorreu um erro ao listar cidades');
    }
  }

  public async approveOnboarding(payload: any) {
    const token = await this.authService.getIdToken();

    this.loading = true;
    this.showSkeleton = true;
    this.reloadPage = true;
    try {
      await this.$http.put(this.endpoint.onboarding.result, payload, {
        headers: { Authorization: `${token}` },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'Onboarding approved',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: '#ad1318',
        isError: true,
        text: 'An error has occurred',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.loading = false;
    this.showSkeleton = false;
    this.reloadPage = false;
  }

  public async approveDeniedOnboarding(payload: any) {
    const token = await this.authService.getIdToken();

    this.loading = true;
    this.showSkeleton = true;
    this.reloadPage = true;
    try {
      await this.$http.put(this.endpoint.onboarding.result, payload, {
        headers: { Authorization: `${token}` },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'Onboarding approved',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: '#ad1318',
        isError: true,
        text: 'An error has occurred',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      console.log('Ocorreu um erro ao aprovar este Onboarding');
    }
    this.loading = false;
    this.showSkeleton = false;
    this.reloadPage = false;
  }

  public openApproveDeniedModal() {
    this.setModalApproveDeniedState(true);
  }

  public openDenyModal() {
    this.setOnboardingId(this.onboarding.id);
    this.setModalDenyState(true);
  }

  public async denyOnboarding(payload: any) {
    const token = await this.authService.getIdToken();

    this.loading = true;
    this.showSkeleton = true;
    this.reloadPage = true;
    try {
      await this.$http.put(this.endpoint.onboarding.result, payload, {
        headers: { Authorization: `${token}` },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'Onboarding denied',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: '#ad1318',
        isError: true,
        text: 'An error has occurred',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      console.log('Ocorreu um erro ao negar este Onboarding');
    }
    this.loading = false;
    this.showSkeleton = false;
    this.reloadPage = false;
  }

  public openPendencyModal() {
    this.setOnboardingId(this.onboarding.id);
    this.setModalPendencyState(true);
  }

  public async putPendencyOnboarding(payload: any) {
    const token = await this.authService.getIdToken();

    this.loading = true;
    this.showSkeleton = true;
    this.reloadPage = true;
    try {
      await this.$http.put(this.endpoint.onboarding.pendency, payload, {
        headers: { Authorization: `${token}` },
      });
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'Pending set',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      this.setFeedback({
        feedback: true,
        color: '#ad1318',
        isError: true,
        text: 'Pending set',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      console.log('Ocorreu um erro ao definir pendencia para esse Onboarding');
    }
    this.loading = false;
    this.showSkeleton = false;
    this.reloadPage = false;
  }

  public openBackgroundDocumentsModal() {
    this.setModalBackDocuments(true);
  }

  public async handleFilterBackDocuments(payload: BackDraft[]) {
    const uploadBackDocument = payload.filter((item) => !item.hasDeleted && item.id === '');
    // const editBackDocument = payload.filter(
    //   (item) => item.hasChange && !item.hasDeleted && item.id
    // );

    if (uploadBackDocument.length === 0) return;

    this.loadingBackDocuments = true;

    const token = await this.authService.getIdToken();

    // await this.uploadSelectedBackDocuments(payload, token);
    // await this.editSelectedBackDocuments(editBackDocument, token);

    await this.getOnboardingById(token);

    this.loadingBackDocuments = false;
  }

  public async uploadSelectedBackDocuments(backToUpload: File[]) {
    const token = await this.authService.getIdToken();
    this.loadingBackDocuments = true;
    for (const backDocument of backToUpload) {
      try {
        const formData = new FormData();
        formData.append('OnboardingId', this.id);
        formData.append('File', backDocument);

        const response = await this.$http.post<VendorResponse<any>>(
          this.endpoint.files.backgroundCheck,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `${token}`,
            },
          }
        );
        if (!response.hasErrors) await this.getOnboardingById(token);
      } catch (error) {
        console.log('Ocorreu um erro ao enviar backDocuments');
      }
    }
    this.loadingBackDocuments = false;
  }

  public async editSelectedBackDocuments(backToEdit: BackDraft[], token: string | null) {
    for (const backEdit of backToEdit) {
      try {
        const formData = new FormData();
        formData.append('Id', backEdit.id);
        formData.append('OnboardingId', this.id);
        formData.append('DtExpiration', backEdit.expirationDate);
        formData.append('DocumentType', backEdit.documentTypeSelected.id.toString());
        formData.append('File', backEdit.attachment);
        formData.append('Note', backEdit.observation !== '' ? backEdit.observation : '');

        await this.$http.put(this.endpoint.files.backgroundCheck, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        });
      } catch (error) {
        console.log('Ocorreu um erro ao enviar backDocuments');
      }
    }
  }

  public removeBackDocument(item: BackDraft) {
    this.backDraft.splice(this.backDraft.indexOf(item), 1);
  }

  public async removeSelectedBackDocuments(backToDelete: BackDraft) {
    const token = await this.authService.getIdToken();

    this.hasAttachmentDeleting = true;
    this.backDraft.indexOf(backToDelete);
    this.handleArchiveIsDeleting = backToDelete.fileName;
    try {
      const formData = new FormData();
      formData.append('Id', backToDelete.id);
      const response = await this.$http.delete<VendorResponse<any>>(this.endpoint.files.backgroundCheck, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
        data: formData,
      });

      if (!response.hasErrors) await this.getOnboardingById(token);
    } catch (error) {
      console.log('Ocorreu um erro ao deletar arquivos');
    }
    this.hasAttachmentDeleting = false;
    this.handleArchiveIsDeleting = '';
  }

  public async mounted(): Promise<void> {
    const token = await this.authService.getIdToken();
    await this.getOnboardingById(token);
    this.getRelationClassifications(token);
    this.getFormOrganization(token);
    this.getJuridisction(token);
    this.getActivityTypes(token);
    this.getFileDocumentType(token);
    this.getCities(token);
  }

  beforeMount() {
    localStorage.setItem('route', this.$router.currentRoute.path);
  }
}
