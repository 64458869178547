import CostCenter, { costCenterInitialValue } from './costCenter';
import FinancialBudget, { financialBudgetInitialVlaue } from './financialBudget';

export default class CostCenterContract {
  costCenterSelected: CostCenter = costCenterInitialValue;
  financialBudgeSelected: FinancialBudget = financialBudgetInitialVlaue;
  percentage: number = 0;
  costCenterData: CostCenter[] = [];
  financialBudgetData: FinancialBudget[] = [];

  constructor(costCenterData: CostCenter[], financialBudgetData: FinancialBudget[]) {
    this.costCenterData = costCenterData;
    this.financialBudgetData = financialBudgetData;
  }
}
