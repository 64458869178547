import { PaymentStatusTypes, PAYMENT_STATUS } from '@/types/general/paymentStatus';

export default function setStatusClass(status: string): string | undefined {
  if (status === 'Owner Review') return 'owner-review';
  if (status === 'Vendor Review') return 'vendor-review';
  if (status === 'Internal Process') return 'internal-proccess';
  if (status === 'Line Manager Review') return 'line-manager-review';
  if (status === 'Finance Review') return 'finance-review';
  if (status === 'Waiting Payment') return 'digital-signature';
  if (status === 'Waiting Invoice') return 'waiting-nf';
  if (status === 'Legal Review') return 'legal-review';
  if (status === 'Digital Signature') return 'digital-signature';
  if (status === 'Waiting NF') return 'waiting-nf';
  if (status === 'Canceled') return 'canceled';
  if (status === 'Open') return 'open';
  if (status === 'Paid') return 'open';
  if (status === 'Processing') return 'open';
  if (status === 'Blocked') return 'blocked';
  if (status === 'Closed') return 'closed';
  if (status === 'Denied') return 'denied';
  if (status === 'InProcess') return 'finance-review';
}

export function setPaymentModalTabByStatus(status: PaymentStatusTypes): number {
  switch (status) {
    case PAYMENT_STATUS.WAITING_INVOICE:
      return 2;
    case PAYMENT_STATUS.FINANCE_REVIEW:
      return 1;
    default:
      return 0;
  }
}
