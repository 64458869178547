import DocumentTypes, { documentTypeInitialValue } from './documentType';

export default class BackDocuments {
  OnboardingId?: string = '';
  documentTypeSelected: DocumentTypes = documentTypeInitialValue;
  id: string = '';
  creationDate?: string | null = '';
  expirationDate: string = '';
  documentType? = 0;
  observation: string = '';
  note?: string | undefined = '';
  attachment = new File([], '') || null;
  fileName = '';
  url = '';
}
