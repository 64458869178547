
import _ from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import { Money } from 'v-money';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';

import ModalLoading from '@/components/actions/ModalLoading.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';
import AutomaticMergeIcon from '@/components/icons/AutomaticMergeIcon.vue';
import BundleIcon from '@/components/icons/BundleIcon.vue';
import UnbundleIcon from '@/components/icons/UnbundleIcon.vue';

import User from '@/types/auth/user';
import NewPaymentPayload, { Services } from '@/types/paymentById';
import Selects from '@/types/selects';
import ServiceResponse from '@/types/services/response';

interface GroupableService extends ServiceResponse {
  groupKey?: number | null;
  isVisible?: boolean;
}

@Component({
  components: { FileDisplay, ModalLoading, Money, BundleIcon, UnbundleIcon, AutomaticMergeIcon },
})
export default class ModalNewPayment extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Prop(Boolean) public newBundlePaymentsModal!: boolean;
  @Prop(Object) public paymentData!: NewPaymentPayload;
  @Prop(Array) public paymentServices!: ServiceResponse[];
  @Prop(Array) public companies!: Selects[];
  @Prop(Array) public requesterCompanies!: Selects[];
  @Prop(Boolean) public bundlingPayment!: boolean;

  @Watch('servicesSelecteds')
  matchServicesByCombination() {
    if (this.servicesSelecteds.length > 0) {
      this.servicesToBundle = this.services
        .filter((service) => {
          const serviceApproverIds = service.approvers.map((a) => (a.id as number) ?? 0);
          const selectedApproverIds = this.servicesSelecteds[0].approvers.map(
            (a) => (a.id as number) ?? 0
          );
          return (
            serviceApproverIds.every((id) => selectedApproverIds.includes(id)) &&
            service.payingCompany === this.servicesSelecteds[0].payingCompany &&
            service.requesterEntityId === this.servicesSelecteds[0].requesterEntityId &&
            service.paymentDate === this.servicesSelecteds[0].paymentDate
          );
        })
        .map((s) => {
          const service: Services = {
            id: s.id,
            description: s.description,
            costPerHour: s.costPerHour ?? 0,
            hoursAmount: s.hoursAmount ?? 0,
            total: s.total ?? 0,
            requesterEntityId: s.requesterEntityId ?? '',
            payingCompany: s.payingCompany ?? 0,
            buRequester: s.buRequester ?? '',
            paymentDate: s.paymentDate ?? '',
            runTime: s.runTime ?? '',
            createdAt: s.createdAt ?? '',
            costCenterAllocations: s.costCenterAllocations,
            approversId: s.approvers.map((a) => (a.id as number) ?? 0),
            approvalModeId: s.approvalModeId,
            isVisible: s.isVisible,
            groupKey: s.groupKey,
          };
          return service;
        });
    }
  }

  @Watch('servicesSelecteds')
  clearServicesToBundleIfNoServicesAreSelected() {
    if (!this.servicesSelecteds.length) {
      this.servicesToBundle = [];
    }
  }

  serviceIsInGroup(item: Services) {
    if (this.servicesToBundle.some((i) => i.id == item.id)) {
      return true;
    }
    return false;
  }

  setRowStyle(item: Services) {
    if (this.servicesSelecteds.length === 0) return 'active-row';
    if (this.serviceIsInGroup(item)) return 'active-row';
    return 'inactive-row';
  }

  disableServiceSelector(item: Services) {
    if (this.servicesSelecteds.length === 0) {
      return false;
    }
    if (!this.serviceIsInGroup(item)) {
      return true;
    }
  }

  get disableAutomaticMergeButton() {
    if (this.servicesSelecteds.length > 0) return true;
    if (this.servicesToUnbundle.length > 0) return true;
    if (this.services.length === 1) return true;
    if (this.servicesToBundle.length === 0) return false;
    if (this.servicesSelecteds.length === this.servicesToBundle.length) return true;
    return false;
  }

  get disableBundleButton() {
    if (this.servicesToBundle.length === 0) return true;
    if (this.servicesSelecteds.length <= 1) return true;
    if (this.servicesSelecteds.length === this.servicesToBundle.length) return false;
    return false;
  }

  get disableUnbundleButton() {
    if (this.servicesToUnbundle.length === 0) return false;
    return true;
  }
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '',
    },
    {
      text: 'Activity',
      align: 'start',
      sortable: false,
      value: 'description',
    },
    {
      text: 'Approvers',
      align: 'start',
      sortable: false,
      value: '',
    },
    {
      text: 'Requesting entity',
      align: 'start',
      sortable: false,
      value: 'requesterEntityId',
    },
    {
      text: 'Paying company',
      align: 'start',
      sortable: false,
      value: 'payingCompany',
    },
    {
      text: 'Gross amount',
      align: 'start',
      sortable: false,
      value: 'total',
    },
    {
      text: 'Execution period',
      align: 'start',
      sortable: false,
      value: 'runTime',
    },
    {
      text: 'Used hours',
      align: 'start',
      sortable: false,
      value: 'hoursAmount',
    },
    {
      text: 'Payment date',
      align: 'start',
      sortable: false,
      value: 'paymentDate',
    },
  ];

  formatMoney = FormatMoney;
  formatDate = FormatDate;

  groupAmount = 1;
  panelOpeneds: number[] = [];

  testando = [];

  setHeaderWidth(headerText: string) {
    if (headerText === 'Activity') return 'width: 150px; margin-left: 51px;';
    if (headerText === 'Approvers') return 'width: 150px';
    if (headerText === 'Requesting entity') return 'width: 130px';
    if (headerText === 'Paying company') return 'width: 152px';
    if (headerText === 'Gross amount') return 'width: 155px';
    if (headerText === 'Execution period') return 'width: 125px';
    if (headerText === 'Used hours') return 'width: 84px';
  }

  services: GroupableService[] = [];

  servicesSelecteds: ServiceResponse[] = [];
  bundledServices: Services[][] = [];

  servicesToBundle: Services[] = [];
  servicesToUnbundle: Services[][] = [];

  get isVendor() {
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isFinance() {
    return this.userRoles.includes('Vendor.Finance');
  }

  get isManagement() {
    return this.userRoles.includes('Vendor.Management');
  }

  bundleServices() {
    const groupKey = this.groupAmount++;

    this.servicesSelecteds.forEach((serviceSelected) =>
      this.services.forEach((service) => {
        if (service.id === serviceSelected.id) {
          service.isVisible = false;
          service.groupKey = groupKey;
        }
      })
    );

    const servicesPartitions: Services[][] = _.partition(this.servicesSelecteds, 'groupKey');

    servicesPartitions.map((item) => {
      if (item.length > 0) {
        this.bundledServices.push(item);
      }
    });

    this.panelOpeneds.push(this.bundledServices.length - 1);

    this.servicesSelecteds = [];
    this.servicesToUnbundle = [];
  }

  openAccordionsToBundledServices() {
    this.panelOpeneds.push(this.bundledServices.length - 1);
  }

  unBlundleServices() {
    const servicesBundledGroupIds: number[] = [];
    let servicesGroupIds: number[] = [...new Set(servicesBundledGroupIds)];

    this.servicesToUnbundle.forEach((serviceBundleds, index) =>
      serviceBundleds.map((item) => {
        servicesBundledGroupIds.push(item.groupKey as number);
      })
    );

    servicesGroupIds = [...new Set(servicesBundledGroupIds)];

    servicesGroupIds.forEach((groupId) => {
      this.bundledServices.forEach(() => {
        const index = _.findIndex(this.bundledServices, function (service: Services[]) {
          return service[0].groupKey == groupId;
        });
        this.bundledServices.splice(index, 1);
      });
    });

    this.servicesToUnbundle.forEach((serviceBundleds) => {
      serviceBundleds.forEach((item) => {
        this.services.find((service) => {
          if (service.id === item.id) {
            service.groupKey = null;
            service.isVisible = true;
          }
        });
      });
    });
    this.servicesToUnbundle = [];
  }

  putBundledsServicesInServicesList() {
    this.servicesToUnbundle.forEach((serviceBundleds) =>
      serviceBundleds.forEach((serviceBundled) =>
        this.services.find((service) => {
          if (service.id === serviceBundled.id) {
            service.groupKey = null;
            service.isVisible = true;
          }
        })
      )
    );
  }

  clearBundledItemsList(servicesGroupIds: number[]) {
    servicesGroupIds.forEach(() => this.bundledServices.splice(0, 1));
  }

  automaticGroup() {
    const automaticGroups: Services[][] = this.getActivitiesGroupedToAutomaticMerge();

    this.removeAutomaticGroupedsActivitesInActivitiesList(automaticGroups);

    this.bundledServices = automaticGroups;
    this.openAccordionsToAutomaticGroup(automaticGroups);
  }

  getActivitiesGroupedToAutomaticMerge() {
    let automaticGroups: Services[][] = R.groupWith(
      (a: Services, b: Services) =>
        a.approversId.length === b.approversId.length &&
        a.approversId.every((id) => b.approversId.includes(id)) &&
        a.payingCompany === b.payingCompany &&
        a.requesterEntityId === b.requesterEntityId &&
        a.paymentDate === b.paymentDate,
      this.services.filter((item) => item.isVisible === true)
    );

    automaticGroups = automaticGroups.filter((service) => service.length > 1);

    if (this.bundledServices.length > 0) {
      automaticGroups = [...this.bundledServices, ...automaticGroups];
    }

    return automaticGroups;
  }

  openAccordionsToAutomaticGroup(automaticGroups: Services[][]) {
    automaticGroups.forEach((_, index) => this.panelOpeneds.push(index));
  }

  removeAutomaticGroupedsActivitesInActivitiesList(automaticGroups: Services[][]) {
    automaticGroups.forEach((groups, index) =>
      groups.forEach((group) => {
        group.groupKey = index + 1;
        group.isVisible = false;
      })
    );
  }

  closeModal() {
    this.$emit('closeNewBundlePaymentsModal');
    this.clearFields();
  }

  clearFields() {
    this.servicesSelecteds = [];
    this.servicesToBundle = [];
    this.bundledServices = [];
    this.panelOpeneds = [];
  }

  bundlePayments() {
    const servicesToBundle = this.bundledServices.map((services) =>
      services.map((service) => {
        return service.id;
      })
    );

    const params = {
      paymentId: this.paymentData.id,
      servicesBundle: servicesToBundle,
    };

    this.$emit('bundlePayments', params);
  }

  getPayingCompanyByCompanies(payingCompanyId: number) {
    if (payingCompanyId === null) return '--';

    const payingCompany = this.companies.find((company) => company.id === payingCompanyId);

    return payingCompany !== undefined ? payingCompany.name : '--';
  }

  getCompanyRequesterByRequesterEntity(requesterEntityId: number) {
    if (requesterEntityId === null) return '--';

    const companyRequester = this.requesterCompanies.find(
      (company) => company.id === requesterEntityId
    );

    return companyRequester !== undefined ? companyRequester.name : '--';
  }

  concatApprovers(approvers: Selects[]) {
    return approvers.map((a) => a.name).join(', ');
  }

  public handleFormatDate(date: string): string {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult = dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }

  created() {
    this.services = [
      ...this.paymentServices.map((service) => {
        return { ...service, isVisible: true, groupKey: null };
      }),
    ];
  }
}
