
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Selects, { selectsInitialValue } from '@/types/selects';

import Rules from '@/utils/rules';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalDeny extends Vue {
  @Getter('getOnboardingId', { namespace: 'modals' }) public onboardingId!: boolean;

  @Getter('getModalDenyState', { namespace: 'modals' }) public modalDeny!: boolean;
  @Action('setModalDenyState', { namespace: 'modals' }) public setModalDenyState!: any;

  @Prop({ default: false }) public loading!: boolean;
  @Prop(Array) public cities!: Selects;
  @Prop(Number) public onboardingStatus!: number;

  @Watch('loading')
  public handleCloseModal() {
    if (!this.loading) this.closeModal();
  }

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  rules = new Rules();

  citySelected: Selects = selectsInitialValue;
  denyReason = '';

  public async denyOnboarding() {
    if (!this.form.validate()) {
      return;
    }

    const denyParams = {
      id: this.onboardingId,
      approved: false,
      message: this.denyReason,
      internalMessage: false,
    };
    const approveParams = {
      id: this.onboardingId,
      approved: true,
      message: this.denyReason,
      internalMessage: false,
      cityId: this.citySelected.id,
    };
    this.onboardingStatus == 6
      ? this.$emit('setApproveDeniedOnboarding', approveParams)
      : this.$emit('setDenyOnboarding', denyParams);
  }

  get handleSetTitle() {
    return this.onboardingStatus == 6
      ? 'Do you want to approve this denied request?'
      : 'Do you want to deny this request?';
  }

  get handleButtonText() {
    return this.onboardingStatus == 6 ? 'Approve' : 'Deny';
  }

  get setButtonColor() {
    return this.onboardingStatus == 6 ? '#19B500' : '#C00202';
  }

  get handleSetRules() {
    return this.onboardingStatus == 6 ? this.rules.approveReason : this.rules.denyReason;
  }

  public closeModal(): void {
    this.setModalDenyState(false);
    this.denyReason = '';
    (this.$refs.form as HTMLFormElement).resetValidation();
  }
}
