import { ActionContext } from 'vuex';
import { PermissionsState } from './types';

import SystemInformations, {
  Permissions,
} from '@/types/auth/systemInformations';

interface UserPermissionsPayload {
  resource: string;
  action: string;
}

export default {
  setSystemInformations(
    store: ActionContext<PermissionsState, any>,
    systemInformations: SystemInformations
  ) {
    store.commit('setSystemInformations', systemInformations);
  },
  setUserPermissions(store: ActionContext<PermissionsState, any>, permissions: Permissions[]) {
    store.commit('setUserPermissions', permissions);
  },

  setUserRoles(store: ActionContext<PermissionsState, any>, userRoles: string[]) {
    store.commit('setUserRoles', userRoles);
  },

  verifyUserPermissions(
    store: ActionContext<PermissionsState, any>,
    userPermissionsPayload: UserPermissionsPayload
  ) {
    // if (store.state.userPermissions.length === 0) return false;

    const permissionsFilteredsByResource = store.state.userPermissions.filter(
      (permission) => permission.resource === userPermissionsPayload.resource
    );

    // if (permissionsFilteredsByResource.length === 0) return false;

    const resourceActions = permissionsFilteredsByResource.flatMap((item) => item.actions);

    const userPermissions = resourceActions.some(
      (userAction) => userAction === userPermissionsPayload.action
    );

    return userPermissions;
  },
};
