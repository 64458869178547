
import qs from 'qs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import NewAdditivePayload from '@/types/newAdditivePayload';
import Selects from '@/types/selects';

import AditiveData from '@/components/contracts/AditiveData.vue';
import AlocationData from '@/components/contracts/AlocationData.vue';
import ContractData from '@/components/contracts/ContractData.vue';
import DocumentData from '@/components/contracts/DocumentData.vue';
import FinancialGraph from '@/components/contracts/FinancialGraph.vue';
import PaymentData from '@/components/contracts/PaymentsData.vue';
import VendorData from '@/components/contracts/VendorData.vue';
import ApproversData from '@/components/contracts/ApproversData.vue';

import SkeletonLoader from '@/components/SkeletonLoader.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';
import ModalApproveContract from '@/components/contracts/ModalApproveContract.vue';
import NewAditive from '@/components/contracts/ModalNewAditive.vue';
import ModalReproveContract from '@/components/contracts/ModalReproveContract.vue';

import { AuthService } from '@/services/authService';
import ContractsById, { contractByIdInitialValue } from '@/types/contractsById';
import CostCenter from '@/types/costCenter';
import FinancialBudget from '@/types/financialBudget';
import { PAYMENT_STATUS } from '@/types/general/paymentStatus';
import RemoveDocumentPayload from '@/types/removeDocumentPayload';
import Endpoint from '@/utils/endpoint';
import { setErrorText } from '@/utils/errorHandler';
import VendorResponse from '@/types/response/response';
import Approvers from '@/types/Approvers';
@Component({
  components: {
    ContractData,
    VendorData,
    DocumentData,
    PaymentData,
    AditiveData,
    ApproversData,
    AlocationData,
    FinancialGraph,
    FileDisplay,
    ModalApproveContract,
    ModalReproveContract,
    NewAditive,
    SkeletonLoader,
  },
})
export default class ListContracts extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Prop(String) id!: string;
  @Prop(String) type!: string;

  @Watch('contractsById.documents', { deep: true })
  handleContractsDocument() {
    this.alreadExistSignedContract = this.contract.documents.some(
      (item) => item.type === 'Signed Contract'
    );

    this.alreadExistDocumentAdditional = this.contract.documents.some(
      (item) => item.type !== 'Contract' && item.type !== 'Signed Contract'
    );
  }

  private endpoint = new Endpoint();
  authService = new AuthService();
  alreadExistSignedContract = false;
  contractAreas: number | null = null;
  filterItems = false;
  showViewModal = false;
  loadingSignedContract = false;
  openNewContractModal = false;
  openNewAditiveModal = false;
  loadingAdditive = false;
  gettingContract = false;
  removingSignedContract = false;
  modalApproveContract = false;
  approvingContract = false;
  modalReproveContract = false;
  reprovingContract = false;
  alreadExistDocumentAdditional = false;
  downloadingFile = false;
  fileIsDownloading = '';
  updateAdditiveModal = 0;
  contractsById = '';
  contract: ContractsById = contractByIdInitialValue;
  companies: Selects[] = [];
  initialCompanies: Selects[] = [];
  jurisdictions: Selects[] = [];
  approvers: Approvers[] = [];
  companyId: string = '';
  getContratcId: string = '';
  financialBudgetData: FinancialBudget[] = [];
  costCenterData: CostCenter[] = [];

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  public setStatusClass(status: string): string {
    if (status === 'Open') return 'contract-open-status';
    if (status === 'Canceled') return 'canceled';
    if (status === 'Blocked') return 'contract-blocked-status';
    if (status === 'InProcess') return 'contract-in-proccess-status';
    if (status === 'Closed') return 'contract-closed-status';
    if (status === 'Owner Review') return 'owner-review';
    if (status === 'Vendor Review') return 'vendor-review';
    if (status === 'Internal Process') return 'internal-proccess';
    if (status === 'Legal Review') return 'legal-review';
    if (status === 'Signed') return 'digital-signature';
    if (status === 'Denied') return 'denied';
    if (status === 'InProcess') return 'finance-review';
    return '';
  }

  statusName(item: string) {
    if (item === 'InProcess') {
      return 'Internal Process';
    } else {
      return item;
    }
  }

  async getByContracts(item: boolean) {
    await this.getContractById();
  }

  closeApproveModal() {
    this.modalApproveContract = false;
  }

  closeReproveModal() {
    this.modalReproveContract = false;
  }

  showModalView() {
    this.showViewModal = true;
  }

  closeViewModal() {
    this.showViewModal = false;
  }

  closeFilter() {
    this.filterItems = false;
  }

  showNewAditiveModal() {
    this.openNewAditiveModal = true;
  }

  closeNewAditiveModal() {
    this.openNewAditiveModal = false;
    (this.$refs.newAditive as NewAditive).clearFields();
  }

  async getFinancialBudget() {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.financialBudget.base, {
        headers: {
          Authorization: `${token}`,
          lesteKey: this.endpoint.auth.fintiServicesKey!,
          'API-Key': `${this.endpoint.auth.fintiKey}`,
        },
      });
      this.financialBudgetData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de planos financeiros', error);
    }
  }

  async getCostCenters() {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(
        this.endpoint.costCenter.base + `/${this.contract.companyId}`,
        {
          headers: {
            Authorization: `${token}`,
            lesteKey: this.endpoint.auth.fintiServicesKey!,
          },
        }
      );
      this.costCenterData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de cost centers', error);
    }
  }

  async addAdditive(additivePayload: NewAdditivePayload) {
    this.loadingAdditive = true;
    const params = {
      ContractId: additivePayload.contractId,
      AdditiveValue: additivePayload.additiveValue,
      Description: additivePayload.description,
      Title: additivePayload.title,
      DeadlineAdditive: additivePayload.deadlineAdditive,
      AdditiveFileContract: additivePayload.additiveFileContract,
      Status: PAYMENT_STATUS.OPEN,
    };
    const token = await this.authService.getIdToken();
    const formData = new FormData();

    for (const [key, value] of Object.entries(params)) {
      if (value) formData.append(key, value);
    }
    this.$http
      .post(this.endpoint.additive.base, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      })
      .then((resp) => {
        this.closeNewAditiveModal();
        this.getContractById();
      })
      .catch((error) => {
        this.setFeedback({
          feedback: true,
          isError: true,
          color: '#ad1318',
          text: setErrorText(error),
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 8000);
      })
      .finally(() => {
        this.loadingAdditive = false;
        this.updateAdditiveModal++;
      });
  }

  async handleDownloadFile(fileData: any) {
    this.downloadingFile = true;
    try {
      const token = await this.authService.getTokenForMenuAsync();
      const response = await this.$http.get(this.endpoint.files.download, {
        params: {
          fileUrl: fileData.fileUrl,
        },
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          'content-type': 'blob',
          Authorization: `${token}`,
        },
        responseType: 'blob',
      });

      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      this.fileIsDownloading = fileData.fileName;
      reader.onload = function () {
        const result: string | ArrayBuffer = reader.result ? reader.result : '';
        const downloadLink = document.createElement('a');
        downloadLink.href = result.toString();
        downloadLink.download = fileData.fileName;
        downloadLink.click();
      };
    } catch (error) {
      console.log('Ocorreu um erro ao baixar arquivo', error);
    }
    this.downloadingFile = false;
    this.fileIsDownloading = '';
  }

  async removeFile(item: RemoveDocumentPayload) {
    // this.contractsById.documents.splice(this.contractsById.documents.indexOf(item.key), 1);
    this.removingSignedContract = true;
    try {
      const token = await this.authService.getIdToken();
      await this.$http.patch(
        this.endpoint.contract.removeSignedContract + `/${item.documentId}`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      );
      await this.getContractById();
    } catch (error) {
      console.log('Ocorreu um erro ao aprovar este aditive', error);
    }
    this.removingSignedContract = false;
  }

  async getContractById() {
    this.gettingContract = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(this.endpoint.contract.base + `/${this.id}`, {
        headers: { Authorization: `${token}` },
      });
      this.contract = data;
      this.approvers = data.approvers;
      this.companyId = data.companyId;
      this.contractsById = data.id;
    } catch (error) {
      console.log('Ocorreu um erro ao obter dados deste contrato', error);
    }
    this.gettingContract = false;
  }

  async getAditiveById() {
    this.gettingContract = true;
    try {
      const { data } = await this.$http.get(this.endpoint.aditive.base + `/${this.id}`);
      this.contract = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter dados deste contrato', error);
    }
    this.gettingContract = false;
  }

  async getCompanyies() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.companies, {
        headers: { Authorization: `${token}` },
      });
      this.companies = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de empresas', error);
    }
  }

  async getJurisdictions() {
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(this.endpoint.selects.jurisdictions, {
        headers: { Authorization: `${token}` },
      });
      this.jurisdictions = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de Jurisdições', error);
    }
  }

  async approveContract() {
    this.approvingContract = true;
    try {
      const params = {
        contractId: this.contract.id,
        feedback: 'aprovado',
      };
      await this.$http.patch(this.endpoint.contract.approve, params);
    } catch (error) {
      this.closeApproveModal();
      console.log('Ocorreu um erro ao aprovar este contrato', error);
    }
    this.approvingContract = false;
    this.closeApproveModal();
  }

  async reproveContract() {
    this.reprovingContract = true;
    try {
      const params = {
        contractId: this.contract.id,
        feedback: '',
      };
      await this.$http.patch(this.endpoint.contract.reprove, params);
    } catch (error) {
      this.closeReproveModal();
      console.log('Ocorreu um erro ao aprovar este contrato', error);
    }
    this.reprovingContract = false;
    this.closeReproveModal();
  }

  goBack() {
    this.$router.push({ name: 'ListContracts' });
  }

  setTabActiveBycontractType() {
    if (this.type === 'contract') {
      this.contractAreas = 0;
      return;
    }
    if (this.contract.isAdditive === false) {
      this.contractAreas = 0;
      return;
    }
    this.contractAreas = 4;
  }

  async created() {
    this.gettingContract = true;
    await Promise.all([
      this.getContractById(),
      this.getCompanyies(),
      this.getJurisdictions(),
      this.getFinancialBudget(),
      this.getCostCenters(),
      this.setTabActiveBycontractType(),
    ]);
    this.getCompaniesForOwner(this.contract.lesteUserEmail);
    this.gettingContract = false;
  }

  public async getCompaniesForOwner(ownerEmail: string | undefined): Promise<void> {
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get<VendorResponse<Selects[]>>(
        this.endpoint.selects.companies + `?ownerEmail=${ownerEmail}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (!data || data.length === 0) {
        return;
      }
      this.initialCompanies = data;
    } catch (error) {
      this.initialCompanies = [];
    }
  }
}
