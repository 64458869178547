import fs from 'fs';

export default function truncateFile(file: string, limiter: number, isXlBreakpoint: boolean) {
  const split = file.split('.');
  let fileName = split[0];
  const extension = split.pop();

  if (fileName.length > limiter) {
    fileName = fileName.substring(0, isXlBreakpoint ? limiter + 10 : limiter).trim();
    return fileName + '...' + extension;
  }

  return file;
}
