
import { Component, Prop, Vue } from 'vue-property-decorator';
import SkeletonLoader from '@/components/SkeletonLoader.vue';
import ContractDetail from '@/types/contractDetail';
import Approvers from '@/types/Approvers'
import { AuthService } from '@/services/authService';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import Endpoint from '@/utils/endpoint';
import ModalEditApproveContract from '@/components/Modals/ModalEditApproveContract.vue';
import Selects, { selectsInitialValue } from '@/types/selects';
import ContractByid, {
  contractByIdInitialValue
} from '@/types/contractsById';
import SetApproversPayload from '@/types/SetApproversPayload';
import { setErrorText } from '@/utils/errorHandler';
import { Action, Getter } from 'vuex-class';
import Filter from '@/types/filter';

import FilterPayload, { filterPayloadInitialValue } from '@/types/filtersPayload';


@Component({ components: { SkeletonLoader, ModalEditApproveContract } })
export default class ContractData extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Prop(Array) approvers!: Approvers[];
  @Prop(Number) companyId!: number;
  @Prop(Number) contractsById!: number;

  @Prop(Boolean) gettingContract!: boolean;

  formatDate = FormatDate;
  formatMoney = FormatMoney;
  authService = new AuthService();
  private endpoint = new Endpoint();
  filterCheckboxs: Filter[] = [];
  showAddApproverModal = false;
  requiresSecondApprover = false;
  showViewModal = false;
  approverData: Selects[] = [];
  gettingApprovers = false;
  addingApprover = false;
  isApprove = false;
  isEditApprove = false;
  filtersContract: FilterPayload = filterPayloadInitialValue;
  textError = '';
  hasError = false;

  showModalNewAlocation() {
    this.$emit('showAddApproverModal');
  }

  get Approvers() {
    return this.approvers.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  }

  async setContractApprover(aproversId: number[]) {
    this.addingApprover = true;

    try {
      const token = await this.authService.getIdToken();
      await this.$http.patch(this.endpoint.selects.contract + `/${this.contractsById}/Approvers`, { approversId: aproversId }, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.addingApprover = false;
      this.closeAddApproverModal();
      this.isApprove = true

      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Contract Successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      this.getByContracts(true)
    } catch (error) {
      this.isApprove = false
      this.getByContracts(false)
      this.hasError = true;
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      this.addingApprover = false;
    }
    this.addingApprover = false;
  }

  getByContracts(item: boolean) {
    this.$emit('getByContracts', item)
  }




  closeAddApproverModal() {
    this.showAddApproverModal = false;
    this.showViewModal = true;
    this.isEditApprove = false;
  }
  async mounted() {
    this.gettingApprovers = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get<any>(this.endpoint.selects.approvers + `/${this.companyId}`, {
        headers: { Authorization: `${token}` },
      });
      this.approverData = data
    } catch (error) {
      // console.log('Ocorreu um erro ao obter dados de aprovadores deste contrato');
    }
    this.gettingApprovers = false;
  }
}
