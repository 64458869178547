
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'vue-debounce';
import { Getter } from 'vuex-class';
import Filter from '@/types/filter';
import Selects from '@/types/selects';
import FormatDate from '@/utils/formatDate';

@Component({})
export default class ContractFilter extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Watch('statusSelecteds')
  @Watch('emissionDate')
  @Watch('expirationDate')
  @Watch('companySelected')
  @Watch('managersSelected')
  @Watch('vendorsSelected')
  handleGetStatus() {
    this.getData();
    this.setWasFiltering();
  }

  @Watch('endValue')
  @Watch('startValue')
  getContractByvalue = debounce(() => {
    this.getData();
    this.setWasFiltering();
  }, 1000);

  @Watch('filterCheckboxs', { deep: true })
  handleFilterCheckboxs() {
    this.clearStatusSelecteds();
  }

  get filterDrawer() {
    return this.filterItems;
  }

  itemFormattedTitle(title: string){
    if (this.isMoreThanVendor) return title;
    if (title === 'Finance Review' && this.isVendor && !this.isMoreThanVendor) return 'Internal Process';
    return title;
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isMoreThanVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.length > 1;
  }
  @Watch('filterItems')
  testandopdsheiir() {
    this.$emit('update:filterItems', this.filterItems);

    // if (this.filterItems === true) {
    //   this.itemsCompanies = [
    //     ...this.companies.map((service) => {
    //       return { ...service, isChecked: false };
    //     }),
    //   ];
    //   this.companiesData = [
    //     ...this.companies.map((service) => {
    //       return { ...service, isChecked: false };
    //     }),
    //   ];
    // }
  }

  @Watch('lesteCompanies')
  hideCompaniesSelects() {
    this.companies.filter((item: any) => {
      this.lesteCompanies.forEach((companyId) => {
        if (item.id === companyId) {
          item.isChecked = true;
        }

        if (item.isChecked) {
          this.companies.push({
            name: item.name,
            id: item.id,
            isChecked: item.isChecked ? true : false,
          });
        }
      });
    });
  }

  // lesteCompaniesSelects() {
  //   this.companies.filter((x) => this.lesteCompanies.includes(x.id));
  // }

  @Watch('selectsCompanies')
  handleLesteCompaniesSelects(val: Selects[], oldVal: Selects[]) {
    this.lesteCompanies.forEach((select) => {
      this.itemsCompanies.find((company: any) => {
        if (company.id === select) {
          company.isChecked = true;
        }
      });
      this.companies.find((company: any) => {
        if (company.id === select) {
          company.isChecked = true;
        }
      });
    });

    this.selectsCompanies = [
      ...this.selectsCompanies,
      ...this.itemsCompanies.filter((item) => item.isChecked === true),
    ];
    this.itemsCompanies = [...this.itemsCompanies.filter((item) => item.isChecked === false)];
  }

  removeSelectCompany(item: Selects) {
    this.companiesData.forEach((company) => {
      if (company.id === item.id) {
        company.isChecked = false;
      }
    });

    this.itemsCompanies = [...this.companiesData.filter((item) => !item.isChecked === true)];
    const selectCompanyIndex = this.companySelected.indexOf(item);
    this.companySelected.splice(selectCompanyIndex, 1);
    console.log('Items companies: ', this.itemsCompanies);
  }

  addSelectCompanies(item: Selects) {
    const companyToSelect: any = this.companiesData.find((company) => company.id === item.id);
    companyToSelect!.isChecked = true;

    console.log(companyToSelect);
    this.itemsCompanies = [...this.companiesData.filter((item) => !item.isChecked === true)];
    this.companySelected.push(companyToSelect);

    console.log('Leste Select: ', this.companySelected);
  }

  @Prop(Boolean) filterItems!: boolean;
  @Prop(Array) filterCheckboxs!: Filter[];
  @Prop(Array) public companies!: Selects[];
  @Prop(Array) public managers!: Selects[];
  @Prop(Array) public vendors!: Selects[];

  gettingSelects = false;
  gettingCompanies = false;
  gettingEntityTypes = false;
  expiration = false;
  expirationDate = '';
  emission = false;
  emissionDate = '';
  startValue: number | string | null = null;
  endValue: number | string | null = null;

  statusSelecteds: string[] = [];
  lesteCompanies: Selects[] = [];
  companySelected: Selects[] = [];
  selectsCompanies: Selects[] = [];
  managersSelected: Selects[] = [];
  vendorsSelected: Selects[] = [];
  itemsCompanies: Selects[] = [];
  companiesData: Selects[] = [];

  wasFiltering = false;

  formatDate = FormatDate;

  getData() {
    this.$emit('getData', {
      status: this.statusSelecteds,
      startDeadlineDate: this.emissionDate,
      endDeadlineDate: this.expirationDate,
      startValue: this.startValue,
      endValue: this.endValue,
      listCompanies: this.companySelected,
      managersSelected: this.managersSelected,
      listVendors: this.vendorsSelected,
    });
  }

  setWasFiltering() {
    if (
      this.statusSelecteds.length !== 0 ||
      this.emissionDate !== '' ||
      this.expirationDate !== '' ||
      (this.startValue !== null && this.startValue !== '') ||
      (this.endValue !== null && this.endValue !== '') ||
      this.vendorsSelected.length !== 0 ||
      this.managersSelected.length !== 0 ||
      this.companySelected.length !== 0
    ) {
      this.wasFiltering = true;
    } else {
      this.wasFiltering = false;
    }

    this.$emit('setWasFiltering', this.wasFiltering);
  }

  get getCompaniesChecked() {
    const companyChecked = this.companiesData.filter((item) => item.isChecked === true);

    return companyChecked.length !== 0 ? true : false;
  }

  setFilterStatusName() {
    const setNameFilter = this.filterCheckboxs.find((item) => item.title);

    return setNameFilter;
  }

  limitCaracters(word: string) {
    return word.length > 9 ? `${word.slice(0, 10)}...` : word;
  }

  limitOnlyNumber(evt: any) {
    const dot = 46;
    const comma = 44;
    const firstDigit = 48;
    const lastDigit = 57;

    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < firstDigit || charCode > lastDigit) && charCode !== dot) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  getCompanyByCompanyId(companyId: number) {
    const companyName = this.companies.find((company) => company.id === companyId)?.name;
    return companyName !== undefined ? companyName : '--';
  }

  clearStatusSelecteds() {
    this.statusSelecteds = [];
  }

  clearAllSelects() {
    this.clearStatusSelecteds();
    this.emissionDate = '';
    this.expirationDate = '';
    this.startValue = null;
    this.endValue = null;
    this.companySelected = [];
    this.companiesData.forEach((company) => (company.isChecked = false));
    this.itemsCompanies = [
      ...this.companies.map((service) => {
        return { ...service, isChecked: false };
      }),
    ];
    this.managersSelected = [];
    this.vendorsSelected = [];
  }

  closeFilter() {
    this.$emit('closeFilter');
  }

  // getCompanies() {
  //   this.$emit('getCompanies');
  // }

  // async created() {

  // }
}
