
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Selects, { selectsInitialValue } from '@/types/selects';

@Component({})
export default class ModalApprove extends Vue {
  @Getter('getOnboardingId', { namespace: 'modals' }) public onboardingId!: boolean;

  @Getter('getModalApproveState', { namespace: 'modals' }) public modalApprove!: boolean;
  @Action('setModalApproveState', { namespace: 'modals' }) public setModalApproveState!: any;

  @Prop({ default: false }) public loading!: boolean;
  @Prop(Array) public cities!: Selects;

  citySelected: Selects = selectsInitialValue;

  @Watch('loading')
  public handleCloseModal() {
    if (!this.loading) this.closeModal();
  }

  public async approveOnboarding() {
    const params = {
      id: this.onboardingId,
      approved: true,
      internalMessage: false,
    };
    this.$emit('setApproveOnboarding', params);
  }

  public closeModal(): void {
    this.setModalApproveState(false);
    this.citySelected = selectsInitialValue;
  }
}
