
import { DefaultPhoto, getUserPhotoFromShieldByEmail } from '@/components/common/shieldService';
import ModalNewContract from '@/components/contracts/ModalNewContract.vue';
import FilterContracts from '@/components/filter/FilterIndex.vue';
import EditingModal from '@/components/Modals/EditingModal.vue';
import ModalAddApprover from '@/components/Modals/ModalAddApprover.vue';
import ModalApprove from '@/components/Modals/ModalApprove.vue';
import ModalCancel from '@/components/Modals/ModalCancel.vue';
import ModalContractSigned from '@/components/Modals/ModalContractSigned.vue';
import ModalNewAlocation from '@/components/Modals/ModalNewAlocation.vue';
import ModalReprove from '@/components/Modals/ModalReprove.vue';
import ModalDeleteContract from '@/components/Modals/ModalDeleteContract.vue';
import ViewModal from '@/components/Modals/ViewModal.vue';
import { AuthService } from '@/services/authService';
import AdditiveById, { additiveByIdInitialValue } from '@/types/additiveById';
import Alocation, { alocationInitialValue } from '@/types/alocation';
import Contract from '@/types/contracts';
import ContractByid, { contractByIdInitialValue } from '@/types/contractsById';
import CostCenter from '@/types/costCenter';
import CostCenterContract from '@/types/costCenterContract';
import EditAdditivePayload from '@/types/EditAdditivePayload';
import EditContractPayload from '@/types/EditContractPayload';
import Filter from '@/types/filter';
import FilterPayload, { filterPayloadInitialValue } from '@/types/filtersPayload';
import FinancialBudget from '@/types/financialBudget';
import NewContractPayload from '@/types/NewContractPayload';
import VendorResponse from '@/types/response/response';
import Selects, { selectsInitialValue } from '@/types/selects';
import SetApproversPayload from '@/types/SetApproversPayload';
import Endpoint from '@/utils/endpoint';
import { setErrorText } from '@/utils/errorHandler';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import ContractHeaders from '@/utils/getContractHeaders';
import setStatusClass from '@/utils/setStatus';
import axios from 'axios';
import qs from 'qs';
import AsyncComputedPlugin from 'vue-async-computed';
import AsyncComputed from 'vue-async-computed-decorator';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

Vue.use(AsyncComputedPlugin);

@Component({
  components: {
    FilterContracts: FilterContracts,
    ViewModal: ViewModal,
    ModalNewContract,
    EditingModal,
    ModalNewAlocation,
    ModalCancel,
    ModalApprove,
    ModalReprove,
    ModalContractSigned,
    ModalAddApprover,
    ModalDeleteContract,
  },
})
export default class ListContracts extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  @Watch('contractType')
  async handleGetContractByType() {
    if (!this.initializing) {
      this.controller.abort();
      this.controller = new AbortController();
      this.signal = this.controller.signal;
      // this.restartRequest = true;
      await this.getContracts(this.filtersContract);
    }
  }

  @Watch('showViewModal')
  async handleShowViewModal() {
    if (this.showViewModal) {
      if (this.isAdditive) {
        await this.getAdditiveById(this.additiveId);
        await this.getVendorBankData(this.additiveById.contract.entityId);
        await this.getCostCenters(this.additiveById.contract.companyId);
      } else {
        await this.getContractsById(this.idContract);
        await this.getVendorBankData(this.contractsById.vendor.entityId);
        await this.getCostCenters(this.contractsById.companyId);
      }
    }
  }

  setStatusClass = setStatusClass;
  authService = new AuthService();
  // restartRequest = false;
  initializing = false;
  contractType = 0;
  filterItems = false;
  showViewModal = false;
  openNewContractModal = false;
  gettingFilter = false;
  showSkeleton = false;
  showNewAlocationModal = false;
  showAddApproverModal = false;
  showDeleteModal = false;
  showModalCancel = false;
  isEditAlocation = false;
  requiresSecondApprover = false;
  showModalEdit = false;
  idContract = '';
  nameContractItem = '';
  wasFiltering = false;

  jurisdictions: Selects[] = [];
  approverData: Selects[] = [];
  filterCheckboxs: Filter[] = [];
  idViewModal = '';
  additiveId = '';
  idContractItem = '';
  isAdditive = false;
  gettingContracts = false;
  gettingContractsById = false;
  creatingContract = false;
  editingContract = false;
  approvingAdditive = false;
  approvingContract = false;
  reprovingAdditive = false;
  reprovingContract = false;
  loadingCancelContract = false;
  loadingDeleteContract = false;
  gettingApprovers = false;
  loadingCreateCostCenter = false;
  showModalAprove = false;
  showReproveModal = false;
  showModalContractSigned = false;
  isSignedContract = false;
  removingSignedContract = false;
  addingApprover = false;
  isEditApprove = false;
  removingAllocation = false;

  uploadNewContractModal = 0;
  editContractModal = 0;
  percentageValueCostCenter = 0;
  indexAllocationWasEditing: number | null = null;

  approveMember = '';
  deniedMember = '';
  textError = '';
  hasError = false;

  filtersContract: FilterPayload = filterPayloadInitialValue;

  private endpoint = new Endpoint();
  formatMoney = FormatMoney;
  formatDate = FormatDate;

  baseHeaders = [
    {
      text: 'Contract name',
      align: 'start',
      sortable: true,
      value: 'title',
      isVisible: true,
    },
    {
      text: 'Leste Company',
      align: 'start',
      sortable: true,
      value: 'lesteCompany',
      isVisible: true,
    },
    {
      text: 'Contract Value',
      align: 'start',
      sortable: true,
      value: 'contractValue',
      isVisible: true,
    },
    {
      text: 'Runtime',
      align: 'start',
      sortable: true,
      value: 'deadlineContract',
      isVisible: true,
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      isVisible: true,
      width: '15%',
    },
  ];

  // contractHeaders: any[] = [];
  photos: { [key: string]: string } = {};

  inProgressContracts: Contract[] = [];
  signedContracts: Contract[] = [];
  contractsById: ContractByid = contractByIdInitialValue;
  additiveById: AdditiveById = additiveByIdInitialValue;
  companies: Selects[] = [];
  initialCompanies: Selects[] = [];
  managers: Selects[] = [];
  vendors: Selects[] = [];
  vendorBankData: Selects = selectsInitialValue;
  paymentMethods: Selects[] = [];
  withoutFundCompanies: Selects[] = [];
  alocationItems: Alocation = alocationInitialValue;
  financialBudgetData: FinancialBudget[] = [];
  costCenterData: CostCenter[] = [];
  controller = new AbortController();
  signal = this.controller.signal;
  canCreate = false;
  canDelete = false;
  loadingSignedContract = false;

  @AsyncComputed()
  async getCanCreate() {
    this.canCreate = await this.verifyUserPermissions({
      resource: 'Contract',
      action: 'Create',
    });
  }

  @AsyncComputed()
  async getCanDelete() {
    this.canDelete = await this.verifyUserPermissions({
      resource: 'Contract',
      action: 'Block',
    });
  }

  statusName(item: string) {
    const deployName = process.env.VUE_APP_NAME_DEPLOY;
    if (deployName === 'LORE' && item === 'Legal Review') {
      return 'Opportunity';
    } else if (item === 'InProcess') {
      return 'Internal Process';
    } else {
      return item;
    }
  }
  get getContractHeaders() {
    if (this.isVendor) return ContractHeaders.Vendor;
    if (this.isManagement) return ContractHeaders.Management;
    return ContractHeaders.Finance;
  }

  @Watch('userRoles', { immediate: true })
  setContractHeadersDataBasedInUserPermission() {
    const vendorHeader = {
      text: 'Vendor',
      align: 'start',
      sortable: true,
      value: 'vendorEmail',
      isVisible: !this.isVendor,
    };
    const managementHeader = {
      text: 'Accountable Manager',
      align: 'start',
      sortable: true,
      value: 'owner',
      isVisible: !this.isManagement,
    };
    this.baseHeaders.splice(1, 0, vendorHeader);
    this.baseHeaders.splice(3, 0, managementHeader);
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  get isManagement() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Management');
  }

  get contracts() {
    if (this.contractType === 0) return this.inProgressContracts;
    return this.signedContracts;
  }

  handleRowCLick(item: Contract) {
    this.contractType === 1
      ? this.$router.push({
          name: 'ViewContract',
          params: { id: item.id, type: item.isAdditive ? 'aditive' : 'contract' },
        })
      : this.showModalView(item);
  }
  disableDelete: any = [];
  async deleteContractId(id: string, name: string) {
    this.showDeleteModal = true;
    this.idContractItem = id;
    this.nameContractItem = name;
  }

  async deleteContract(id: string) {
    try {
      const token = await this.authService.getIdToken();
      this.loadingDeleteContract = true;
      await this.$http.delete(this.endpoint.contract.delete + `/${id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.loadingDeleteContract = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Contract successfully deleted',
      });
      this.showDeleteModal = false;
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
      await this.getContracts(this.filtersContract);
    } catch (error) {
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'red',
        text: 'Error while deleting',
      });
      this.showDeleteModal = false;
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
  }

  closeDeleteModal() {
    this.showDeleteModal = false;
    this.disableDelete.length = 0;
  }

  closeNewContractModal() {
    this.openNewContractModal = false;
    (this.$refs.modalNewContract as ModalNewContract).clearFields();
  }

  async showModalView(item: Contract) {
    this.isAdditive = item.isAdditive;
    if (item.isAdditive === false) {
      this.idContract = item.id;
    } else {
      this.additiveId = item.additiveId;
    }
    this.showViewModal = true;
  }

  get alocationPercentageValue() {
    let percentageValue = 0;
    this.contractsById.costCenterAllocations.forEach(
      (item) => (percentageValue = percentageValue + item.percentage)
    );

    return percentageValue;
  }

  showCancelModal() {
    this.showModalCancel = true;
    this.showViewModal = false;
  }

  showModalNewAlocation() {
    this.isEditAlocation = false;
    this.showNewAlocationModal = true;
    this.showViewModal = false;
  }

  showModalAddApprover(companyId: number) {
    // console.log('showModalAddApprover: ', companyId);
    const company = this.companies.find((c) => c.id === companyId);

    this.requiresSecondApprover = company?.flag ?? false;
    this.showAddApproverModal = true;
    this.showViewModal = false;
  }

  closeAddApproverModal() {
    this.showAddApproverModal = false;
    this.showViewModal = true;
    this.isEditApprove = false;
  }

  editApprover(companyId: number) {
    console.log('showModalAddApprover(edit): ', companyId);
    const company = this.companies.find((c) => c.id === companyId);
    console.log(company);
    this.requiresSecondApprover = company?.flag ?? false;
    this.showAddApproverModal = true;
    this.showViewModal = false;
    this.isEditApprove = true;
  }

  showAproveModal() {
    if (this.isAdditive) {
      this.approveMember = this.additiveById.permission.approveMember;
    } else {
      this.approveMember = this.contractsById.permission.approveMember;
    }
    this.showModalAprove = true;
    this.showViewModal = false;
  }

  showModalReprove() {
    if (this.isAdditive) {
      this.deniedMember = this.additiveById.permission.deniedMember;
    } else {
      this.deniedMember = this.contractsById.permission.deniedMember;
    }
    this.showReproveModal = true;
    this.showViewModal = false;
  }

  showEditAlocationModal(item: Alocation) {
    this.isEditAlocation = true;
    this.alocationItems = item;
    this.showNewAlocationModal = true;
    this.showViewModal = false;
  }

  showContractSignedModal() {
    this.showModalContractSigned = true;
    this.showViewModal = false;
  }

  setIndexAllocationWasEditing(index: number) {
    this.indexAllocationWasEditing = index;
  }

  closeModalNewAlocation() {
    this.showNewAlocationModal = false;
    this.showViewModal = true;
    this.indexAllocationWasEditing = null;
  }

  closeViewModal() {
    this.showViewModal = false;
  }

  closeModalCancel() {
    this.showModalCancel = false;
  }

  closeModalApprove() {
    this.showModalAprove = false;
  }

  closeModalReprove() {
    this.showReproveModal = false;
  }

  closeFilter() {
    this.filterItems = false;
  }

  openEditContractModal(isAdditive: boolean) {
    this.isAdditive = isAdditive;
    this.showModalEdit = true;
  }

  closeModalEdit() {
    this.showModalEdit = false;
  }

  closeContractSignedModal() {
    this.showModalContractSigned = false;
  }

  goToDetails() {
    this.$router.push({ name: 'ViewContract' });
  }

  setWasFiltering(state: boolean) {
    this.wasFiltering = state;
  }

  getCompanyByCompanyId(companyId: number) {
    const companyName = this.withoutFundCompanies.find((company) => company.id === companyId)?.name;
    return companyName !== undefined ? companyName : '--';
  }

  async approveAditive(approveField: string) {
    this.approvingContract = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        additiveId: this.additiveId,
        feedback: approveField,
      };
      const response = await this.$http.patch(this.endpoint.aditive.approve, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalApprove();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully approved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getContracts(this.filtersContract);
      }
    } catch (error: any) {
      this.closeModalApprove();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.approvingContract = false;
  }

  async approveContract(approveField: string) {
    this.approvingContract = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        contractId: this.contractsById.id,
        feedback: approveField,
      };
      const response = await this.$http.patch(this.endpoint.contract.approve, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalApprove();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully approved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getContracts(this.filtersContract);
      }
    } catch (error: any) {
      this.closeModalApprove();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 8000);
    }
    this.approvingContract = false;
  }

  async reproveAditive(reproveField: string) {
    this.reprovingContract = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        additiveId: this.additiveId,
        feedback: reproveField,
      };
      const response = await this.$http.patch(this.endpoint.aditive.reprove, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalReprove();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully reproved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getContracts(this.filtersContract);
      }
    } catch (error: any) {
      this.closeModalReprove();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.reprovingContract = false;
  }

  async reproveContract(reproveField: string) {
    this.reprovingContract = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        contractId: this.contractsById.id,
        feedback: reproveField,
      };
      const response = await this.$http.patch(this.endpoint.contract.reprove, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalReprove();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully reproved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getContracts(this.filtersContract);
      }
    } catch (error: any) {
      this.closeModalReprove();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.reprovingContract = false;
  }

  async cancelAditive() {
    this.loadingCancelContract = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        additiveId: this.additiveId,
        feedback: 'cancelado',
      };
      const response = await this.$http.patch(this.endpoint.aditive.cancel, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalCancel();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully canceled',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getContracts(this.filtersContract);
      }
    } catch (error: any) {
      this.closeModalCancel();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.loadingCancelContract = false;
  }

  async cancelContract() {
    this.loadingCancelContract = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        contractId: this.contractsById.id,
        feedback: 'cancelado',
      };
      const response = await this.$http.patch(this.endpoint.contract.cancel, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalCancel();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully canceled',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getContracts(this.filtersContract);
      }
    } catch (error: any) {
      this.closeModalCancel();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.loadingCancelContract = false;
  }

  async removeSignedContract(data: { id: string; documentId: string; isAdditive: boolean }) {
    this.removingSignedContract = true;
    try {
      const token = await this.authService.getIdToken();
      await this.$http.patch(
        this.endpoint.contract.removeSignedContract + `/${data.documentId}`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (data.isAdditive) await this.getAdditiveById(data.id);
      else await this.getContractsById(data.id);
    } catch (error: any) {
      // console.log('Ocorreu um erro ao deletar um contrato assinado');
    }
    this.removingSignedContract = false;
  }

  async getFilterStatusName() {
    this.gettingFilter = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        IsContract: true,
        InProcess: this.contractType === 0 ? true : false,
      };
      const { data } = await this.$http.get(this.endpoint.filter.base, {
        params,
        headers: { Authorization: `${token}` },
      });
      this.filterCheckboxs = data;
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de status', error);
    }
    this.gettingFilter = false;
  }

  async getApproverByContractCompany() {
    this.gettingApprovers = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(
        this.endpoint.selects.approvers + `/${this.contractsById.companyId}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      this.approverData = data;
    } catch (error) {
      // console.log('Ocorreu um erro ao obter dados de aprovadores deste contrato');
    }
    this.gettingApprovers = false;
  }

  validateStatus(status: string[]) {
    if (status.length !== 0 && status.length !== this.filterCheckboxs.length) {
      return true;
    }
    return false;
  }

  validateDeadline(deadlineDate: string) {
    if (deadlineDate !== '') return true;
    return false;
  }

  validateContractValue(contractValue: number | null) {
    if (contractValue !== null) return true;
    return false;
  }

  validateListCompaniesAndManagers(list: Selects[]) {
    if (list.length !== 0) return true;
    return false;
  }

  setContractsFielterValues(contractPayload: FilterPayload) {
    this.filtersContract = contractPayload;
  }

  async getContracts(contractPayload: FilterPayload) {
    this.setContractsFielterValues(contractPayload);
    this.gettingContracts = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        InProcess: this.contractType === 0 ? true : false,
        ...(this.contractType === 0
          ? this.validateStatus(contractPayload.status) && { ListStates: contractPayload.status }
          : this.validateStatus(contractPayload.status) && { ListStates: contractPayload.status }),
        ...(this.validateDeadline(contractPayload.startDeadlineDate) && {
          StartDeadlineDate: contractPayload.startDeadlineDate,
        }),
        ...(this.validateDeadline(contractPayload.endDeadlineDate) && {
          EndDeadlineDate: contractPayload.endDeadlineDate,
        }),
        ...(this.validateContractValue(contractPayload.startValue) && {
          StartValue: contractPayload.startValue,
        }),
        ...(this.validateContractValue(contractPayload.endValue) && {
          EndValue: contractPayload.endValue,
        }),
        ...(this.validateListCompaniesAndManagers(contractPayload.listCompanies) && {
          ListCompany: contractPayload.listCompanies,
        }),
        ...(this.validateListCompaniesAndManagers(contractPayload.managersSelected) && {
          ListManagementContract: contractPayload.managersSelected,
        }),
        ...(this.validateListCompaniesAndManagers(contractPayload.listVendors) && {
          ListVendorContract: contractPayload.listVendors,
        }),
      };
      const { data } = await this.$http.get(this.endpoint.contract.base, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
        headers: { Authorization: `${token}`, contentType: 'application/json', dataType: 'json' },
        signal: this.signal,
      });
      if (this.contractType === 0) {
        this.inProgressContracts = data;
      } else {
        this.signedContracts = data;
      }
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de contratos', error);
    } finally {
      this.gettingContracts = false;
    }
  }

  async getJurisdictions() {
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(this.endpoint.selects.jurisdictions, {
        headers: { Authorization: `${token}` },
      });
      this.jurisdictions = data;
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de Jurisdições', error);
    }
  }

  async handleDownloadFile(fileData: any) {
    try {
      const token = await this.authService.getIdToken();
      const response = await this.$http.get(this.endpoint.files.download, {
        params: {
          fileUrl: fileData.fileUrl,
        },
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          'content-type': 'blob',
          Authorization: `${token}`,
        },
        responseType: 'blob',
      });

      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        const result: string | ArrayBuffer = reader.result ? reader.result : '';
        const downloadLink = document.createElement('a');
        downloadLink.href = result.toString();
        downloadLink.download = fileData.fileName;
        downloadLink.click();
      };
    } catch (error) {
      // console.log('Ocorreu um erro ao baixar arquivo', error);
    }
  }

  async getContractsById(id: string) {
    this.gettingContractsById = true;
    this.showSkeleton = true;
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get(this.endpoint.contract.base + `/${id}`, {
        headers: { Authorization: `${token}` },
      });
      const contract = data as ContractByid;
      const emails = [...new Set(contract.feedBacks.map((feedback) => feedback.email))];

      for (let email of emails) {
        if (this.photos[email] === undefined) this.getPhotoStringForEmail(email);
      }
      this.contractsById = data;
      await this.getCompaniesForOwner(this.contractsById.lesteUserEmail);
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de contratos por id', error);
    }
    this.gettingContractsById = false;
    this.showSkeleton = false;
  }

  public async getCompaniesForOwner(ownerEmail: string | undefined): Promise<void> {
    try {
      const token = await this.authService.getIdToken();
      const { data } = await this.$http.get<VendorResponse<Selects[]>>(
        this.endpoint.selects.companies + `?ownerEmail=${ownerEmail}`,
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (!data || data.length === 0) {
        return;
      }
      this.initialCompanies = data;
    } catch (error) {
      this.initialCompanies = [];
    }
  }

  public async getPhotoStringForEmail(email: string): Promise<void> {
    try {
      const resultPhoto = await getUserPhotoFromShieldByEmail(email);
      if (resultPhoto) {
        this.photos[email] = `data:;base64,${resultPhoto}`;
      } else this.photos[email] = DefaultPhoto;
    } catch (err) {
      this.photos[email] = DefaultPhoto;
    }
  }

  async getAdditiveById(id: string) {
    this.gettingContractsById = true;
    this.showSkeleton = true;
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.additive.base + `/${id}`, {
        headers: { Authorization: `${token}` },
      });
      this.additiveById = data;
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de contratos por id', error);
    }
    this.gettingContractsById = false;
    this.showSkeleton = false;
  }

  async getFinancialBudget() {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.financialBudget.base, {
        headers: {
          Authorization: `${token}`,
          lesteKey: this.endpoint.auth.fintiServicesKey!,
          'API-Key': `${this.endpoint.auth.fintiKey}`,
        },
      });
      this.financialBudgetData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de planos financeiros', error);
    }
  }

  async getCostCenters(companyId: number) {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.costCenter.base + `/${companyId}`, {
        headers: {
          Authorization: `${token}`,
          lesteKey: this.endpoint.auth.fintiServicesKey!,
        },
      });
      this.costCenterData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de cost centers', error);
    }
  }

  async createAlocation(contractId: string, costCenters: CostCenterContract[]) {
    this.loadingCreateCostCenter = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        contractId: contractId,
        costCenterAllocationCommand: costCenters.map((item) => ({
          costCenterId: item.costCenterSelected.id,
          financialBudgetId: item.financialBudgeSelected.id,
          percentage: item.percentage,
        })),
      };
      const response = await this.$http.post(this.endpoint.costCenter.create, params, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data !== null) {
        this.closeModalNewAlocation();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully created',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
      }
    } catch (error: any) {
      this.closeModalNewAlocation();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.loadingCreateCostCenter = false;
    this.closeModalNewAlocation();
  }

  async editCostCenters(idAlocation: string, alocation: Alocation) {
    this.loadingCreateCostCenter = true;
    try {
      const token = await this.authService.getIdToken();
      const params: Alocation = {
        costCenterId: alocation.costCenterId,
        financialBudgetId: alocation.financialBudgetId,
        percentage: alocation.percentage,
      };
      const response = await this.$http.put(
        this.endpoint.costCenter.create + `/${idAlocation}`,
        params,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data !== null) {
        this.closeModalNewAlocation();
        this.hasError = false;
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully edited',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
      }
    } catch (error) {
      console.log('Ocorreu um erro ao edtar os cost centers', error);
      this.hasError = true;
      this.textError = setErrorText(error);
    }
    this.loadingCreateCostCenter = false;
    this.closeModalNewAlocation();
  }

  async removeAlocation(alocation: Alocation) {
    this.removingAllocation = true;
    try {
      const token = await this.authService.getIdToken();
      await this.$http.delete(this.endpoint.costCenter.create + `/${alocation.id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      await this.getContractsById(this.contractsById.id);
    } catch (error: any) {
      console.log('Ocorreu um erro ao deletar uma alocação');
    }
    this.removingAllocation = false;
  }

  async contractSignedAdditive(file: any) {
    try {
      const token = await this.authService.getIdToken();
      const formData = new FormData();

      formData.append('SignedContract', file);
      await this.$http.post(
        this.endpoint.aditive.uploadSignedContractAdditive + `/${this.additiveId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        }
      );
    } catch (error: any) {
      console.log('Ocorreu um erro ao subir um contrato assinado');
    }
  }

  async contractSignedContract(file: any) {
    this.loadingSignedContract = true;
    try {
      const token = await this.authService.getIdToken();
      const formData = new FormData();

      formData.append('SignedContract', file);
      await this.$http.post(
        this.endpoint.contract.uploadSignedContract + `/${this.contractsById.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        }
      );
      await this.getContractsById(this.contractsById.id);
    } catch (error: any) {
      console.log('Ocorreu um erro ao subir um contrato assinado');
    }
    this.loadingSignedContract = false;
    this.isSignedContract = true;
  }

  async getCompanies() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.companies, {
        headers: { Authorization: `${token}` },
      });
      this.companies = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de empresas', error);
    }
  }

  async getManagers() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.managers, {
        headers: { Authorization: `${token}` },
      });
      this.managers = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de gestores', error);
    }
  }

  async getVendors() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.vendor, {
        headers: { Authorization: `${token}` },
      });
      this.vendors = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de vendors', error);
    }
  }

  async getPaymentMethods() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.paymentMethods, {
        headers: { Authorization: `${token}` },
      });
      this.paymentMethods = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de tipo de pagamentos', error);
    }
  }

  async getVendorBankData(entityId: string) {
    const token = await this.authService.getIdToken();
    try {
      const params = {
        entityId: entityId,
      };
      const { data } = await this.$http.get(this.endpoint.selects.vendorBank, {
        params,
        headers: { Authorization: `${token}` },
      });
      this.vendorBankData = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de vendors', error);
    }
  }

  async createContract(newContractPayload: NewContractPayload) {
    this.creatingContract = true;
    const token = await this.authService.getIdToken();
    const formData = new FormData();

    for (const [key, value] of Object.entries(newContractPayload)) {
      if (key === 'Documents') {
        newContractPayload.Documents.forEach((item) => formData.append('Documents', item));
      } else {
        formData.append(key, value);
      }
    }
    axios
      .post(this.endpoint.contract.base, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully created',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        this.creatingContract = false;
        this.uploadNewContractModal++;
        this.closeNewContractModal();
        this.getContracts(this.filtersContract);
      })
      .catch((error) => {
        this.setFeedback({
          feedback: true,
          isError: true,
          color: '#ad1318',
          text: setErrorText(error),
        });
        this.closeNewContractModal();
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 8000);
        this.creatingContract = false;
        this.uploadNewContractModal++;
        this.closeNewContractModal();
        this.getContracts(this.filtersContract);
      });
  }

  async editContract(editContractPayload: EditContractPayload) {
    editContractPayload.approverId = this.contractsById.approverId;
    this.editingContract = true;
    const token = await this.authService.getIdToken();
    const formData = new FormData();

    try {
      for (const [key, value] of Object.entries(editContractPayload)) {
        if (key === 'listDocumentsToRemove') {
          editContractPayload.listDocumentsToRemove?.find((item) =>
            formData.append('listDocumentsToRemove', item)
          );
        }
        if (key === 'documents') {
          editContractPayload.documents?.forEach((item) => formData.append('Documents', item));
        } else {
          if (value !== null && value !== undefined) formData.append(key, value);
        }
      }

      await this.$http.put(this.endpoint.contract.base + `/${editContractPayload.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      });

      this.closeModalEdit();
      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Contract Successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getContracts(this.filtersContract);
    } catch (error) {
      console.log('ocorreu um erro ao editar este contrato', error);
      this.hasError = true;
      this.textError = setErrorText(error);
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: this.textError,
      });
    }
    this.editContractModal++;
    this.editingContract = false;
  }

  async editAdditive(payload: EditAdditivePayload) {
    // console.log("EDITING ADDITIVE: ",payload);
    this.editingContract = true;
    const token = await this.authService.getIdToken();
    const formData = new FormData();

    try {
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'listDocumentsToRemove') {
          payload.listDocumentsToRemove?.find((item) =>
            formData.append('listDocumentsToRemove', item)
          );
        }
        if (key === 'documents') {
          payload.documents?.forEach((item) => formData.append('Documents', item));
        } else {
          if (value !== null && value !== undefined) formData.append(key, value);
        }
      }

      await this.$http.put(this.endpoint.aditive.base, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      });

      this.closeModalEdit();
      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Additive successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getContracts(this.filtersContract);
    } catch (error) {
      console.log('ocorreu um erro ao editar este aditivo', error);
      this.hasError = true;
      this.textError = setErrorText(error);
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: this.textError,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 8000);
    }
    this.editContractModal++;
    this.editingContract = false;
  }

  async setContractApprover(payload: SetApproversPayload) {
    this.addingApprover = true;
    try {
      const token = await this.authService.getIdToken();

      await this.$http.patch(
        this.endpoint.contract.base + `/${this.contractsById.id}/Approvers`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      this.addingApprover = false;
      this.closeAddApproverModal();
      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Contract Successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getContracts(this.filtersContract);
    } catch (error) {
      this.hasError = true;
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error),
      });
      this.addingApprover = false;
    }
    this.addingApprover = false;
  }

  async getNonFundCompanies(): Promise<void> {
    try {
      const response = await this.$http.get<Selects[]>(
        this.endpoint.company.base + '/without-fundcompanies',
        {
          headers: {
            lesteKey: this.endpoint.auth.fintiServicesKey!,
            'API-Key': `${this.endpoint.auth.fintiKey}`,
          },
        }
      );
      this.withoutFundCompanies = response;
    } catch {
      this.withoutFundCompanies = [];
    }
  }

  isExpiring(deadlineContract: string): boolean {
    if (deadlineContract === null) return false;

    const timeDiff = new Date(deadlineContract).getTime() - new Date().getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (daysDiff < 8) {
      return true;
    }
    return false;
  }

  needsBalance(usedValue: number, contractValue: number): boolean {
    if (usedValue === null || contractValue === null) return false;

    if (usedValue > contractValue) return true;
    if (contractValue < 0) return true;
    return false;
  }

  isOpen(status: string): boolean {
    return status === 'Open';
  }

  async beforeMount() {
    this.initializing = true;
    await this.getNonFundCompanies();
    await this.getJurisdictions();
    await this.getCompanies();
    await this.getFilterStatusName();
    await this.getManagers();
    await this.getPaymentMethods();
    await this.getFinancialBudget();
    await this.getVendors();
    this.initializing = false;
  }
}
