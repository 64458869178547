
import ContractDetail from '@/types/contractDetail';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import setStatusClass from '@/utils/setStatus';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AdditiveById, { additiveByIdInitialValue } from '@/types/additiveById';
import ViewAdditive from '@/components/additive/ViewAdditive.vue';
import ModalApprove from '@/components/Modals/ModalApprove.vue';
import ModalReprove from '@/components/Modals/ModalReprove.vue';
import EditingModal from '@/components/Modals/EditingModal.vue';
import Selects, { selectsInitialValue } from '@/types/selects';
import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import Alocation from '@/types/alocation';
import FinancialBudget from '@/types/financialBudget';
import CostCenter from '@/types/costCenter';
import { Action } from 'vuex-class';
import { setErrorText } from '@/utils/errorHandler';
import EditAdditivePayload from '@/types/EditAdditivePayload';
import VendorResponse from '@/types/response/response';
import { DefaultPhoto, getUserPhotoFromShieldByEmail } from '@/components/common/shieldService';
@Component({
  components: {
    ViewAdditive,
    ModalApprove,
    ModalReprove,
    EditingModal,
  },
})
export default class AdditivesTable extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(Array) public initialCompanies!: Selects[];
  @Action('setFeedback', { namespace: 'modals' }) public setFeedback: any;

  formatDate = FormatDate;
  formatMoney = FormatMoney;  
  setStatusClass = setStatusClass;  
  authService = new AuthService();
  endpoint = new Endpoint();
  editContractModal = 0;
  editingAdditive = false;
  isAdditive = true;
  showApproveModal = false;
  showCancelModal = false;
  showViewModal = false;
  showSkeleton = false;
  showModalApprove = false;
  showReproveModal = false;
  showModalEdit = false;
  removingSignedContract = false;
  loadingSignedContract = false;
  removingAllocation = false;
  gettingAdditiveById = false;
  approvingAdditive = false;
  reprovingAdditive = false;
  indexAllocationWasEditing: number | null = null;
  photos: {[key: string] : string} = {};
  additiveById: AdditiveById = additiveByIdInitialValue;
  jurisdictions: Selects[] = [];
  managers: Selects[] = [];
  vendors: Selects[] = [];
  paymentMethods: Selects[] = [];
  financialBudgetData: FinancialBudget[] = [];
  costCenterData: CostCenter[] = [];
  approveMember = '';
  deniedMember = '';
  textError = '';
  hasError = false;
  async showModalView(item: AdditiveById) {     
    if (item.status === 'Canceled'){
      return;
    }    
    this.showViewModal = true;
    await this.getAdditiveById(item.id);    
    this.getFinancialBudget();
    this.getCostCenters(this.additiveById.contract.companyId);
  }

  setIndexAllocationWasEditing(index: number) {
    this.indexAllocationWasEditing = index;
  }

  async removeAlocation(alocation: Alocation) {
    this.removingAllocation = true;
    try {
      const token = await this.authService.getIdToken();
      await this.$http.delete(this.endpoint.costCenter.create + `/${alocation.id}`, {
        headers: { 
          Authorization: `${token}`
         }
      });
      await this.getAdditiveById(this.additiveById.id);
    } catch (error: any) {
      console.log('Ocorreu um erro ao deletar uma alocação');
    }
    this.removingAllocation = false;
  }

  async getAdditiveById(id: string) {
    this.gettingAdditiveById = true;
    this.showSkeleton = true;
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get<VendorResponse<AdditiveById>>(this.endpoint.additive.base + `/${id}`, {
        headers: { Authorization: `${token}` },
      });
      
      const emails = [...new Set(data.feedBacks.map(feedback => feedback.email))];
      
      for (let email of emails){            
        if (this.photos[email] === undefined)     
          this.getPhotoStringForEmail(email);
      } 
      this.additiveById = data;
    } catch (error) {
      // console.log('Ocorreu um erro ao obter lista de contratos por id', error);
    }
    this.gettingAdditiveById = false;
    this.showSkeleton = false;
  }

  async getPhotoStringForEmail(email: string): Promise<void>{    
    try {
      const resultPhoto = await getUserPhotoFromShieldByEmail(email);
      if (resultPhoto){                
        this.photos[email] = `data:;base64,${resultPhoto}`;                        
      }
      else 
        this.photos[email] = DefaultPhoto;
    }
    catch (err) {
      this.photos[email] = DefaultPhoto;
    }
  }

  async contractSignedAdditive(file: any) {
    try {      
      const token = await this.authService.getIdToken();
      const formData = new FormData();

      formData.append('SignedContract', file);
      await this.$http.post(
        this.endpoint.aditive.uploadSignedContractAdditive + `/${this.additiveById.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        }
      );
    } catch (error: any) {
      console.log('Ocorreu um erro ao subir um contrato assinado');
    }
    this.$emit('refreshContract');
  }

  async contractSignedContract(file: any) {
    this.loadingSignedContract = true;
    try {
      const token = await this.authService.getIdToken();
      const formData = new FormData();

      formData.append('SignedContract', file);
      await this.$http.post(
        this.endpoint.aditive.uploadSignedContractAdditive + `/${this.additiveById.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `${token}`,
          },
        }
      );
      await this.getAdditiveById(this.additiveById.id);
    } catch (error: any) {
      console.log('Ocorreu um erro ao subir um contrato assinado');
    }
    this.loadingSignedContract = false;    
  }

  async getFinancialBudget() {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(this.endpoint.financialBudget.base, {
        headers: { 
          Authorization: `${token}`,
          "lesteKey": this.endpoint.auth.fintiServicesKey!,
          "API-Key": `${this.endpoint.auth.fintiKey}`,
        },
      });
      this.financialBudgetData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de planos financeiros', error);
    }
  }

  async getCostCenters(companyId: number) {
    const token = await this.authService.getIdToken();
    try {
      const response = await this.$http.get(
        this.endpoint.costCenter.base + `/${companyId}`,
        {
          headers: { 
            Authorization: `${token}`,
            "lesteKey": this.endpoint.auth.fintiServicesKey!,
          },
        }
      );
      this.costCenterData = response;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de cost centers', error);
    }
  }

  async removeSignedContract(data: { id: string, documentId: string, isAdditive: boolean }) {
    this.removingSignedContract = true;
    try {
      const token = await this.authService.getIdToken();
      await this.$http.patch(
        this.endpoint.contract.removeSignedContract + `/${data.documentId}`,
        {},
        {
          headers: { Authorization: `${token}` },
        }
      );
      
      await this.getAdditiveById(data.id);      
    } catch (error: any) {
      // console.log('Ocorreu um erro ao deletar um contrato assinado');
    }
    this.removingSignedContract = false;
  }

  async editAdditive(payload: EditAdditivePayload) {    
    this.editingAdditive = true;
    const token = await this.authService.getIdToken();
    const formData = new FormData();

    try {
      for (const [key, value] of Object.entries(payload)) {
        if (key === 'listDocumentsToRemove') {
          payload.listDocumentsToRemove?.find((item) =>
            formData.append('listDocumentsToRemove', item)
          );
        }
        if (key === 'documents') {
          payload.documents?.forEach((item) => formData.append('Documents', item));
        } else {
          if (value !== null && value !== undefined)
            formData.append(key, value);
        }
      }

      await this.$http.put(this.endpoint.aditive.base, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`,
        },
      });

      this.closeModalEdit();
      this.hasError = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Additive successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
      await this.getAdditiveById(this.additiveById.id);
    } catch (error) {
      console.log('ocorreu um erro ao editar este aditivo', error);
      this.hasError = true;
      this.textError = setErrorText(error)
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: this.textError,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 8000);
    }
    this.editContractModal++;
    this.editingAdditive = false;

    this.$emit('refreshContract');
  }

  async approveAditive(approveField: string) {
    this.approvingAdditive = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        additiveId: this.additiveById.id,
        feedback: approveField,
      };
      const response = await this.$http.patch(this.endpoint.aditive.approve, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalApprove();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully approved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getAdditiveById(this.additiveById.id);
      }
    } catch (error: any) {
      this.closeModalApprove();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error)
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 8000);
    }
    this.approvingAdditive = false;
    this.$emit('refreshContract');
  }

  async reproveAditive(reproveField: string) {
    this.reprovingAdditive = true;
    try {
      const token = await this.authService.getIdToken();
      const params = {
        additiveId: this.additiveById.id,
        feedback: reproveField,
      };
      const response = await this.$http.patch(this.endpoint.aditive.reprove, params, {
        headers: { Authorization: `${token}` },
      });

      if (response.data !== null) {
        this.closeModalReprove();
        this.setFeedback({
          feedback: true,
          isError: false,
          color: 'green',
          text: 'Contract Successfully reproved',
        });
        setTimeout((): void => {
          this.setFeedback({
            feedback: false,
          });
        }, 3000);
        await this.getAdditiveById(this.additiveById.id);
      }
    } catch (error: any) {
      this.closeModalReprove();
      this.setFeedback({
        feedback: true,
        isError: true,
        color: '#ad1318',
        text: setErrorText(error)
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 3000);
    }
    this.reprovingAdditive = false;
  }

  async getManagers() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.managers, {
        headers: { Authorization: `${token}` },
      });
      this.managers = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de gestores', error);
    }
  }

  async getVendors() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.vendor, {
        headers: { Authorization: `${token}` },
      });
      this.vendors = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de vendors', error);
    }
  }

  async getPaymentMethods() {
    const token = await this.authService.getIdToken();
    try {
      const { data } = await this.$http.get(this.endpoint.selects.paymentMethods, {
        headers: { Authorization: `${token}` },
      });
      this.paymentMethods = data;
    } catch (error) {
      console.log('Ocorreu um erro ao obter lista de tipo de pagamentos', error);
    }
  }

  showEditAlocationModal(item: Alocation) {
    // this.isEditAlocation = true;
    // this.alocationItems = item;
    // this.showNewAlocationModal = true;
    this.showViewModal = false;
  }
  showContractSignedModal() {
    // this.showModalContractSigned = true;
    this.showViewModal = false;
  }
  showAproveModal() {    
    this.approveMember = this.additiveById.permission.approveMember;     
    this.showModalApprove = true;
    this.showViewModal = false;
  }
  openEditAdditiveModal() {    
    this.showModalEdit = true;
  }
  closeViewModal() {
    this.showViewModal = false;
  }
  closeModalApprove() {
    this.showModalApprove = false;
  }
  closeModalReprove() {
    this.showReproveModal = false;
  }
  closeModalEdit() {
    this.showModalEdit = false;
  }
}
