
import { Component, Prop, Vue } from 'vue-property-decorator';

import ContractDetail from '@/types/contractDetail';

import RadialProgressBar from 'vue-radial-progress';

import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
@Component({ components: { RadialProgressBar } })
export default class FinancialGraph extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(String) type!: string;

  formatMoney = FormatMoney;
  formatDate = FormatDate;

  get totalContractSteps() {
    if (this.contract.contractValue < this.contract.usedContractValue)
      return this.contract.usedContractValue;
    return this.contract.contractValue;
  }

  get stopColor() {
    if (this.contract.contractValue < this.contract.usedContractValue)
      return '#b01221';
    return '#08cc6a';
  }

  setCompletedStepsColor() {
    // TODO: Fazer com que a tiágem aceite tanto os dados do contrato quanto os dados do aditivo
    if (this.contract.contractValue === null && this.contract.deadlineContract === null)
      return 'rgba(229, 235, 241, 0.3);';
    if (this.contract.statusTitle === 'Blocked') return '#F50E0E';
    if (this.contract.statusTitle === 'Open') return '#0AB48C';
  }

  setContractValue(contractValue: number | null,currency:string) {
    if (contractValue === null) return 'Sem limite';
    return this.formatMoney(contractValue,currency);
  }

  setContractDeadline(contractDeadline: string | null) {
    if (contractDeadline === null) return 'Sem prazo';
    return this.formatDate(contractDeadline);
  }
}
