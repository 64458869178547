import { EmptyPermission, VendorPermission } from './VendorPermission';
import { FeedBack, Vendor } from './contractsById';
import ServiceResponse from './services/response';

export default interface PaymentById {
  id: string;
  title: string;
  description: string;
  paymentTypeId: string;
  paymentType: string;
  paymentValue: number;
  statusTitle: string;
  documentTypeId: number;
  documentNumber: string;
  paymentMethodId: number;
  editableRow: string;
  currency: string;
  totalAmount: number;
  issueDate: string;
  expirationDate: string;
  contractId: string;
  contractTitle: string;
  management: string;
  deadlineContract: string;
  contractBalance: number;
  contractTotalValue: number;
  createdAt: string;
  documents: Documents[];
  vendor: Vendor;
  permission: VendorPermission;
  feedBacks: FeedBack[];
  services: ServiceResponse[];
}

export interface Documents {
  id: string;
  contractId: string;
  fileName: string;
  sharepointPath: string;
  fileExtension: string;
  type: string;
  createdAt: string;
}

export interface Services {
  id: string;
  description: string;
  costPerHour: number;
  hoursAmount: number;
  total: number;
  requesterEntityId: string;
  payingCompany: number;
  buRequester: string;  
  paymentDate: string;
  runTime: string;
  createdAt: string;
  costCenterAllocations: Alocation[];
  isVisible?: boolean;
  groupKey?: number | null;
  approversId: number[];
  approvalModeId: number;
}

export interface Alocation {
  id: string;
  costCenterId: number;
  costCenterName: string;
  financialBudgetId: number;
  financialBudgetName: string;
  percentage: number;
}

const paymentByIdInitialValue: PaymentById = {
  id: '',
  title: '',
  description: '',
  paymentTypeId: '',
  paymentType: '',
  paymentValue: 0,
  statusTitle: '',
  documentTypeId: 0,
  documentNumber: '',
  paymentMethodId: 0,
  editableRow: '',
  currency: '',
  totalAmount: 0,
  issueDate: '',
  expirationDate: '',
  contractId: '',
  contractTitle: '',
  management: '',
  deadlineContract: '',
  contractBalance: 0,
  contractTotalValue: 0,
  createdAt: '',
  documents: [],
  vendor: {
    entityId: '',
    companyName: '',
    shortName: '',
    identifier: '',
    jurisdictionId: '',
    jurisdictionOfOperations: [],
    activityTypes: [],
    formOrganizationId: '',
  },
  permission: EmptyPermission,
  feedBacks: [],
  services: [],
};

export { paymentByIdInitialValue };
