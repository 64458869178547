export default interface Contracts {
  id: string;
  title: string;
  workflowId: string;
  companyId: number;
  contractNumber: string;
  deadlineContract: string;
  contractValue: number;
  description: string;
  paymentMethodId: number;
  lesteUserEmail: string;
  isAdditive: boolean;
  statusTitle: string;
  additiveId: string;
  approverId: number;
  approverName: string;
  secondApproverId: number;
  secondApproverName: string;
  currency: string;
  financeCanApprove: boolean;
}

const contractInitialValue: Contracts = {
  id: '',
  title: '',
  workflowId: '',
  companyId: 0,
  contractNumber: '',
  deadlineContract: '',
  contractValue: 0,
  description: '',
  paymentMethodId: 0,
  lesteUserEmail: '',
  isAdditive: false,
  statusTitle: '',
  additiveId: '',
  approverId: 0,
  approverName: '',
  secondApproverId: 0,
  secondApproverName: '',
  currency: 'USD',
  financeCanApprove: false,
};

export { contractInitialValue };
