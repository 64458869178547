export default interface Selects {
  id?: string | number;
  name: string;
  email?: string;
  siengeValue?: string;
  disabled?: boolean;
  isChecked?: boolean;
  entityId?: string;
  vendorData?: VendorBank;
  dataBank?: DataBank;
  businessUnityId?: string;
  flag?: boolean;
  order?: number;
}

export interface FintiCompany {
  id: number;
  name: string;
  cnpj: string | null,
  siengeId: number | null,
  fundAdministratorId: number | null,
  fundAdministratorName: string | null,
  fundAdministratorExternalAdm: boolean | null,
  enabled: boolean,
  requiresSecondApprover: boolean
}

export interface FintiApprover {
  id: number;
  approverName: string;
  departmentName: string | null;	
}

export interface VendorBank {
  entityId: string;
  name: string;
}

export interface DataBank {
  bank: string;
  agency: string;
  account: string;
  agencyDigit: string;
  accountDigit: string;
}

const selectsInitialValue: Selects = {
  id: '',
  name: '',
  email: '',
  siengeValue: '',
  entityId: '',
  disabled: false,
  isChecked: false,
  vendorData: {
    entityId: '',
    name: '',
  },
  dataBank: {
    bank: '',
    agency: '',
    account: '',
    agencyDigit: '',
    accountDigit: '',
  },
};

export { selectsInitialValue };
