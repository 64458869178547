export default interface Alocation {
  id?: string;
  costCenterId: number;
  financialBudgetId: number;
  percentage: number;
}

const alocationInitialValue: Alocation = {
  id: '',
  costCenterId: 0,
  financialBudgetId: 0,
  percentage: 0,
};

export { alocationInitialValue };
