import { ActionsState } from './types';

import User from '@/types/auth/user';
import Area from '@/types/auth/areas';
import CurrentRoute from '@/types/auth/currentRoute';
import Feedback from '@/types/general/feedback';

export default {
  getUser(state: ActionsState): User {
    return state.user;
  },

  getAreas(state: ActionsState): Area[] {
    return state.areas !== null ? state.areas : [];
  },

  getIslogged(state: ActionsState): boolean {
    return state.user != null ? true : false;
  },

  getCurrentRoute(state: ActionsState): CurrentRoute {
    return state.currentRoute;
  },
  getCanList(state: ActionsState): boolean {
    return state.canList;
  },

  getCanApprove(state: ActionsState): boolean {
    return state.canApprove;
  },

  getCanCreate(state: ActionsState): boolean {
    return state.canCreate;
  },

  getCanDelete(state: ActionsState): boolean {
    return state.canDelete;
  },

  getCanUpdate(state: ActionsState): boolean {
    return state.canUpdate;
  },
  getCanApproveDenied(state: ActionsState): boolean {
    return state.canApproveDenied;
  },
  getIsCollaborator(state: ActionsState): boolean {
    return state.isCollaborator;
  },
  getIsApprover(state: ActionsState): boolean {
    return state.isApprover;
  },
  getIsApproverMaster(state: ActionsState): boolean {
    return state.isApproverMaster;
  },
  getIsVendor(state: ActionsState): boolean {
    return state.isVendor;
  },
  getModalApproveState(state: ActionsState): boolean {
    return state.modalApprove;
  },
  getModalDenyState(state: ActionsState): boolean {
    return state.modalDeny;
  },
  getModalApproveDeniedState(state: ActionsState): boolean {
    return state.modalApproveDenied;
  },
  getModalPendencyState(state: ActionsState): boolean {
    return state.modalPendency;
  },
  getModalPendencyCommentState(state: ActionsState): boolean {
    return state.modalPendencyComment;
  },
  getModalBackDocuments(state: ActionsState): boolean {
    return state.modalBackDocuments;
  },
  getDisclaimerForm(state: ActionsState): boolean {
    return state.disclaimerForm;
  },
  getOnboardingId(state: ActionsState): string {
    return state.onboardingId;
  },
  getFeedback(state: ActionsState): Feedback {
    return state.feedback;
  },
  getPendencyComment(state: ActionsState): string {
    return state.pendencyComment;
  },
};
