var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"financial-dashboard-wrapper mt-8"},[_c('p',{staticClass:"financial-dashboard-title"},[_vm._v("Financial")]),_c('div',{staticClass:"financial-dashboard-graph"},[_c('radial-progress-bar',{staticClass:"mt-9 mb-10",attrs:{"total-steps":_vm.totalContractSteps,"completed-steps":_vm.contract.usedContractValue,"diameter":192,"strokeWidth":13,"innerStrokeWidth":13,"startColor":_vm.stopColor,"stopColor":_vm.stopColor,"innerStrokeColor":"#e5f1ef"}},[_c('div',{staticClass:"d-flex flex-column mt-2"},[_c('p',{staticClass:"financial-dashboard-amount mb-0"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.contract.usedContractValue,_vm.contract.currency))+" ")]),_c('span',{staticClass:"financial-dashboard-description"},[_vm._v("Used")])])])],1),_c('div',{staticClass:"financial-dashboard-details mt-4"},[_c('v-row',{staticClass:"financial-dashboard-detail"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"financial-dashboard-detail-title"},[_vm._v("Contract Value")]),_c('p',{staticClass:"financial-dashboard-detail-value"},[_vm._v(" "+_vm._s(_vm.setContractValue(_vm.contract.contractValue,_vm.contract.currency))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"financial-dashboard-detail-title"},[_vm._v("Used value")]),_c('p',{staticClass:"financial-dashboard-detail-value",class:{
            'open-value': _vm.contract.statusTitle === 'Open' || _vm.contract.usedContractValue <= _vm.contract.contractValue,
            'blocked-value': _vm.contract.statusTitle === 'Blocked' || _vm.contract.usedContractValue > _vm.contract.contractValue,
          }},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.contract.usedContractValue,_vm.contract.currency))+" ")])])],1),_c('v-row',{staticClass:"financial-dashboard-detail"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"financial-dashboard-detail-title"},[_vm._v("Remaining amount")]),_c('p',{staticClass:"financial-dashboard-detail-value",class:{
              'open-value': _vm.contract.usedContractValue <= _vm.contract.contractValue,
              'blocked-value': _vm.contract.usedContractValue > _vm.contract.contractValue,
            }},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.contract.restContractValue,_vm.contract.currency))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('p',{staticClass:"financial-dashboard-detail-title"},[_vm._v("Runtime")]),_c('p',{staticClass:"financial-dashboard-detail-value"},[_vm._v(" "+_vm._s(_vm.setContractDeadline(_vm.contract.deadlineContract))+" ")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }