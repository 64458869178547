const vendorHeaders = [
  {
    text: 'Contract name',
    align: 'start',
    sortable: true,
    value: 'title',
    isVisible: true,
  },
  {
    text: 'Leste Company',
    align: 'start',
    sortable: true,
    value: 'lesteCompany',
    isVisible: true,
  },
  {
    text: 'Accountable Manager',
    align: 'start',
    sortable: true,
    value: 'owner',
    isVisible: true,
  },
  {
    text: 'Contract Value',
    align: 'start',
    sortable: true,
    value: 'contractValue',
    isVisible: true,
  },
  {
    text: 'Runtime',
    align: 'start',
    sortable: true,
    value: 'deadlineContract',
    isVisible: true,
  },
  {
    text: 'Status',
    align: 'start',
    sortable: true,
    value: 'status',
    isVisible: true,
    width: '15%',
  },
];

const managementHeaders = [
  {
    text: 'Contract name',
    align: 'start',
    sortable: true,
    value: 'title',
    isVisible: true,
  },
  {
    text: 'Vendor',
    align: 'start',
    sortable: true,
    value: 'vendorEmail',
    isVisible: true,
  },
  {
    text: 'Leste Company',
    align: 'start',
    sortable: true,
    value: 'lesteCompany',
    isVisible: true,
  },
  {
    text: 'Contract Value',
    align: 'start',
    sortable: true,
    value: 'contractValue',
    isVisible: true,
  },
  {
    text: 'Runtime',
    align: 'start',
    sortable: true,
    value: 'deadlineContract',
    isVisible: true,
  },
  {
    text: 'Status',
    align: 'start',
    sortable: true,
    value: 'status',
    isVisible: true,
    width: '15%',
  },
];

const financeHeaders = [
  {
    text: 'Contract name',
    align: 'start',
    sortable: true,
    value: 'title',
    isVisible: true,
  },
  {
    text: 'Vendor',
    align: 'start',
    sortable: true,
    value: 'vendorEmail',
    isVisible: true,
  },
  {
    text: 'Leste Company',
    align: 'start',
    sortable: true,
    value: 'lesteCompany',
    isVisible: true,
  },
  {
    text: 'Accountable Manager',
    align: 'start',
    sortable: true,
    value: 'owner',
    isVisible: true,
  },
  {
    text: 'Contract Value',
    align: 'start',
    sortable: true,
    value: 'contractValue',
    isVisible: true,
  },
  {
    text: 'Runtime',
    align: 'start',
    sortable: true,
    value: 'deadlineContract',
    isVisible: true,
  },
  {
    text: 'Status',
    align: 'start',
    sortable: true,
    value: 'status',
    isVisible: true,
    width: '15%',
  },
];

const ContractHeaders = {
  Vendor: vendorHeaders,
  Management: managementHeaders,
  Finance: financeHeaders,
};

export default ContractHeaders;
