
import DocumentData from '@/components/contracts/DocumentData.vue';
import FileDisplay from '@/components/contracts/FileDisplay.vue';
import EditingModal from '@/components/Modals/EditingModal.vue';
import ModalApprove from '@/components/Modals/ModalApprove.vue';
import ModalCancel from '@/components/Modals/ModalCancel.vue';
import ModalNewAlocation from '@/components/Modals/ModalNewAlocation.vue';
import ModalSend from '@/components/Modals/ModalSend.vue';
import { AuthService } from '@/services/authService';
import AdditiveById from '@/types/additiveById';
import Alocation from '@/types/alocation';
import ContractByid, { contractByIdInitialValue } from '@/types/contractsById';
import CostCenter from '@/types/costCenter';
import FinancialBudget from '@/types/financialBudget';
import RemoveDocumentPayload from '@/types/removeDocumentPayload';
import Selects from '@/types/selects';
import Endpoint from '@/utils/endpoint';
import FormatDate from '@/utils/formatDate';
import FormatMoney from '@/utils/formatMoney';
import setStatusClass from '@/utils/setStatus';
import qs from 'qs';
import VueSkeletonLoader from 'skeleton-loader-vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CONTRACT_STATUS } from '@/types/general/contractStatus';
import CurrencyData, { currencyFormater } from '@/utils/currencyData';
@Component({
  components: {
    DocumentData,
    FileDisplay,
    ModalApprove,
    EditingModal,
    ModalNewAlocation,
    ModalSend,
    ModalCancel,
    VueSkeletonLoader,
  },
})
export default class ViewModal extends Vue {
  @Getter('getUserRoles', { namespace: 'permissions' })
  userRoles!: string[];

  @Prop(Object) photos!: { [key: string]: string };
  @Prop(Boolean) showViewModal!: boolean;
  @Prop(Boolean) isAdditive!: boolean;
  @Prop(Boolean) showSkeleton!: boolean;
  @Prop(Boolean) approvingAdditive!: boolean;
  @Prop(Boolean) loadingSignedContract!: boolean;
  @Prop(Boolean) isSignedContract!: boolean;
  @Prop(Boolean) loadingRemoveDocument!: boolean;
  @Prop(Boolean) removingAllocation!: boolean;
  @Prop(String) additiveId!: string;
  @Prop(Object) contractsById!: ContractByid;
  @Prop(Object) additiveById!: AdditiveById;
  @Prop(Array) initialCompanies!: Selects[];
  @Prop(Array) jurisdictions!: Selects[];
  @Prop(Object) vendorBankData!: Selects;
  @Prop(Array) approverData!: Selects[];
  @Prop(Array) paymentMethods!: Selects[];
  @Prop(Number) alocationPercentageValue!: number;

  @Prop(Array) costCenterData!: CostCenter[];
  @Prop(Array) financialBudgetData!: FinancialBudget[];

  @Watch('showSkeleton')
  getApproverByContractCompany() {
    this.$emit('getApproverByContractCompany');
  }

  @Watch('additiveById.status', { deep: true })
  handleSetAdditiveTab() {
    if (!this.showViewModal) return;
    if (!this.isAdditive) return;
    switch (this.additiveById.status) {
      case CONTRACT_STATUS.DIGITAL_SIGNATURE:
        this.tab = 3;
        break;
      default:
        this.tab = 0;
        break;
    }
  }

  @Watch('contractsById.status', { deep: true })
  handleSetContractTab() {
    if (!this.showViewModal) return;
    if (this.isAdditive) return;
    switch (this.contractsById.statusTitle) {
      case CONTRACT_STATUS.DIGITAL_SIGNATURE:
        this.tab = 3;
        break;
      default:
        this.tab = 0;
        break;
    }
  }

  @Watch('contractsById.documents', { deep: true })
  handleContractsDocument() {
    this.alreadExistSignedContract = this.contractsById.documents.some(
      (item) => item.type === 'Signed Contract'
    );

    this.alreadExistDocumentAdditional = this.contractsById.documents.some(
      (item) => item.type === 'Additional'
    );
  }
  @Watch('additiveById.documents', { deep: true })
  handleAdditivesDocument() {
    this.alreadyExistsSignedAdditive = this.additiveById.documents.some(
      (item) => item.type === 'Signed Contract'
    );

    this.alreadyExistsAdditionalDocumentAdditive = this.additiveById.documents.some(
      (item) => item.type === 'Additional'
    );
  }
  formatDate = FormatDate;
  formatMoney = FormatMoney;
  setStatusClass = setStatusClass;
  authService = new AuthService();
  private endpoint = new Endpoint();

  tab = 0;
  addAlocation = false;
  addContractSign = false;
  showModalCancel = false;
  showModalSend = false;
  showSendField = false;
  showModalEdit = false;
  showNewAlocationModal = false;
  addSignedContracts = false;
  isDraggingContract = false;
  alreadExistSignedContract = false;
  alreadExistDocumentAdditional = false;
  alreadyExistsSignedAdditive = false;
  alreadyExistsAdditionalDocumentAdditive = false;
  feedbackText = '';
  btnText = '';
  uploadContractInput = 0;
  indexAllocationWasRemoving = 0;
  currencyFormater = currencyFormater;
  files: Blob = new Blob();
  get isOnlyViewer() {
    if (this.userRoles === undefined) return true;
    const result =
      this.userRoles.some((r) => r.toLowerCase().includes('financeviewer')) &&
      !this.userRoles.some(
        (r) => r.toLowerCase() === 'vendor.finance' || r.toLowerCase() === 'vendor.management'
      );
    return result;
    //return this.userRoles.includes('Vendor.Finance');
  }

  statusName(item: string) {
    if (item === 'InProcess') {
      return 'Internal Process';
    } else {
      return item;
    }
  }
  get contractStatus() {
    return this.isAdditive ? this.additiveById.status : this.contractsById.statusTitle;
  }

  get isVendor() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Vendor');
  }

  get isFinance() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Finance');
  }

  get isManagement() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Management');
  }

  get isLegal() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Legal');
  }

  get isLineManager() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.LineManager');
  }

  get isApproverMaster() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.ApproverMaster');
  }

  get isApprover() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Approver');
  }

  get isCollaborator() {
    if (this.userRoles === undefined) return false;
    return this.userRoles.includes('Vendor.Approver');
  }

  get showAllocationTab() {
    if (this.isFinance) return true;
    if (this.isVendor) return false;
    if (this.isAdditive && !this.isManagement) return false;
    if (this.contractStatus === 'Canceled') return false;
    return true;
  }

  get showFeedbackTab() {
    const feedbacks = this.isAdditive ? this.additiveById.feedBacks : this.contractsById.feedBacks;
    return feedbacks.length !== 0;
  }

  get setDocumentWidth() {
    const permissions = this.isAdditive
      ? this.additiveById.permission
      : this.contractsById.permission;
    if (this.alreadExistSignedContract) return 'contract-file';
    if (!permissions.userCanInsertSignedContract) return 'contract-file';
    return 'file-name';
  }

  get approvers() {
    if (this.isAdditive)
      return this.additiveById.contract.approvers.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    return this.contractsById.approvers.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  }

  get approverName() {
    if (this.isAdditive) return this.additiveById.contract.approverName ?? '--';
    return this.contractsById.approverName ?? '--';
  }

  get secondApproverName() {
    if (this.isAdditive) return this.additiveById.contract.secondApproverName ?? '--';
    return this.contractsById.secondApproverName ?? '--';
  }

  get costCenterAllocations() {
    if (this.isAdditive) return this.additiveById.contract.costCenterAllocations;
    return this.contractsById.costCenterAllocations;
  }

  getCostCenterName(costCenter: number) {
    const costCenterName = this.costCenterData.find((item) => item.id === costCenter)?.name;
    return costCenterName !== undefined ? costCenterName : '--';
  }

  getFinancialBudgetName(financial: number) {
    const financialBudgetName = this.financialBudgetData.find(
      (item) => item.id === financial
    )?.name;

    return financialBudgetName !== undefined ? financialBudgetName : '--';
  }

  getApproverName(approverId: number) {
    const approverName = this.approverData.find((approver) => approver.id === approverId)?.name;
    return approverName !== undefined ? approverName : '--';
  }

  async handleDownloadFile(fileData: any) {
    try {
      const token = await this.authService.getIdToken();
      const response = await this.$http.get(this.endpoint.files.download, {
        params: {
          fileUrl: fileData.fileUrl,
        },
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          'content-type': 'blob',
          Authorization: `${token}`,
        },
        responseType: 'blob',
      });

      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        const result: string | ArrayBuffer = reader.result ? reader.result : '';
        const downloadLink = document.createElement('a');
        downloadLink.href = result.toString();
        downloadLink.download = fileData.fileName;
        downloadLink.click();
      };
    } catch (error) {
      console.log('Ocorreu um erro ao baixar arquivo', error);
    }
  }

  approveAditive() {
    this.$emit('approveAditive', this.additiveId);
  }

  approveContract() {
    this.$emit('approveContract', this.contractsById.id);
  }

  reproveAditive() {
    this.$emit('reproveAditive', this.additiveId);
  }

  showEditAlocationModal(item: Alocation, index: number) {
    this.$emit('showEditAlocationModal', item);
    this.$emit('setIndexAllocationWasEditing', index);
  }

  removeAlocation(item: Alocation, index: number) {
    this.indexAllocationWasRemoving = index;
    this.$emit('removeAlocation', item);
  }

  getPaymentType(paymentTypeId: number) {
    const paymentType = this.paymentMethods.find((item) => item.id === paymentTypeId)?.name;

    return paymentTypeId ? paymentType : '--';
  }

  setMainJurisdiction(jurisdictionId: string) {
    if (jurisdictionId === null) return '--';

    const mainJurisdiction = this.jurisdictions.find((item) => item.id === jurisdictionId)?.name;

    return mainJurisdiction === undefined ? '--' : mainJurisdiction;
  }

  setSecondaryJurisdiction(jurisdictions: Selects[]) {
    if (!jurisdictions.length) return '--';

    return jurisdictions.map((item) => item.name).join(', ');
  }

  setActivityTypes(activity: Selects[]) {
    if (!activity.length) return '--';

    return activity.map((item) => item.name).join(', ');
  }

  setDocumentFileName(document: any[]) {
    // if (!document.length) return '--';
    const name = document.map((item) => item.fileName);
    const extension = document.map((item) => item.fileExtension);
    return `${name}${extension}`;
  }

  setDocumentFileDownload(document: any[]) {
    // if (!document.length) return '--';
    const url = document.map((item) => item.sharepointPath);
    return url;
  }

  formatCNPJ(identifier: string) {
    const cnpj = identifier.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return cnpj;
  }

  setVendorType(onShore: boolean | null) {
    if (onShore === null) return '--';

    return onShore ? 'Brazil' : 'Non Brazil';
  }

  getCompanyByCompanyId(companyId: number) {
    const companyName = this.initialCompanies.find((company) => company.id === companyId)?.name;
    return companyName !== undefined ? companyName : '--';
  }

  async setContractSignedFile() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractFile as any).files[0] as File;
    this.$emit('setContractSignedFile', this.files);
  }

  async setContractFileAdditive() {
    this.uploadContractInput++;
    this.files = (this.$refs.contractFile as any).files[0] as File;
    this.$emit('setContractFileAdditive', this.files);
  }

  contractDragover(e: any) {
    e.preventDefault();
    this.isDraggingContract = true;
  }

  contractDragleave() {
    this.isDraggingContract = false;
  }

  dropContractFile(e: any) {
    e.preventDefault();
    (this.$refs.contractFile as any).files = e.dataTransfer.files;
    this.setContractSignedFile();
    this.isDraggingContract = false;
  }

  dropContractFileAdditive(e: any) {
    e.preventDefault();
    (this.$refs.contractFile as any).files = e.dataTransfer.files;
    this.setContractFileAdditive();
    this.isDraggingContract = false;
  }

  removeFile(item: RemoveDocumentPayload) {
    let data: {};
    if (this.isAdditive) {
      data = {
        id: this.additiveById.id,
        documentId: item.documentId,
        isAdditive: true,
      };
    } else {
      data = {
        id: this.contractsById.id,
        documentId: item.documentId,
        isAdditive: false,
      };
    }
    this.$emit('removeFile', data);
  }

  showCancelModal() {
    this.$emit('showCancelModal');
  }

  showAproveModal() {
    this.$emit('showAproveModal');
  }

  showContractSignedModal() {
    this.$emit('showContractSignedModal');
  }

  showModalReprove() {
    this.$emit('showModalReprove');
  }

  showSendModal() {
    this.showModalSend = true;
    this.showViewModal = false;
  }

  showEditModal() {
    this.closeModal(); // fechar o VIEWMODAL
    this.$emit('openEditContractModal', this.isAdditive); // emitir a abertura do modal de ação
    // this.showViewModal = false;
  }

  showModalNewAlocation() {
    this.$emit('showModalNewAlocation');
  }

  showModalAddApprover() {
    const companyId = this.isAdditive
      ? this.additiveById.contract.companyId
      : this.contractsById.companyId;
    this.$emit('showModalAddApprover', companyId);
  }

  editApprover() {
    const companyId = this.isAdditive
      ? this.additiveById.contract.companyId
      : this.contractsById.companyId;
    this.$emit('editApprover', companyId);
  }

  closeModalSend() {
    this.showModalSend = false;
    this.showViewModal = true;
  }

  closeModalEdit() {
    this.showModalEdit = false;
    this.showViewModal = true;
  }

  closeModal() {
    this.$emit('closeModalView');
    this.contractsById = contractByIdInitialValue;
  }

  isStatusForEditing() {
    if (this.isAdditive)
      return (
        this.additiveById.status === 'Owner Review' || this.additiveById.status === 'Finance Review'
      );
    return (
      this.contractsById.statusTitle === 'Owner Review' ||
      this.contractsById.statusTitle === 'Finance Review'
    );
  }

  hasApprover(): boolean {
    if (this.isAdditive) return this.additiveById.contract.approvers.length > 0;
    return this.contractsById.approvers.length > 0;
  }

  hasSecondApprover(): boolean {
    if (this.isAdditive)
      return (
        this.additiveById.contract.secondApproverId !== null &&
        this.additiveById.contract.secondApproverId !== 0
      );
    return (
      this.contractsById.secondApproverId !== null && this.contractsById.secondApproverId !== 0
    );
  }

  hasAllocations(): boolean {
    if (this.isAdditive) return this.additiveById.contract.costCenterAllocations.length > 0;
    return this.contractsById.costCenterAllocations.length > 0;
  }

  canSetApprover(): boolean {
    if (this.isAdditive) return false;
    return (
      this.contractsById.approverId === null &&
      this.contractsById.permission.userCanCreateAllocation
    );
  }

  canAddAllocations(): boolean {
    if (this.isAdditive) return false;
    return (
      this.contractsById.permission.userCanCreateAllocation || this.alocationPercentageValue < 100
    );
  }
}
