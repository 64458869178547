
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import VueSkeletonLoader from 'skeleton-loader-vue';

@Component({
  components: { VueSkeletonLoader },
})
export default class NewOnboarding extends Vue {
  @Prop(String) type!: string;

  setSkeletonHeight() {
    if (this.type === 'file') return 64;
    if (this.type === 'aditive') return 46;
    return 18;
  }

  setSkeletonSize() {
    if (this.type === 'x-small') return 85;
    if (this.type === 'small') return 150;
    if (this.type === 'medium') return 210;
    if (this.type === 'large') return 280;
    if (this.type === 'x-large') return 410;
    if (this.type === 'file') return 520;
    if (this.type === 'aditive') return 520;
  }
}
