import Selects from './selects';

export default interface FilterPayload {
  status: string[];
  startDeadlineDate: string;
  endDeadlineDate: string;
  startValue: number | null;
  endValue: number | null;
  listCompanies: Selects[];
  managersSelected: Selects[];
  listVendors: Selects[];
}

const filterPayloadInitialValue: FilterPayload = {
  status: [],
  startDeadlineDate: '',
  endDeadlineDate: '',
  startValue: null,
  endValue: null,
  listCompanies: [],
  managersSelected: [],
  listVendors: [],
};

export { filterPayloadInitialValue };
