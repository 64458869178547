
import AsyncComputedPlugin from 'vue-async-computed';
import AsyncComputed from 'vue-async-computed-decorator';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

Vue.use(AsyncComputedPlugin);

import OnboardingHeader from '@/components/headers/onboarding-headers';
import Headers from '@/types/general/headers';
import OnboardingInterface from '@/types/onboarding';

import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import FormatDate from '@/utils/formatDate';
import SetOnboardingType from '@/utils/setOnboardingType';
import SetStatusText from '@/utils/setStatusText';

@Component({})
export default class Onboarding extends Vue {
  @Action('verifyUserPermissions', { namespace: 'permissions' })
  verifyUserPermissions!: any;

  @Getter('getIsVendor', { namespace: 'modals' }) isVendor!: boolean;

  @Prop(String) public token!: string | null;

  canCreate = false;
  canList = false;

  loading = false;
  search = '';
  public onboardingStatus = [
    { text: 'All status', value: 0 },
    { text: 'Pending Vendor', value: 1 },
    { text: 'Requested', value: 2 },
    { text: 'Review Vendor', value: 3 },
    { text: 'Review Leste', value: 4 },
    { text: 'Approved', value: 5 },
    { text: 'Denied', value: 6 },
  ];
  public onboardingStatusSelected = { text: 'All status', value: 0 };

  public onboardingHeaders: Headers[] = OnboardingHeader.header;

  public onboardingItems: OnboardingInterface[] = [];

  private endpoint = new Endpoint();
  authService = new AuthService();
  formatDate = FormatDate;
  setOnboardingType = SetOnboardingType;
  setStatusText = SetStatusText;

  @AsyncComputed()
  async getCanCreate() {
    this.canCreate = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'Create',
    });
  }

  @AsyncComputed()
  async getCanList() {
    this.canList = await this.verifyUserPermissions({
      resource: 'Onboarding',
      action: 'List',
    });
  }

  public setStatusClass(status: number): string | undefined {
    if (status === 1) return 'pending-vendor';
    if (status === 2) return 'requested';
    if (status === 3) return 'review-vendor';
    if (status === 4) return 'review-leste';
    if (status === 5) return 'approved';
    if (status === 6) return 'denied';
  }

  public goToInner(onboarding: OnboardingInterface) {
    if (onboarding.onboardingStatus === 1) {
      this.$router.push({ name: 'ViewOnboardingVendor', params: { id: onboarding.id } });
    } else {
      this.$router.push({ name: 'ViewOnboarding', params: { id: onboarding.id } });
    }
  }

  public newOnboarding(): void {
    this.$router.push({ name: 'NewOnboarding' });
  }

  @Watch('onboardingStatusSelected')
  @Watch('canList')
  public async getOnboarding(): Promise<void> {
    if (this.canList === false) return;

    const token = await this.authService.getIdToken();

    this.loading = true;
    const params = {
      Status: this.onboardingStatusSelected.value,
    };
    try {
      const onboardings = await this.$http.get(this.endpoint.onboarding.base, {
        params,
        headers: { Authorization: `${token}` },
      });
      this.onboardingItems = onboardings.data.items;
    } catch (error) {
      console.log('Ocorreu um erro ao listar onboardings');
    }
    this.loading = false;
  }

  public async mounted() {
    this.getOnboarding();
  }
}
