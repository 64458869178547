
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import SkeletonLoader from '@/components/SkeletonLoader.vue';

import ContractDetail, { contractDetailInitialValue } from '@/types/contractDetail';
import CostCenter from '@/types/costCenter';
import FinancialBudget from '@/types/financialBudget';

@Component({ components: { SkeletonLoader } })
export default class AlocationData extends Vue {
  @Prop(Object) contract!: ContractDetail;
  @Prop(Boolean) gettingContract!: boolean;
  @Prop(Array) costCenterData!: CostCenter[];
  @Prop(Array) financialBudgetData!: FinancialBudget[];

  setAllocationIdentifier(key: number) {
    return `Allocation ${key + 1}`;
  }


}
