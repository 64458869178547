
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import User from '@/types/auth/user';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({})
export default class ModalDeny extends Vue {
  @Getter('getOnboardingId', { namespace: 'modals' }) public onboardingId!: boolean;
  @Getter('getUser', { namespace: 'modals' }) user!: User;

  @Getter('getIsVendor', { namespace: 'modals' }) isVendor!: boolean;
  @Getter('getIsCollaborator', { namespace: 'modals' }) isCollaborator!: boolean;
  @Getter('getIsApprover', { namespace: 'modals' }) isApprover!: boolean;
  @Getter('getIsApproverMaster', { namespace: 'modals' }) isApproverMaster!: boolean;

  @Getter('getModalPendencyCommentState', { namespace: 'modals' })
  public modalPendencyComment!: boolean;
  @Action('setModalPendencyCommentState', { namespace: 'modals' })
  public setModalPendencyCommentState!: any;

  @Action('setPendencyComment', { namespace: 'modals' }) public setPendencyComment!: any;

  @Prop(Number) public onboardingStatus!: number;

  pendency = '';
  loading = false;

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0) return 'Required field';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return 'Required Field';
    },
  ];

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  public handleSetPendencyComment() {
    if (!this.form.validate()) {
      return;
    }

    const data = {
      user: this.user.email,
      userUpdateAs: this.setUserClassification,
      date: new Date(),
    };

    this.loading = true;
    this.setPendencyComment(this.pendency);
    setTimeout((): void => {
      this.loading = false;
      this.closeModal();
      this.$emit('pendencyCommentData', data);
    }, 3500);
  }

  get setUserClassification(): string {
    return this.isVendor
      ? 'Vendor'
      : this.isCollaborator
      ? 'Requester'
      : this.isApprover
      ? 'Approver'
      : this.isApproverMaster
      ? 'Approver Master'
      : '';
  }

  public closeModal(): void {
    this.setModalPendencyCommentState(false);
    this.pendency = '';
    (this.$refs.form as HTMLFormElement).resetValidation();
  }
}
