export default class PermissionHandler {
  public static verifyPermission(areas: Array<any>, menu: string | null, action: string): boolean {
    if (menu) {
      return areas.some((x) => {
        return (
          x.title === menu &&
          x.menus.some((y: { permissions: { action: string; active: any }[] }) => {
            return y.permissions.some(
              (z: { action: string; active: any }) => z.action === action && z.active
            );
          })
        );
      });
    } else {
      return false;
    }
  }

  public static canSeeUsers(userRoles: string[]): boolean{
    return userRoles.includes(`Vendor.Management`) 
        || userRoles.includes(`Vendor.LineManager`)
        || userRoles.includes(`Vendor.Finance`)
  }
}
