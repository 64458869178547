
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AuthService } from '@/services/authService';
import Endpoint from '@/utils/endpoint';
import { Action } from 'vuex-class';
import profile from '@/types/general/profile';

@Component({})
export default class ModalNewProfile extends Vue {
  @Prop(Boolean) openNewProfileModal!: boolean;
  @Prop(Boolean) isAdditional?: boolean;
  @Prop(Boolean) isEdit?: boolean;
  @Prop(String) id?: string;
  @Prop(String) emailUser?: string;
  @Prop(String) title!: string;

  @Action('setFeedback', { namespace: 'modals' })
  public setFeedback: any;
  userProfile: profile[] = [];
  userEmail = '';
  lineManager = '';
  maxValueForContract = '';
  maxValueForPayment = '';
  Profileid = '';
  principalId = '';
  btnText = 'Save';
  isMasterApproval = false;
  isExist = false;
  loading = false;
  authService = new AuthService();
  private endpoint = new Endpoint();

  get emailRules() {
    return [(v: string) => /.+@.+/.test(v) || 'E-mail must be valid'];
  }
  @Watch('isEdit', { deep: true, immediate: true })
  async handleisEdit() {
    if (this.isEdit === true) {

      const token = await this.authService.getIdToken();
      this.loading = true;
      const data = await this.$http.get(this.endpoint.users.findProfileById + `${this.id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.btnText = data.userEmail  || data.lineManager ? 'Edit' : 'Save'
      this.isExist = !!data.transactionLimit
      this.Profileid = data.id;
      this.principalId = data.principalId;
      this.userEmail = data.userEmail;
      this.lineManager = data.lineManager;
      this.maxValueForContract = data.transactionLimit
        ? data.transactionLimit.maxValueForContract
        : 0;
      this.maxValueForPayment = data.transactionLimit
        ? data.transactionLimit.maxValueForPayment
        : 0;
      this.isMasterApproval = data.transactionLimit
        ? data.transactionLimit.isMasterApproval
        : false;
      this.loading = false;
    }
  }
  @Watch('isAdditional', { deep: true, immediate: true })
  async handleisAdditional() {
    if (this.isAdditional === true) {
      const token = await this.authService.getIdToken();
      this.loading = true;
      const data = await this.$http.get(this.endpoint.users.findProfileById + `${this.id}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.btnText = data.transactionLimit ? 'Edit' : 'Save'
      this.isExist = !!data.transactionLimit
      this.Profileid = data.id;
      this.principalId = data.principalId;
      this.userEmail = data.userEmail;
      this.lineManager = data.lineManager;
      this.maxValueForContract = data.transactionLimit
        ? data.transactionLimit.maxValueForContract
        : 0;
      this.maxValueForPayment = data.transactionLimit
        ? data.transactionLimit.maxValueForPayment
        : 0;
      this.isMasterApproval = data.transactionLimit
        ? data.transactionLimit.isMasterApproval
        : false;
      this.loading = false;
    }
  }

  async handleCreate() {
    try {
      const token = await this.authService.getIdToken();
      const profile = {
        userEmail: this.userEmail,
        lineManager: this.lineManager,
        isTrustedMember: false,
      };
      this.loading = true;
      await this.$http.post(this.endpoint.users.newProfile, profile, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.loading = true;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Profile successfully Created',
      });
      this.$emit('success');
    } catch (error: any) {
      this.loading = false;
      this.setFeedback({
        feedback: true,
        isError: true,
        color: 'red',
        text: 'An error occurred while creating a profile',
      });
    }
  }
  async handletransactionLimit() {
    try {
      const token = await this.authService.getIdToken();
      const profile = {
        userEmail: this.emailUser,
        maxValueForContract: +this.maxValueForContract,
        maxValueForPayment: +this.maxValueForPayment,
        isMasterApproval: this.isMasterApproval,
      };
      this.loading = true;
      await this.$http.post(this.endpoint.users.transactionLimit, profile, {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.loading = false;
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Transaction Limit Successfully Included',
      });
      this.$emit('success');
    } catch (error: any) {
      this.loading = false;
      this.setFeedback({
        feedback: true,
        isError: true,
        color: 'red',
        text: 'error occurred while including a Transaction Limit',
      });
    }
  }

  async handleEditProfile() {
    try {
      const token = await this.authService.getIdToken();
      const profile = {
        userEmail: this.userEmail,
        lineManager: this.lineManager,
        isTrustedMember: this.isMasterApproval,
      };

      const transactionLimit = {
        maxValueForContract: this.maxValueForContract,
        maxValueForPayment: this.maxValueForPayment,
        isMasterApproval: this.isMasterApproval,
      };
      if(this.isAdditional){
        this.loading = true;
        await this.$http.put(
          this.endpoint.users.editProfiletransactionlimit + `${this.userEmail}`,
          transactionLimit,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        this.loading = false;
      }
      if(this.isEdit){
        this.loading = true;
        await this.$http.put(this.endpoint.users.editProfile + `${this.Profileid}`, profile, {
          headers: {
            Authorization: `${token}`,
          },
        });
        this.loading = false;
      }
      this.setFeedback({
        feedback: true,
        isError: false,
        color: 'green',
        text: 'Profile Edit Successfully Included',
      });
      this.$emit('success');
    } catch (error) {
      this.loading = false;
      this.setFeedback({
        feedback: true,
        isError: true,
        color: 'red',
        text: 'Error occurred while including a Profile Edit',
      });
      console.error('Error in handleEditProfile:', error);
    }
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
