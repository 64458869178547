export default interface CostCenter {
  id: number;
  name: string;
  siengeValue: string;
  companyName: string;
  enabled: boolean;
  disabled: boolean;
  isExternal: boolean;
}

const costCenterInitialValue: CostCenter = {
  id: 0,
  name: '',
  siengeValue: '',
  companyName: '',
  enabled: false,
  disabled: false,
  isExternal: false,
};

export { costCenterInitialValue };
