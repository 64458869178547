
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Rules from '@/utils/rules';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

interface Pendency {
  target: string;
  value: number;
}

@Component({
  components: {
    ModalPendency,
  },
})
export default class ModalPendency extends Vue {
  @Getter('getOnboardingId', { namespace: 'modals' }) public onboardingId!: boolean;

  @Getter('getModalPendencyState', { namespace: 'modals' }) public modalPendency!: boolean;
  @Action('setModalPendencyState', { namespace: 'modals' }) public setModalPendencyState!: any;

  @Getter('getIsVendor', { namespace: 'modals' }) isVendor!: boolean;

  @Prop({ default: false }) public loading!: boolean;
  @Watch('loading')
  public handleCloseModal() {
    if (!this.loading) this.closeModal();
  }

  rules = new Rules();

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  pendencyOptions = [
    { target: 'Vendor', value: 3 },
    { target: 'Leste', value: 4 },
  ];
  pendencyOptionsSelected: Pendency = { target: 'Vendor', value: 3 };
  pendencyReason = '';

  public setPendencyOnboarding() {
    if (!this.form.validate()) {
      return;
    }

    const params = {
      id: this.onboardingId,
      pendencyType: this.pendencyOptionsSelected.value,
      message: this.pendencyReason,
      internalMessage: this.handleSetInternalMessage,
    };
    this.$emit('setPendencyOnboarding', params);
  }

  get handleSetInternalMessage() {
    return !this.isVendor && this.pendencyOptionsSelected.value === 4 ? true : false;
  }

  public closeModal(): void {
    this.setModalPendencyState(false);
    this.pendencyOptionsSelected = { target: 'Vendor', value: 3 };
    this.pendencyReason = '';
    (this.$refs.form as HTMLFormElement).resetValidation();
  }
}
