import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueSkeletonLoader from 'skeleton-loader-vue';
import VeeValidate from 'vee-validate';
import money from 'v-money';

Vue.use(VeeValidate);

const filter = function (text: string, length: number, clamp: string) {
  clamp = clamp || '...';
  const split = text.split('.pdf') || text.split('.docx');
  const extension = split[1];
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent;
  return content!.length > length ? content!.slice(0, length) + clamp + extension : content;
};

Vue.filter('truncate', filter);

import Http from './plugins/http';
Vue.config.productionTip = false;

Vue.use(new Http());
Vue.use(money, { precision: 4 });
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
