import { PermissionsState } from './types';

import SystemInformations, {
  Permissions,
} from '@/types/auth/systemInformations';

export default {
  getSystemInformations(state: PermissionsState): SystemInformations {
    return state.systemInformations;
  },

  getUserPermissions(state: PermissionsState): Permissions[] {
    return state.userPermissions;
  },

  getUserRoles(state: PermissionsState): string[] {
    return state.userRoles;
  },
};
