
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalDeleteSubTask extends Vue {
  //   @Getter('getShowModalDelete', { namespace: 'entity' })
  //   showModalDelete!: boolean;
  //   @Action('setShowModalDelete', { namespace: 'entity' })
  //   setShowModalDelete!: any;
  //   @Getter('getSubTaskKey', { namespace: 'checklist' })
  //   subTaskKey!: boolean;
  //   @Action('setSubTaskKey', { namespace: 'checklist' })
  //   setSubTaskKey!: any;
  //   @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  //   checklistEntityStageKey!: string;

  @Prop(Boolean) readonly showContractSignedModal!: boolean;
  @Prop(Boolean) readonly loadingContractSigned!: boolean;
  @Prop(Boolean) isAdditive!: boolean;

  contractSignedAdditive() {
    this.$emit('contractSignedAdditive');
  }

  contractSignedContract() {
    this.$emit('contractSignedContract');
  }

  ifContractSignedContractOrAditive() {
    if (this.isAdditive) {
      this.contractSignedAdditive();
    } else {
      this.contractSignedContract();
    }
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
